import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FlightService } from '@shared/services/flight.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../../../modules/authentication-module/authentication-service/authentication.service';
import { Passenger } from '../../../../../domain/passenger.model';
import { RoutingService } from '@services';
import { User } from '@domain';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@shared/services/user.service';
import { ContactsComponent } from './contacts/contacts.component';
import { MessageComponent } from 'src/app/components/prompts/message/message.component';
import { DialogComponent } from 'src/app/components/prompts/dialog/dialog.component';
import { Observable, zip } from 'rxjs';

@Component({
    selector: 'app-passanger-info',
    templateUrl: './passenger-info.component.html',
    styleUrls: ['./passenger-info.component.scss']
})
export class PassangerInfoComponent implements OnInit {
    public flightId: number;
    public passengers: Passenger[];
    public isLoading = false;
    private newPassenger: User;
    public showNewPassengerForm = false;
    public editedPassenger: number;
    public get earliestDate() { return { year: 1900, month: 1, day: 1 } };
    public get passengersIds() { return this.passengers.map(entry => entry.user.id); }

    @Output() cancelTrip = new EventEmitter();
    @Output() passengersUpdated = new EventEmitter();

    constructor(
        private route: ActivatedRoute,
        private router: RoutingService,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private flightService: FlightService,
        private userService: UserService,
        private routingService: RoutingService,
        private authenticationService: AuthenticationService
    ) {
        // Index of currently edited passenger,
        // value is -1 when no passenger is edited.
        this.passengers = [];
        this.editedPassenger = -1;
        this.newPassenger = new User();
        this.route.params.subscribe(params => {
            this.flightId = +params.id;
        });
    }

    ngOnInit() {
        this.getFlightPassengers();
    }

    savePassengers() {
        if (this.passengers.length === 0) {
            return;
        }
        this.passengersUpdated.emit();
    }

    getFlightPassengers() {
        this.isLoading = true;
        this.flightService.getFlightPassengers(this.flightId)
            .subscribe((passengers: Passenger[]) => {
                this.passengers = passengers;
                this.passengers.forEach((item, i) => {
                    if (item.user.id === this.authenticationService.currentUserValue.id) {
                        this.passengers.splice(i, 1);
                        this.passengers.unshift(item);
                    }
                });
                this.isLoading = false;
            });
    }

    removePassenger(id) {
        this.flightService.removePassenger(id)
            .subscribe(() => {
                this.getFlightPassengers();
            });
    }

    editPassenger(index: number) {
        this.editedPassenger = index;
        this.showNewPassengerForm = false;
    }

    savePassenger(user: User) {
        console.log(user)
        this.userService.update(user.id, user).subscribe(() => this.editedPassenger = -1);
    }

    editCancel = () => {
        this.editedPassenger = -1;
    }

    createNewPassengerForm = () => {
        this.editedPassenger = -1;
        this.showNewPassengerForm = true;
    }

    openContacts = () => {
        this.userService.getContacts().subscribe(contacts => {
            contacts = contacts.filter(entry => this.passengersIds.indexOf(entry.id) == -1);
            contacts = contacts.filter(entry => entry.firstName !== null);
            let modalRef = this.modalService.open(ContactsComponent, { centered: false, size: 'sm', backdrop: 'static', windowClass: 'long-modal' });
            modalRef.componentInstance.contacts = contacts;
            modalRef.result.then(selected => this.addContactsToFlight(selected)).catch(() => { });
        });
    }

    createNewContact = (user: User) => {
        this.userService.create(user).subscribe(user => this.addPassengerToFlight(user.id));
    }

    addContactsToFlight = (selected) => {
        if (selected && selected.length > 0) {
            let actions: Observable<any>[] = selected.map(entry => { return this.addPassengerToFlight(entry) });
            zip(actions).subscribe(() => {
                this.getFlightPassengers();
            },
                error => {
                    console.error(error);
                    let modalRef = this.modalService.open(MessageComponent, { centered: true, size: 'sm', backdrop: 'static' });
                    modalRef.componentInstance.title = "Error adding passengers";
                    modalRef.componentInstance.message = "Some passangers have not been added, please check your passengers list."
                });
        }
    }

    addPassengerToFlight = (userId: string) => {
        this.flightService.addPassengerToFlight(userId, this.flightId)
            .subscribe(() => {
                this.getFlightPassengers();
                this.newPassenger = new User();
                this.showNewPassengerForm = false;
            });
    }

    userPassenger = (data: Passenger) => {
        let user: User = data.toUser();
        user.id = data.user.id;
        return user;
    }

    isPassengerUser = (passenger: Passenger) => {
        return passenger.user.id === this.authenticationService.currentUserValue.id;
    }
}
