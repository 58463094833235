import { Component, OnInit } from "@angular/core";
import { MemberCard } from "src/app/domain/member-card.model";
import { AccountService } from "@shared/services/account.service";
import { MemberCardService } from "@shared/services/membercard.services";
import { Deserialize } from 'cerialize';
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-member-report',
  templateUrl: './member-report.component.html',
  styleUrls: ['./member-report.component.scss']
})

export class MemberReportComponent implements OnInit {
  public memberCard: MemberCard;
  public statementInfo: object;
  loading: boolean;


  constructor(
    private route: ActivatedRoute,
    private memberCardService: MemberCardService,
    private accountService: AccountService
  ) {

  }
  ngOnInit(): void {

    const memberCardError = (err) => {
      this.loading = false;
      console.error("Failure Retrieving the Member Card", err);
    }
    this.route.params.subscribe((params) => {
      const memberCardId = params['id'];
      this.memberCardService.getMemberCardStatement(memberCardId)
        .subscribe(
          (data: any) => {
            if (!data) {
              memberCardError(null);

              return;
            }

            this.memberCard = Deserialize(data, MemberCard);
            this.loading = false;
            //   this.checkForMaturation();
            // if (this.memberCard.account) {
            //   this.setTripsForAccount(this.memberCard.account);
            // }
          },
          (err) => {
            memberCardError(err);
          }
        );
    })


  }

}