import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { User } from '@domain';
import { Serialize, Deserialize } from 'cerialize';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../modules/authentication-module/authentication-service/authentication.service';
import { Photo } from '../../domain/photo.model';
import { Identification } from 'src/app/domain/identification.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
  }

  public getContacts() {
    return this.http.get<User>(environment.baseUrl + 'users/').pipe(
      map((user: User) => {
        return Deserialize(user, User);
      }
      ));
  }

  public get(id: number) {
    return this.http.get<User>(environment.baseUrl + 'users/' + id).pipe(
      map((user: User) => {
        return Deserialize(user, User);
      }
      ));
  }

  public create(user: User) {
    return this.http.post<User>(environment.baseUrl + 'users', Serialize(user)).pipe(
      map((user: User) => {
        return Deserialize(user, User);
      }
      ));
  }

  public update(id: number, user: User) {
    return this.http.put<User>(environment.baseUrl + 'users/' + id, Serialize(user)).pipe(
      map((u: User) => {
        return Deserialize(u, User);
      })
    );
  }

  public resetPassword(email: string) {
    const u = new User();
    u.email = email;
    return this.http.post(environment.baseUrl + 'users/reset_password', u);
  }

  public uploadProfileImage(image) {
    const formData = new FormData();
    formData.append('content', image);
    const userId = this.authenticationService.currentUserValue.id;
    return this.http.post(environment.baseUrl + 'users/' + userId + '/photos', formData).pipe(
      map((p: Photo) => {
        return Deserialize(p, Photo);
      })
    );
  }
}

