import { Component, OnChanges, Input, Output, OnInit } from "@angular/core";
import { Flight } from "src/app/domain/flight";
import { Aircraft } from "src/app/domain/aircraft.model";
import { FlightService } from "@shared/services/flight.service";
import { FlightLeg } from "src/app/domain/flight-leg.model";
import * as moment from "moment";

@Component({
  selector: "app-flight-itinerary",
  templateUrl: "./flight-itinerary.component.html",
  styleUrls: ["./flight-itinerary.component.scss"],
})
export class FlightItineraryComponent implements OnChanges, OnInit {
  @Input() short: boolean;
  @Input() notes: boolean;
  @Input() public flight: Flight;
  @Output() flightLeg: number;

  private passengersList: string[];
  public get leg(): FlightLeg {
    return this.flight.flightLegs[this.flightLeg];
  }
  public get clientNotes(): string {
    return this.flight.flightOffers[0].clientNotes;
  }

  public get catering(): string[] {
    let legsCatering = "\n";

    this.flight.flightLegs.forEach((x, i) => {
      if (x.cateringSummary) {
        legsCatering += "Leg " + (i + 1) + "    ";
        legsCatering += x.cateringSummary.split(",");
        legsCatering += "\n";
      }
    });

    return legsCatering != "\n" ? legsCatering.split("\n") : [];
  }

  public get transportationDeparture(): string[] {
    let legsDeparture = "\n";
    this.flight.flightLegs.forEach((x, i) => {
      if (x.departureGroundSummary) {
        legsDeparture += "Leg " + (i + 1) + "    ";
        legsDeparture += x.departureGroundSummary;
        legsDeparture += "\n";
      }
    });

    return legsDeparture != "\n" ? legsDeparture.split("\n") : [];
  }
  public get transportationArrival(): string[] {
    let legsArrival = "\n";
    this.flight.flightLegs.forEach((x, i) => {
      if (x.arrivalGroundSummary) {
        legsArrival += "Leg " + (i + 1) + "    ";
        legsArrival += x.arrivalGroundSummary;
        legsArrival += "\n";
      }
    });

    return legsArrival != "\n" ? legsArrival.split("\n") : [];
  }

  public get passengers(): string[] {
    return this.passengersList;
  }
  public get aircraft(): Aircraft {
    return this.flight ? this.flight.flightLegs[this.flightLeg].aircraft : null;
  }

  public constructor(private flightService: FlightService) {
    this.passengersList = [];
  }
  public ngOnChanges(): void {
    if (!this.flightLeg) {
      this.flightLeg = 0;
    }
    if (this.flight) {
      this.update();
    }
  }
  public selectFlightLeg(leg: number) {
    this.flightLeg = leg;
  }
  private update(): void {
    this.flightService
      .getFlightPassengers(this.flight.id)
      .subscribe((passengers) => {
        this.passengersList = passengers.map(
          (passenger) =>
            passenger.user.firstName + " " + passenger.user.lastName
        );
      });
  }

  ngOnInit() {}
}
