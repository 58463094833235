import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../modules/authentication-module/authentication-service/authentication.service';
import { User } from '@domain';
import { RoutingService } from '@services';
import { UserService } from '@shared/services/user.service';
import { Router } from '@angular/router'

import { NewUserComponent } from 'src/app/components/prompts/new-user/new-user.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private loginForm: FormGroup;
  private loggingIn = false;
  private passwordVisible = false;
  private emptyPassword = true;
  private loginError = false;
  private step = 1;
  private resetPasswordSuccess = false;
  private resetPasswordError = false;
  private resetPasswordSubmit = false;
  private dom;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private routingService: RoutingService,
    private userService: UserService,
    private router: Router,
  ) {
  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.createLoginForm();
    if (this.authService.currentUserValue) {
      this.routingService.navigateToHome();
    }
    this.dom = (document.querySelector('body') as HTMLElement);
    this.dom.style.backgroundColor = '#4f5758';
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]
      ),
      password: new FormControl('',
        Validators.required
      ),
    });
  }

  submitForm() {
    this.loggingIn = true;
    const u = new User(null, this.f.email.value, this.f.password.value);
    this.authService.login(u).subscribe(user => {
      this.loggingIn = false;
      this.router.navigate(['welcome'])
      this.dom.style.backgroundColor = '';
    }, error => {
      this.loginError = true;
      this.loggingIn = false;
    });
  }

  onPasswordChanged(e) {
    if (e.target.value !== '') {
      this.emptyPassword = false;
    } else {
      this.emptyPassword = true;
      this.passwordVisible = false;
    }
  }

  requestResetPasswordEmail() {
    this.resetPasswordSubmit = true;
    if (this.f.email.valid) {
      this.userService.resetPassword(this.f.email.value).subscribe(
        success => {
          this.resetPasswordSuccess = true;
        },
        error => {
          this.resetPasswordError = true;
        });
    } else {
      this.resetPasswordError = true;
    }
  }

}
