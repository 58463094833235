import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../modules/authentication-module/authentication-service/authentication.service";
import { ImageService } from "@shared/services/image.service";
import { User } from 'src/app/domain/user.model';
import { Account } from 'src/app/domain/account.model';
import { MemberCard } from 'src/app/domain/member-card.model';

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  public user: User;
  public memberCard: MemberCard;
  public viewMode;
  public screenId = 0;
  public photoUrl: string;
  public viewItem: number = 1
  constructor(
    private imageService: ImageService,
    public authenticationService: AuthenticationService
  ) {

  }

  public ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    this.user = user;

    if (user.account.accountType.toLowerCase() === 'member') {
      const acct = Account.fromData(user.account);
      if (acct) {
        const cards = acct.sortMemberCards('createddate')
        // choose the "first" (we assume it's the earliest)
        this.memberCard = MemberCard.fromData(cards[0]);
      }
    }

    //XXXIssue - this needs to be subscribe
    if (user.photo) {
      this.imageService.get(this.user.photo.id.toString()).subscribe((result: any) => {
        this.photoUrl = result.content.url;
      });
    } else {
      this.photoUrl = "assets/images/genericPerson.png";
    }
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  public selectScreen(scrnId) {
    this.screenId = scrnId;
  }
}
