import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { User } from '@domain';

const ID_INPUTS = ['licenseNumber', 'licenseIssuingState', 'licenseExpirationDate'];
const PASSPORT_INPUTS = ['passportNumber', 'passportIssueDate', 'passportIssuingCountry', 'passportExpiration'];

@Component({
  selector: 'app-passenger-form',
  templateUrl: './passenger-form.component.html',
  styleUrls: ['./passenger-form.component.scss']
})
export class PassengerFormComponent implements OnInit, OnChanges {
  @Input() index: string;
  @Input() user: User;
  @Input() enabled: boolean;
  @Input() fixed: boolean;
  @Output() save: EventEmitter<User>;
  @Output() cancel: EventEmitter<void>;


  public get earliestDate() { return { year: 1900, month: 1, day: 1 } };
  public userForm: FormGroup;
  public cardIdTab: boolean;
  constructor() {
    this.cardIdTab = true;
    this.save = new EventEmitter<User>();
    this.cancel = new EventEmitter<void>();

  }
  public ngOnInit(): void {
    if (!this.index) this.index = '';
    if (this.user) {
      if (this.user.identification) {
        this.cardIdTab = !!this.user.identification.licenseNumber;
      }
      else this.cardIdTab = true;
    }
    this.update();
  }
  public ngOnChanges(): void {
    this.update();
  }
  public update(): void {
    if (!this.user) return;
    if (!this.userForm) {
      this.userForm = this.user.toFormGroup();
    }
    this.toggleInput();
    this.onSwitchDocument(this.cardIdTab);
  }
  public toggleInput(): void {
    if (this.enabled) {
      this.userForm.enable();

    }
    else {
      this.userForm.disable();
    }
  }
  public onCancel(): void {
    this.userForm = this.user.toFormGroup();
    this.cancel.emit();
  }
  public onSave(): void {
    if (this.userForm.valid) {
      this.save.emit(User.fromFormData(this.userForm.value));
      this.userForm.markAsPristine()
    }
    else this.userForm.markAllAsTouched();
  }
  public onSwitch(isId: boolean): void {
    if (this.enabled) {
      this.onSwitchDocument(isId);
    }
  }
  public onSwitchDocument(isId: boolean): void {
    this.cardIdTab = isId;
    let identifictationForm: FormGroup = <FormGroup>this.userForm.get('identification');
    ID_INPUTS.forEach(inputName => identifictationForm.get(inputName).setValidators((isId) ? [Validators.required] : null));
    PASSPORT_INPUTS.forEach(inputName => identifictationForm.get(inputName).setValidators((isId) ? null : [Validators.required]));
    [...ID_INPUTS, ...PASSPORT_INPUTS].forEach(inputName => identifictationForm.get(inputName).updateValueAndValidity());
  }
}