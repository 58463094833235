import {
  Component,
  OnChanges,
  Input,
  Output,
  OnInit,
  EventEmitter,
} from "@angular/core";
import { Flight } from "src/app/domain/flight";
import { Aircraft } from "src/app/domain/aircraft.model";
import { FlightService } from "@shared/services/flight.service";
import { FlightLeg } from "src/app/domain/flight-leg.model";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PassengerHealthFormComponent } from "./passenger-health-form/passenger-health-form.component";
import { DocumentService } from "@shared/services/document.service";
import * as moment from 'moment';

@Component({
  selector: "app-edit-flight-itinerary",
  templateUrl: "./edit-flight-itinerary.component.html",
  styleUrls: ["./edit-flight-itinerary.component.scss"],
})
export class EditFlightItineraryComponent implements OnChanges, OnInit {
  @Input() short: boolean;
  @Input() notes: boolean;
  @Input() public flight: Flight;
  @Output() public flightLeg: number;

  public editsAllowed: boolean;
  public healthFormCompleted: boolean = false;

  public internationalTrip: boolean = false;
  public checkingIfFormsComplete: boolean = false;
  public internationHealthAttestationFormComplete: boolean = false;

  @Output() tabChangeEvent = new EventEmitter<boolean>();

  public viewMode: string = "itineraryTab";

  private passengersList: string[];
  public get leg(): FlightLeg {
    return this.flight.flightLegs[this.flightLeg];
  }
  public get clientNotes(): string {
    return this.flight.flightOffers[0].clientNotes;
  }

  public get catering(): string {
    const catering = this.flight.flightLegs[this.flightLeg].cateringSummary;
    return catering !== null ? catering : "";
  }

  public get transportationDeparture(): string {
    const departureGroundSummary = this.flight.flightLegs[this.flightLeg]
      .departureGroundSummary;
    return departureGroundSummary !== null ? departureGroundSummary : "";
  }
  public get transportationArrival(): string {
    const arrivalGroundSummary = this.flight.flightLegs[this.flightLeg]
      .arrivalGroundSummary;
    return arrivalGroundSummary !== null ? arrivalGroundSummary : "";
  }

  public get passengers(): string[] {
    return this.passengersList;
  }
  public get aircraft(): Aircraft {
    const plane = this.flight ? this.flight.flightLegs[this.flightLeg].aircraft : null;
    return plane;
  }

  get tail() {
    const plane = this.aircraft;
    if ((plane.tailId == plane.genericTailId)
      || (plane.carrier && plane.carrier.name && plane.carrier.name.toLowerCase().startsWith('magellan jets'))
    ) {
      return undefined;
    }

    return plane.tailId
  }

  public constructor(
    private flightService: FlightService,
    private modalService: NgbModal,
    private documentService: DocumentService
  ) {
    this.passengersList = [];
  }

  ngOnInit() {
    this.checkIfEditsAllowed();
    this.checkIfHealthFormsAreComplete();
    this.checkIfInternationalFlight();
  }
  public checkIfInternationalFlight() {
    this.flight.flightLegs.forEach((leg: FlightLeg) => {
      if (leg.internationalHealthForm) {
        this.internationalTrip = true;
        if (leg.attestationFormFilename) {
          this.internationHealthAttestationFormComplete = true;
        }
      }
    });
  }

  public ngOnChanges(): void {
    if (!this.flightLeg) {
      this.flightLeg = 0;
    }
    if (this.flight) {
      this.update();
    }
  }
  public selectFlightLeg(leg: number) {
    this.flightLeg = leg;
  }
  private update(): void {
    this.flightService
      .getFlightPassengers(this.flight.id)
      .subscribe((passengers) => {
        this.passengersList = passengers.map(
          (passenger) =>
            passenger.user.firstName + " " + passenger.user.lastName
        );
      });
  }

  public openTab(tabName: string) {
    this.viewMode = `${tabName}Tab`;
    this.tabChangeEvent.emit(tabName === "itinerary");
  }

  public ammenityUpdated(event) {
    if (event) {
      this.openTab("itinerary");

      // wait 2 seconds for newly created pax before checking if they have the form complete boolean equal to true
      setTimeout(() => {
        this.checkIfHealthFormsAreComplete();
      }, 2000);
    }
  }

  checkIfHealthFormsAreComplete() {
    this.checkingIfFormsComplete = true;
    this.flightService
      .checkPassengerFormsCompleted(this.flight.id)
      .subscribe((data: any) => {
        this.checkingIfFormsComplete = false;
        this.healthFormCompleted = data.form_complete;
      });
  }

  checkIfEditsAllowed() {
    if (!this.flight) { return; }

    const departureDate = this.flight.flightLegs[0].departureDate.split("-")
    const departureTime = this.flight.flightLegs[0].departureTime.split(" ")[0].split(":")

    const currentDate = moment(new Date())

    const departureDateTime = moment({ years: +departureDate[0], months: +departureDate[1] - 1, date: +departureDate[2], hours: +departureTime[0], minutes: +departureTime[1] }).toDate()

    const timeDiff = Math.floor(moment(departureDateTime).toDate().getTime() - currentDate.toDate().getTime())

    const hourDifference = Math.floor(timeDiff / (1000 * 3600))

    this.editsAllowed = hourDifference >= 24;
  }

  openHealthFormModal() {
    const modalRef = this.modalService.open(PassengerHealthFormComponent, {
      windowClass: "large-modal",
    });

    modalRef.componentInstance.flight = this.flight;
    modalRef.result.then((changesSaved) => {
      if (changesSaved) {
        // wait a second to check if all trip pax on trip now have the health form completed to be true. Added this wait for new contacts and new pax created during form submission.
        setTimeout(() => {
          this.checkIfHealthFormsAreComplete();
        }, 1000);
      }
    });
  }
  public downloadHealthAttestationForm() {
    this.documentService
      .getPDFDocument("flight_legs/get_blank_health_attestation_form")
      .subscribe(
        (data: Blob) => {
          this.downloadPDFBlob(
            data,
            `pax-health-attestation-${this.flight.tripNumber}.pdf`
          );
        },
        (error) => {
          console.error("get PDF error: ", error);
        }
      );
  }
  private downloadPDFBlob(data: Blob, fileName: string) {
    let file = new Blob([data], { type: "application/pdf" });
    let fileURL = URL.createObjectURL(file);

    window.open(fileURL);
    let a = document.createElement("a");
    a.href = fileURL;
    a.target = "_blank";
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  }
}
