import { autoserialize, autoserializeAs } from 'cerialize';

export class Error {
  @autoserialize dateTime: Date;  //Should be unique date time to millisecond
  @autoserialize location: string; //Code module
  @autoserialize description: string;  //Desc of error
  @autoserialize level: number; //Severity 1-10


  constructor(
    dateTime?: Date,
    location?: string,
    description?: string,
    level?: number
  ) {
    this.dateTime = dateTime;
    this.location = location;
    this.description = description;
    this.level = level;
  }
}
