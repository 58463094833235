import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RoutingService } from "@services";
import * as moment from "moment";
import { Flight } from "../../../domain/flight";
import { FlightService } from "@shared/services/flight.service";
import { Aircraft } from "src/app/domain/aircraft.model";
import { EditFlightItineraryComponent } from "../../../components/edit-flight-itinerary/edit-flight-itinerary.component";

@Component({
  selector: "app-trip-details",
  templateUrl: "./trip-details.component.html",
  styleUrls: ["./trip-details.component.scss"],
})
export class TripDetailsComponent implements OnInit {
  public tripId: number;
  public tripNum: string;
  public flight: Flight;
  private selectedFlightLeg: number;
  public moment = moment;
  public fromRoute: string;

  public userType: string;
  public loadingFlight: boolean = false;
  public showReturnButton: boolean = true;

  public get notes(): string {
    return this.flight.flightOffers[0].clientNotes;
  }
  public get catering(): string {
    return this.flight.cateringSummary;
  }
  public get transportation(): string {
    return this.flight.arrivalGroundSummary;
  }
  public get passengers(): number {
    return this.flight.flightLegs[this.selectedFlightLeg].noOfPassengers;
  }
  public get aircraft(): Aircraft {
    return this.flight
      ? this.flight.flightLegs[this.selectedFlightLeg].aircraft
      : null;
  }

  @ViewChild(EditFlightItineraryComponent, { static: false })
  editFlightItineraryComponent: EditFlightItineraryComponent;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,

    private flightService: FlightService
  ) {
    this.selectedFlightLeg = 0;
    this.route.params.subscribe((params) => {
      if ("id" in params) {
        this.tripId = +params.id;
        this.fromRoute = "my-trips";
        this.getFlight(this.tripId);
      } else if ("trip_num" in params) {
        this.tripNum = params.trip_num;
        this.fromRoute = "statement";
        this.getFlightByTripNum(this.tripNum);
      }
      // this.tripId = +params.id;
      // this.getFlight(this.tripId);
    });
  }

  getFlight(flightId: number) {
    this.loadingFlight = true;
    this.flightService.get(flightId).subscribe(
      (flight) => {
        this.flight = flight;
        this.loadingFlight = false;
      },
      (err) => {
        this.routingService.navigateToMyTrips();
      }
    );
  }
  getFlightByTripNum(tripNum: string) {
    this.loadingFlight = true;
    this.flightService.get(null, tripNum).subscribe(
      (flight) => {
        this.flight = flight;
        this.tripId = flight.id;
        this.loadingFlight = false;
      },
      (err) => {
        this.routingService.navigateToMyTrips();
      }
    );
  }

  ngOnInit() { }

  selectFlightLeg(i: number) {
    this.selectedFlightLeg = i;
  }

  closeTab() {
    this.editFlightItineraryComponent.viewMode = "itineraryTab";
    if ("my-trips" === this.fromRoute) {
      this.getFlight(this.tripId);
    } else if ("statement" === this.fromRoute) {
      this.getFlightByTripNum(this.tripNum);
    } else {
      /* There's an error, abort */
      this.routingService.navigateToMyTrips();
    }
    this.showReturnButton = true;
  }
  tabChanged(event) {
    this.showReturnButton = event;
    if (event) {
      if ("my-trips" === this.fromRoute) {
        this.getFlight(this.tripId);
      } else if ("statement" === this.fromRoute) {
        this.getFlightByTripNum(this.tripNum);
      } else {
        /* There's an error, abort */
        this.routingService.navigateToMyTrips();
      }

    }
  }
}
