import { Component, OnInit, Input } from "@angular/core";
import { BlueSnapService } from "@shared/services/bluesnap.service";
import { Card } from "src/app/domain/card";
import { FlightService } from "@shared/services/flight.service";
import { Flight } from "src/app/domain/flight";

@Component({
  selector: "app-payment-select",
  templateUrl: "./payment-select.component.html",
  styleUrls: ["./payment-select.component.scss"],
})
export class PaymentSelectComponent implements OnInit {
  public submitCardForm: boolean = false;
  public showCardForm: boolean = false;
  public creditCards: Card[] = [];
  public loadingCreditCards: boolean = false;

  @Input() flight: Flight;

  constructor(
    private bluesnapService: BlueSnapService,
    private flightService: FlightService
  ) {}

  ngOnInit() {
    this.getCreditCards();
  }

  public getCreditCards() {
    this.loadingCreditCards = true;

    this.bluesnapService.getCards().subscribe((cards: Card[]) => {
      this.creditCards = cards;
      console.log(this.creditCards);
      this.loadingCreditCards = false;
    });
  }
  bookFlight() {
    this.flightService
      .bookFlight(this.flight.id, this.creditCards[0])
      .subscribe((data) => console.log(data));
  }
}
