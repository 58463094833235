import { Component, OnInit, Input } from '@angular/core';
import { FlightService } from '@shared/services/flight.service';
import { Flight } from 'src/app/domain/flight';
import { TRIP_TYPES } from '@shared/constants';

@Component({
  selector: 'app-total-flight-overview',
  templateUrl: './total-flight-overview.component.html',
  styleUrls: ['./total-flight-overview.component.scss']
})
export class TotalFlightOverviewComponent implements OnInit {

  @Input() public aircraftId: string;
  @Input() public flightLeg: number;
  @Input() public flight: Flight;
  @Input() public isHorizontal = false;
  public flightDuration: string;
  public get firstLeg(): number { return (this.flightLeg != null) ? this.flightLeg : 0; }
  public get lastLeg(): number { return (this.flightLeg != null) ? this.flightLeg : (this.flight.type === TRIP_TYPES.ROUND_TRIP) ? 0 : this.flight.flightLegs.length - 1; }
  public get airportFrom(): string { return this.flight.flightLegs[this.firstLeg].fromAirport.icao; }
  public get cityFrom(): string { return this.flight.flightLegs[this.firstLeg].fromAirport.city; }
  public get airportTo(): string { return this.flight.flightLegs[this.lastLeg].toAirport.icao; }
  public get cityTo(): string { return this.flight.flightLegs[this.lastLeg].toAirport.city; }
  constructor(private flightService: FlightService) { }



  ngOnInit() {
    this.getTotalFlightTime()




  }

  getTotalFlightTime() {
    let totalTime = ""
    let totalMins = 0;



    this.flight.flightLegs.forEach((leg) => {
      if (leg.flightTime) {
        let flightTimeArr = leg.flightTime.split(":");
        let hours = Number(flightTimeArr[0]);

        let minutes = Number(flightTimeArr[1]);
        totalMins += hours * 60;
        totalMins += minutes;
      }


    })
    let totalHours = Math.floor(totalMins / 60);
    totalTime += totalHours.toString() + ":" + (totalMins % 60).toString();
    this.flightDuration = totalTime;
  }



}
