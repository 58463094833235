import { Component, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RoutingService } from "@services";
import { FlightService } from "@shared/services/flight.service";
import { DialogComponent } from "src/app/components/prompts/dialog/dialog.component";
import { Flight } from 'src/app/domain/flight';
import { Deserialize } from 'cerialize';

@Component({
  selector: "app-flight-details",
  templateUrl: "./flight-details.component.html",
  styleUrls: ["./flight-details.component.scss"],
})
export class FlightDetailsComponent implements OnInit {
  public flightId: number;
  public offerId: number;
  public viewMode: string = "paymentTab";
  public stepsDone: boolean[];
  public isCheckingTripIsPaid: boolean = false;
  @Output() chosenLeg: number;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: RoutingService,
    private flightService: FlightService
  ) {
    this.stepsDone = [false, false, false, false, false];
    this.route.params.subscribe(
      (params) => {
        this.flightId = +params.id;
        this.offerId = +params.offerId;
      }
    );
  }
  ngOnInit() {
    this.checkIfTripIsPaid()
  }

  cancelTrip = () => {
    let modalRef = this.modalService.open(DialogComponent, {
      centered: true,
      size: "sm",
      backdrop: "static",
    });
    modalRef.componentInstance.title = "Cancel trip?";
    modalRef.componentInstance.message =
      "Are you sure you want to cancel this trip?";
    modalRef.result.then((confirmed) => {
      if (confirmed) {
        this.flightService
          .delete(this.flightId)
          .subscribe(() => this.router.navigateToMyTrips());
      }
    });
  };

  chooseActiveLeg(legNum: number): void {
    this.chosenLeg = legNum;
  }

  checkIfTripIsPaid(): any {
    this.isCheckingTripIsPaid = true
    this.flightService.getFlight(this.flightId)
      .subscribe(
        (flight: Flight) => {
          if (flight.clientPaidByCreditCard || flight.clientStartedACHPayment || flight.clientWantsToWirePayment || flight.clientPaidForTrip) {
            this.viewMode = 'passengersTab';
          }

          this.isCheckingTripIsPaid = false
        },
        (err) => {
          console.error("Cannot retrieve flight", err);
        }
      );
  }
}