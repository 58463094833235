import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import * as moment from "moment";
import { CustomValidators } from "@shared/custom-validators.service";

const PASSENGER_CONTROLS = [
  { name: "passengerFirstName" },
  { name: "passengerLastName" },
  {
    name: "birthDate",
  },
];

@Component({
  selector: "app-minor-health-form",
  templateUrl: "./minor-health-form.component.html",
  styleUrls: ["./minor-health-form.component.scss"],
})
export class MinorHealthFormComponent implements OnInit {
  @Input() healthForm: FormGroup;
  constructor(private customValidators: CustomValidators) {}

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm(): void {
    PASSENGER_CONTROLS.forEach((controlData) => this.addControl(controlData));
  }

  private hasControl(controlData): boolean {
    return !!this.healthForm.get(controlData.name);
  }

  private addControl(controlData): void {
    let value: any =
      controlData.defaultValue != null ? controlData.defaultValue : "";
    let validators: any[] = controlData.validation
      ? [
          Validators.required,
          ...controlData.validation.map(
            (validator) => this.customValidators[validator]
          ),
        ]
      : [Validators.required];
    this.healthForm.addControl(
      controlData.name,
      new FormControl(value, validators)
    );
  }

  private removeControl(controlData): void {
    this.healthForm.removeControl(controlData.name);
  }
}
