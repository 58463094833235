import * as moment from 'moment';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Airport } from '../../../domain/airport.model';
import { FlightService } from '@shared/services/flight.service';
import { CustomValidators } from '@shared/custom-validators.service';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';

const AIRCRAFT_TYPE_CONTROL = { name: 'aircraftType' };
const PASSENGERS_NUMBER_CONTROL = { name: 'noOfPassengers', validation: ['largerThanZero'] };
const ROUND_TRIP_CONTROLS = [
  { name: 'returnDate', defaultValue: moment().add(1, 'day').format('YYYY-MM-DD') },
  { name: 'returnTime', defaultValue: '07:00' }
];
const FLIGHT_LEG_CONTROLS = [
  { name: 'fromAirport', validation: ['hasFieldId'] },
  { name: 'toAirport', validation: ['hasFieldId'] },
  { name: 'departureDate', defaultValue: moment().add(1, 'day').format('YYYY-MM-DD') },
  { name: 'departureTime', defaultValue: '07:00' }
];

@Component({
  selector: 'app-flight-leg-form',
  templateUrl: './flight-leg-form.component.html',
  styleUrls: ['./flight-leg-form.component.scss']
})
export class FlightLegFormComponent implements OnInit, OnChanges {

  @Input() public legForm: FormGroup;
  @Input() public roundTrip: boolean;
  @Input() public aircraftType: boolean;
  @Input() public passengersNumber: boolean;
  @Input() public minDate: string;
  public maxDate: string;
  public minTime: string;
  public maxTime: string;
  @Output() public dateSelected: EventEmitter<any>;
  public get today() {
    const dt = new Date();
    return {
      day: dt.getDate(),
      month: dt.getMonth() + 1,
      year: dt.getFullYear()
    }
  };
  public get aircraftTypeControl(): FormControl { return <FormControl>this.legForm.get('aircraftType'); }
  public get passengersControl(): FormControl { return <FormControl>this.legForm.get('noOfPassengers'); }
  private aircraftInfo;
  public maxPax = -1;
  public TooManyPax = false;
  constructor(private customValidators: CustomValidators, private flightService: FlightService) {
    this.dateSelected = new EventEmitter<any>();
  }
  public ngOnInit(): void {
    this.aircraftInfo = JSON.parse(localStorage.getItem('aircraft'));

    const today = this.today;
    if (!this.minDate) {
      this.minDate = `${today.year}-${today.month.toString().padStart(2, '0')}-${today.day.toString().padStart(2, '0')}`;
    }
    if (!this.maxDate) {
      const nextYear = today;
      nextYear.year += 2;
      this.maxDate = `${nextYear.year}-${nextYear.month.toString().padStart(2, '0')}-${nextYear.day.toString().padStart(2, '0')}`;
    }
    if (!this.minTime) {
      this.minTime = "00:00";
    }
    if (!this.maxTime) {
      this.maxTime = "23:59";
    }
    this.initializeForm();
  }
  public ngOnChanges(): void {
    this.updateForm();
  }
  private initializeForm(): void {
    FLIGHT_LEG_CONTROLS.forEach(controlData => this.addControl(controlData));
    this.updateForm();
    this.legForm.get('departureDate').valueChanges.subscribe(departure => {
      if (this.roundTrip && moment(this.legForm.get('returnDate').value).isBefore(departure)) {
        this.legForm.get('returnDate').setValue('');
      }
    });

  }
  public airCraftInfoChanged(event) {
    if (this.aircraftTypeControl.value != event) this.aircraftTypeControl.setValue(event);
    this.maxPax = -1;

    switch (event) {
      case 'Light':
        this.maxPax = 6;
        break;
      case 'Mid':
        this.maxPax = 7;
        break;
      case 'SuperMid':
        this.maxPax = 8;
        break;
      case 'Turbo':
        this.maxPax = 4;
        break;
      case 'Heavy':
        this.maxPax = 12;
        break;
    }
    if (this.maxPax == -1) {
      if (!Array.isArray(this.aircraftInfo)) { return }
      let aircraft = this.aircraftInfo.find(ac => ac.name == event);
      if (aircraft) {
        if (aircraft.passengerCapacity) {
          this.maxPax = aircraft.passengerCapacity;
        }
      }
    }

    if (this.maxPax == -1) {
      this.maxPax = 12;
    }
  }
  private updateForm(): void {
    if (!this.roundTrip && this.hasControl(ROUND_TRIP_CONTROLS[0])) {
      ROUND_TRIP_CONTROLS.forEach(controlData => this.removeControl(controlData));
    }
    else if (this.roundTrip && !this.hasControl(ROUND_TRIP_CONTROLS[0])) {
      ROUND_TRIP_CONTROLS.forEach(controlData => this.addControl(controlData));
    }
    if (this.aircraftType && !this.hasControl(AIRCRAFT_TYPE_CONTROL)) {
      this.addControl(AIRCRAFT_TYPE_CONTROL);
    }
    if (this.passengersNumber && !this.hasControl(PASSENGERS_NUMBER_CONTROL)) {
      this.addControl(PASSENGERS_NUMBER_CONTROL);
    }
  }
  private hasControl(controlData): boolean {
    return !!this.legForm.get(controlData.name);
  }
  private addControl(controlData): void {
    let value: any = (controlData.defaultValue != null) ? controlData.defaultValue : '';
    let validators: any[] = (controlData.validation) ? [Validators.required, ...controlData.validation.map(validator => this.customValidators[validator])] : [Validators.required];
    this.legForm.addControl(controlData.name, new FormControl(value, validators));
  }
  private removeControl(controlData): void {
    this.legForm.removeControl(controlData.name);
  }
  public changePassengers(delta: number): void {

    this.TooManyPax = false;
    if (this.passengersControl.value === '') {
      if (delta === -1) return;
      this.passengersControl.setValue(1);
    }
    else {
      let result = this.passengersControl.value + delta;
      if (this.maxPax <= 0 || result <= this.maxPax) {
        this.passengersControl.setValue(result);
      }
      else {
        this.TooManyPax = true;
      }
    }
    if (this.passengersControl.value === 0) {
      this.passengersControl.setValue('');
    }
  }
  public airportFormatter(airport: Airport): string {
    if (airport) {
      return (airport.icao || '') + ', ' + (airport.city || '') + ' ' + (airport.country === 'United States' ? airport.state : airport.country);
    } else {
      return '';
    }
  }
  public searchAirport = (text: Observable<string>) => {
    return text.pipe(
      debounceTime(200),
      switchMap((term) => {
        if (!term) { return; }
        if (term.length < 3) { return; }
        return this.flightService.getAirports(term, 10);
      })
    );
  }
  public dateChanged(value: string): void {
    const [year, month, day] = value.split('-')
    this.dateSelected.emit(new NgbDate(Number(year), Number(month), Number(day)));
  }
}
