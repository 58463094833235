import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidators {

  constructor() {
  }

  hasFieldId(control: AbstractControl) {
    if (control.value.id) {
      return null;  // return null if no errors
    } else {
      return { hasFieldId: true };  // return error
    }
  }

  largerThanZero(control: AbstractControl) {
    if (control.value > 0) {
      return null;  // return null if no errors
    } else {
      return { largerThanZero: true };  // return error
    }
  }

  validatePasswordRepeat(control: AbstractControl) {
    if (control.value) {
      if (control.value === control.parent.value.newPassword) {
        return null;  // return null if no errors
      } else {
        return { validatePasswordRepeat: true }; // return error
      }
    } else {
      return { validatePasswordRepeat: true };  // return error
    }
  }
}
