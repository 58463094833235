import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { User } from "@domain";
import { Serialize } from "cerialize";

import { Card } from "../../../domain/card";
import { FlightService } from "@shared/services/flight.service";
import { CreditCardValidator } from "angular-cc-library";
import { BlueSnapService } from "@shared/services/bluesnap.service";
import { AuthenticationService } from "src/app/modules/authentication-module/authentication-service/authentication.service";
import { UserService } from "@shared/services/user.service";

declare let $: any;
declare let bluesnap: any;
@Component({
  selector: "app-add-card-form",
  templateUrl: "./add-card-form.component.html",
  styleUrls: ["./add-card-form.component.scss"],
})
export class AddCardFormComponent implements OnInit {
  private loading: boolean = false;

  public user: User;
  creditCardForm: FormGroup;
  public token: string;
  public firstName: string;
  public lastName: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;
  public country: string = "";
  public phone: string;

  @Output() formSuccess = new EventEmitter<boolean>();
  @Input() formCheck: boolean = true;
  @Input() showCardForm: boolean = false;
  @Input() isAddingCard: boolean = false;
  public userLoaded: boolean = false;


  constructor(
    private blueSnapService: BlueSnapService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getHostedFields();
    this.getUserAndCreateForm();
  }

  getUserAndCreateForm() {
    this.userService
      .get(this.authenticationService.currentUserValue.id)
      .subscribe(
        (user) => {
          this.userLoaded = true;
          this.firstName = user.firstName
          this.lastName = user.lastName
          this.user = user;
          this.createCreditCardForm();
        },
        (error) => {
          console.error("cannot locate logged in user", error);
          //XXXError
        }
      );
  }

  checkBlueSnapFields(): boolean {
    let cvv = $("#cvv-help")[0].innerText;
    let expDate = $("#exp-help")[0].innerText;
    let cardNum = $("#ccn-help")[0].innerText;

    return [cvv, expDate,cardNum].some(v => v == "");
  }

  getHostedFields() {
    this.blueSnapService.getToken()
      .subscribe(
        (data: any) => {
          let tokenSplit = data.token.split("/");
          const length = tokenSplit.length;
          const token = tokenSplit[length - 1];
          this.token = token;
          this.loadHostedPaymentToken(token);
        },
        (err: any) => {
          console.error("No token received from the server.", err);
        }
      );
  }

  loadHostedPaymentToken(token: string) {
    let cardUrl = {
      AmericanExpress: "https://files.readme.io/97e7acc-Amex.png",
      CarteBleau: "https://files.readme.io/5da1081-cb.png",
      DinersClub: "https://files.readme.io/8c73810-Diners_Club.png",
      Discover: "https://files.readme.io/caea86d-Discover.png",
      JCB: "https://files.readme.io/e076aed-JCB.png",
      MaestroUK: "https://files.readme.io/daeabbd-Maestro.png",
      MasterCard: "https://files.readme.io/5b7b3de-Mastercard.png",
      Solo:
        "https://sandbox.bluesnap.com/services/hosted-payment-fields/cc-types/solo.png",
      Visa: "https://files.readme.io/9018c4f-Visa.png",
    };
    function changeImpactedElement(tagId, removeClass, addClass) {
      removeClass = removeClass || "";
      addClass = addClass || "";
      $("[data-bluesnap=" + tagId + "]")
        .removeClass(removeClass)
        .addClass(addClass);
    }
    let bsObj = {
      token,
      onFieldEventHandler: {
        changeImpactedElement,
        onFocus: function (tagId) {
          // Handle focus
          changeImpactedElement(tagId, "", "hosted-field-focus");
        },
        onBlur: function (tagId) {
          // Handle blur
          changeImpactedElement(tagId, "", "hosted-field-focus");
        },
        onError: function (tagId, errorCode, errorDescription) {
          // Handle a change in validation by displaying help text
          $("#" + tagId + "-help")
            .removeClass("helper-text-green")
            .text(errorDescription);

        },
        onType: function (tagId, cardType, cardData) {
          // get card type from cardType and display card image
          $("#card-logo > img").attr("src", cardUrl[cardType]);
          // if (null != cardData) {
          //   $("#" + tagId + "-help")
          //     .addClass("helper-text-green")
          //     .text(JSON.stringify(cardData));
          // }
        },
        onValid: function (tagId) {
          // Handle a change in validation by removing any help text
          $("#" + tagId + "-help").text("");
        },
      },
      style: {
        // Styling all inputs
        input: {
          "font-size": "14px",
          "font-family":
            "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
          "line-height": "1.42857143",
          color: "#555",
          "border-bottom": "solid #7f7f7f 1px",
          height: "30px",
        },
        // Styling input state
        ":focus": {
          color: "#555",
          "border-bottom": "solid black 1px",
        },
      },
      ccnPlaceHolder: "1234 5678 9012 3456",
      cvvPlaceHolder: "123",
      expPlaceHolder: "MM / YY",
      expDropDownSelector: false, //set to true for exp date dropdown
    };
    bluesnap.hostedPaymentFieldsCreate(bsObj);
  }

  cancel() {
    this.formSuccess.emit(true);
  }

  createUpdatedUserPayload(): User {
    let user = this.user
    user.address = this.creditCardForm.value.address1;
    if (this.creditCardForm.value.address2.length > 0) {
      user.address += (", " + this.creditCardForm.value.address2)
    }
    user.state = this.creditCardForm.value.state;
    user.country = this.country
    user.phone = this.creditCardForm.value.phone;
    user.city = this.creditCardForm.value.city;
    user.zip = this.creditCardForm.value.zip;

    return user
  }

  submit() {
    this.isAddingCard = true;

    let blueSnapService = this.blueSnapService;

    let firstName = this.firstName;
    let lastName = this.lastName;

    //update user info with form fields
    let updatedUser = this.createUpdatedUserPayload();
    this.updateUserInfo(updatedUser)

    //address info
    let address1 = this.creditCardForm.value.address1;
    let address2 = this.creditCardForm.value.address2;
    let state = this.creditCardForm.value.state;
    let country = this.country
    let phone = this.creditCardForm.value.phone;
    let city = this.creditCardForm.value.city;
    let zip = this.creditCardForm.value.zip;

    let token = this.token;

    let emitter = this.formSuccess;

    //for now to stop spinner on bad inputs until we find a better way
    setTimeout(() => {
      this.isAddingCard = false;
    }, 3500)

    bluesnap.hostedPaymentFieldsSubmitData(function (callback) {
      if (null != callback.error) {
        var errorArray = callback.error;
        for (let i in errorArray) {
          $("#" + errorArray[i].tagId + "-help").text(
            errorArray[i].errorDescription
          );
        }
        return
      }

      var cardData = callback.cardData;

      let newCard = new Card();
      newCard.cardNumber = cardData.last4Digits;
      newCard.expirationDate = cardData.exp;
      newCard.token = token;
      newCard.firstName = firstName;
      newCard.lastName = lastName;

      newCard.address1 = address1;
      newCard.address2 = address2;
      newCard.state = state;
      newCard.country = country;
      newCard.city = city;
      newCard.zip = zip;
      newCard.phone = phone;

      blueSnapService.addCard(newCard).subscribe(
        (data) => {
          emitter.emit(true);
          bluesnap.closeCheckout();
          $(".cancel-btn").click();
        }, (error) => {
          alert("We are unable to add your payment at this time, please call 844-316-6972 We apologize for the inconvenience.")
          console.error("cannot add payment", error);
        }
      );
    });
  }

  createCreditCardForm() {
    this.creditCardForm = this.fb.group({
      firstName: new FormControl({ value: this.firstName, disabled: true }, [Validators.required]),
      lastName: new FormControl({ value: this.lastName, disabled: true }, [Validators.required]),
      address1: new FormControl({ value: this.user.address, disabled: false }, [Validators.required]),
      address2: new FormControl("", []),
      city: new FormControl({ value: this.user.city, disabled: false }, [Validators.required]),
      state: new FormControl({ value: this.user.state, disabled: false }, [Validators.required]),
      zip: new FormControl({ value: this.user.zip, disabled: false }, [Validators.required]),
      phone: new FormControl({ value: this.user.phone, disabled: false }, [Validators.required]),
    });

    this.creditCardForm.valueChanges.subscribe((data) => {
      this.formCheck = this.checkBlueSnapFields();
      // console.log(data)
    });
  }

  updateUserInfo(user: User) {
    // this.loading = true;
    this.userService
      .update(this.authenticationService.currentUserValue.id, Serialize(user))
      .subscribe(
        (userResponse) => {
          // this.loading = false;
          console.log("saved", userResponse)
        },
        (error) => {
          console.error("unable to update user", error);
        }
      );
  }
}
