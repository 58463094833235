import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Serialize } from "cerialize";
import { NO_FBO_FOR_AIRPORT_MESSAGE, TRIP_TYPES } from "@shared/constants";
import { RoutingService } from "@services";
import { FlightRequest, User } from "@domain";
import { FlightLeg } from "src/app/domain/flight-leg.model";
import { FlightService } from "@shared/services/flight.service";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DialogComponent } from "src/app/components/prompts/dialog/dialog.component";
import { AuthenticationService } from "src/app/modules/authentication-module/authentication-service/authentication.service";
import { Account } from "src/app/domain/account.model";

@Component({
  selector: "app-request-flight",
  templateUrl: "./request-flight.component.html",
  styleUrls: ["./request-flight.component.scss"],
})
export class RequestFlightComponent implements OnInit {
  public legForms: FormGroup[];
  public legDepartures: NgbDate[];
  public currentRouteUrl = "";
  public tripType: string;
  private flightLegs: FlightLeg[];

  public membershipAccount: boolean = false;
  public requestingTrip: boolean = false;

  constructor(
    private routingService: RoutingService,
    private flightService: FlightService,
    private modalService: NgbModal,
    private authService: AuthenticationService
  ) {
    this.tripType = TRIP_TYPES.ONE_WAY;
    this.legForms = [new FormGroup({})];
  }

  public ngOnInit(): void {
    this.authService.currentUser.subscribe((data: User) => {
      if (
        data.account &&
        data.account.accountType.toLowerCase() == "jet membership"
      ) {
        this.routingService.navigateToMembershipFlightRequest();
      }
    });

    this.currentRouteUrl = this.routingService.getCurrentRouteUrl();
    this.legDepartures = [];
    this.legForms.forEach(() => this.legDepartures.push(null));
  }

  public requestFlight(): void {
    if (!this.formsValid()) {
      return;
    }

    this.flightLegs = [];
    this.legForms.forEach((legForm) => {
      this.flightLegs.push(FlightLeg.fromFormData(legForm.value));
    });
    let today = new Date();
    let depart = new Date(
      this.flightLegs[0].departureDate + " " + this.flightLegs[0].departureTime
    );

    let howSoon = depart.valueOf() - today.valueOf();
    const TooSoonMS = 24 * 1000 * 60 * 60; //24 hours

    if (howSoon < TooSoonMS) {
      this.warnTooSoon();
    } else {
      this.SendTripRequest();
    }
  }

  public warnTooSoon() {
    let modalRef = this.modalService.open(DialogComponent, {
      centered: true,
      size: "md",
      backdrop: "static",
    });
    let result = false;
    modalRef.componentInstance.title = "Warning";
    modalRef.componentInstance.acceptButton = "Continue";
    modalRef.componentInstance.declineButton = "Cancel";
    modalRef.componentInstance.message =
      "You are attempting to book a trip within 24 hours of your requested departure time.  We may not be able to process this request in time to meet your needs. Please call us at 617-328-5387 to book within 24 hours.";
    modalRef.result.then((confirmed) => {
      if (confirmed) {
        this.SendTripRequest();
        this.routingService.navigateToHome();
      }
    });
  }

  SendTripRequest() {
    if (this.legForms.length == 0) { return }

    const { value } = this.legForms[0];
    this.requestingTrip = true;

    if (this.tripType === TRIP_TYPES.ROUND_TRIP) {
      this.flightLegs.push(FlightLeg.createReturnLeg(value));
    }
    let flightRequest = new FlightRequest(this.flightLegs);
    const user: User = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
      const acctType = (account: Account) => {
        if (!account) { return undefined; }
        return account.accountType;
      }
      flightRequest.memberTrip = acctType(user.account) === "Member";
      flightRequest.jetMembershipTrip = acctType(user.account) === "Jet Membership"
    }

    if (value) {
      flightRequest.aircraftType = value.aircraftType;
    }
    this.flightService.flightRequest(flightRequest)
      .subscribe(
        () => {
          this.requestingTrip = false;
          this.routingService.navigateToRequestRecievedConfirmation();
        },
        (error) => {
          this.requestingTrip = false;
          console.error("unable to request trip", error);
        }
      );
  }

  public selectTripType(type: string): void {
    this.tripType = type;
    if (type != TRIP_TYPES.MULTI_LEG) {
      this.legForms.splice(1);
    }
  }
  public addFlightLeg(): void {
    this.legForms.push(new FormGroup({}));
  }
  public removeFlightLeg(index: number): void {
    this.legForms.splice(index, 1);
  }
  public formsValid(): boolean {
    let valid: boolean = true;
    this.legForms.forEach((legForm) => (valid = valid && legForm.valid));
    valid = valid &&
      (this.tripType !== TRIP_TYPES.MULTI_LEG || this.legForms.length > 1);
    return valid;
  }
  public dateSelected(index: number, value: NgbDate): void {
    this.legDepartures[index] = value;
    for (let i = index + 1; i < this.legDepartures.length; i++) {
      this.legForms[i].controls.departureDate.reset();
      this.legDepartures[i] = null;
    }
  }
}
