import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  @Input() public title: string;
  @Input() public message: string;
  @Input() public acceptButton: string;
  @Input() public declineButton: string;
  constructor(public activeModal: NgbActiveModal) {
    if (!this.acceptButton) this.acceptButton = "Yes";
    if (!this.declineButton) this.declineButton = "No";
  }
}
