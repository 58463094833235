import { Authorization } from "./authorization.model";
import { autoserialize, autoserializeAs, deserializeAs } from "cerialize";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Account } from "./account.model";
import { Photo } from "./photo.model";
import { NgbDateWrapper } from "./NgbDateWrapper.model";
import { Identification } from "./identification.model";

export class StoredWireAch {


  @autoserializeAs("first_name") firstName: string;
  @autoserializeAs("last_name") lastName: string;
  @autoserializeAs('address1') address1: string;
  @autoserializeAs('address2') address2: string;
  @autoserializeAs('zip') zip: string;
  @autoserializeAs('country') country: string;
  @autoserializeAs('state') state: string;
  @autoserializeAs('city') city: string;
  @autoserializeAs('phone') phone: string;
  @autoserializeAs('account_type') accountType: string;
  @autoserializeAs('account_number') accountNumber: string;
  @autoserializeAs('routing_number') routingNumber: string;
  constructor(
    accountType?: string,
    accountNumber?: string,
    routingNumber?: string,
    firstName?: string,
    lastName?: string,
    address1?: string,
    address2?: string,
    zip?: string,
    country?: string,
    state?: string,
    city?: string,
    phone?: string





  ) {

    this.accountType = accountType;
    this.accountNumber = accountNumber;
    this.routingNumber = routingNumber;
    this.firstName = firstName;
    this.lastName = lastName;
    this.address1 = address1;
    this.address2 = address2;
    this.zip = zip;
    this.country = country;
    this.state = state;
    this.city = city;
    this.phone = phone;


  }




}
