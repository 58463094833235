import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-success-banner",
  templateUrl: "./success-banner.component.html",
  styleUrls: ["./success-banner.component.scss"],
})
export class SuccessBannerComponent implements OnInit {
  @Input() public successMessage: string;

  public showSuccessBanner: boolean = false;

  constructor() {}

  ngOnInit() {}

  showBanner() {
    this.showSuccessBanner = true;
    setTimeout(() => (this.showSuccessBanner = false), 5000);
  }
}
