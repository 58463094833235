import { Component, OnInit, Input, ElementRef, ViewChild, Output } from "@angular/core";
import { Card } from "src/app/domain/card";
import { BlueSnapService } from "@shared/services/bluesnap.service";

declare let $: any;

@Component({
  selector: "app-credit-card-dashboard",
  templateUrl: "./credit-card-dashboard.component.html",
  styleUrls: ["./credit-card-dashboard.component.scss"],
})
export class CreditCardDashboardComponent implements OnInit {
  private gettingCards = false;
  private loading: boolean;
  @Output() public showCardForm: boolean = false;

  @Output() public showWireForm: boolean = false;



  public cards: Card[];
  constructor(private blueSnapService: BlueSnapService) { }

  ngOnInit() {
    this.loading = false;
    this.getCards();

  }

  onSuccess(success: boolean): void {
    this.showCardForm = false;
    this.showWireForm = false;
    this.getCards();
  }
  deleteCard = (id: number) => {
    this.gettingCards = true;
    this.blueSnapService.deleteCard(id).subscribe(() => {
      this.getCards();
      this.gettingCards = false;
    });
  };

  public getCards() {
    this.gettingCards = true;

    this.blueSnapService.getCards().subscribe((cards: Card[]) => {
      this.cards = cards;
      this.gettingCards = false;
    });
  }
}
