import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  SimpleChanges,
} from "@angular/core";
import { RoutingService } from "@services";
import { FlightService } from "@shared/services/flight.service";
import * as _ from "lodash";
import { ActivatedRoute } from "@angular/router";
import { FormControl } from "@angular/forms";
import { Flight } from "src/app/domain/flight";
import { FlightLeg } from "src/app/domain/flight-leg.model";

declare let $: any;
@Component({
  selector: "app-edit-catering",
  templateUrl: "./edit-catering.component.html",
  styleUrls: ["./edit-catering.component.scss"],
})
export class EditCateringComponent implements OnInit {
  public catering;
  public dishesByCategory;
  private selectedCategory = 0;
  public selectedSectionName = "Breakfast";
  public flightId: number;
  private selectedDishes = [];
  private cateringSummary = "";
  public isLoading = false;
  public isSubmitting = false;
  public currentPrice = 0.0;
  public customInput: FormControl;
  @Output() cateringUpdated = new EventEmitter<boolean>();
  @Input() activeLeg: number;
  public flight: Flight;
  public currentLeg: FlightLeg;

  public customCatering: string = "";

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private flightService: FlightService
  ) {
    this.customInput = new FormControl("");
    this.route.params.subscribe((params) => {
      this.flightId = +params.id;
    });
  }

  ngOnInit() {
    this.getFlight();
  }

  getFlight() {
    this.isLoading = true;
    this.flightService.getFlight(this.flightId).subscribe((flight: Flight) => {
      this.flight = flight;
      this.currentLeg = this.flight.flightLegs[this.activeLeg];
      this.getCatering();
      this.isLoading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeLeg && this.flight) {
      this.saveCateringOptions(false);
      this.getFlight();

      this.currentLeg = this.flight.flightLegs[this.activeLeg];
    }
  }

  saveCateringOptions(nextStep: boolean) {
    this.isSubmitting = true;

    let flightLegId = Number(this.currentLeg.id);

    if (typeof flightLegId === "number") {
      this.flightService
        .updateLegCatering(flightLegId, this.getCateringSummary())
        .subscribe((data) => {
          if (nextStep) {
            this.cateringUpdated.emit(true);
          }
          this.isSubmitting = false;
        });
    }
  }

  //gets catering
  getCatering = () => {
    this.isLoading = true;
    let currentOrder = [];
    this.customCatering = "";
    if (
      this.currentLeg.cateringSummary &&
      this.currentLeg.cateringSummary.toLowerCase() !==
        "no specific catering requests."
    ) {
      currentOrder = this.currentLeg.cateringSummary
        .split(",")
        .map((x: any, i: number) => {
          let index = x.length - 1;

          //items are saved as a string on each leg like eggs x3, pizza x4, etc
          while (index > 0 && x[index].toLowerCase() !== "x") {
            --index;
          }

          if (index == 0) {
            this.customCatering = x;
            this.customInput.setValue(x);
            return {};
          } else {
            const itemName = x.substring(0, index - 1).trim();
            const itemCount = x.slice(index + 1);

            return { name: itemName, count: +itemCount };
          }
        });
    }

    this.flightService.getCatering().subscribe((response) => {
      this.catering = response;

      console.log(currentOrder);

      if (this.customCatering !== "") {
        //remove custom order from order array which is being edited
        currentOrder.pop();
      }
      console.log(currentOrder);
      this.catering.menu.forEach((menu) => {
        menu.items.forEach((item) => {
          const indexOfItem = currentOrder.findIndex(
            (x) => x.name == item.name
          );
          if (indexOfItem > -1) {
            item.count = currentOrder[indexOfItem].count;
          } else {
            item.count = 0;
          }
        });
      });
      this.catering.menu.push({ section: "Custom", items: [] });
      this.dishesByCategory = _.groupBy(
        this.catering.menu,
        (items) => items.section
      );
      this.isLoading = false;
    });
  };

  selectCategory(i: number, sectionName: string) {
    this.selectedCategory = i;
    this.selectedSectionName = sectionName;
  }

  getCateringSummary() {
    this.selectedDishes = [];
    _.forEach(this.dishesByCategory, (dishCategory) => {
      const selectedDishesInCategory = _.filter(
        dishCategory[0].items,
        (item) => {
          return item.count > 0;
        }
      );
      this.selectedDishes = this.selectedDishes.concat(
        selectedDishesInCategory
      );
    });
    if (this.selectedDishes.length > 0) {
      this.cateringSummary = "";
      this.selectedDishes.forEach((item) => {
        this.cateringSummary = this.cateringSummary.concat(
          `${item.name} x${item.count}, `
        );
      });
      this.cateringSummary = this.cateringSummary.substring(
        0,
        this.cateringSummary.length - 2
      );
    } else {
      this.cateringSummary = "";
    }
    if (this.customInput.value !== "") {
      let custom: string = this.customInput.value;
      custom = custom.replace(/,/g, " ");
      custom = custom.replace(/\s+/g, " ");
      if (this.selectedDishes.length > 0) custom = ", " + custom;
      this.cateringSummary = this.cateringSummary.concat(custom);
    }
    return this.cateringSummary;
  }

  removeItem(dish) {
    if (dish.count > 0) {
      dish.count = dish.count - 1;
      this.currentPrice = this.currentPrice - parseFloat(dish.price);
    }
  }

  addItem(dish) {
    dish.count = dish.count + 1;
    this.currentPrice = this.currentPrice + parseFloat(dish.price);
  }
}
