import {Component, Input} from '@angular/core';
import {UserService} from '../../../../shared/services/user.service';
import {User} from '@domain';
import {AuthenticationService} from '../../../../modules/authentication-module/authentication-service/authentication.service';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent {
  @Input() public imageUrl: any;
  public imagePath;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
  }


  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    if (files[0].size > 1048576 * 3) {
      alert('File is too large. Please choose a file up to 3MB.');
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.imageUrl = reader.result;
    };
    this.userService.uploadProfileImage(files[0]).subscribe(
      photo => {
        const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.photo = photo;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.authenticationService.currentUserSubject.next(currentUser);
      },
      error => {
        console.log(error);
        this.imageUrl = '/assets/images/profile-image-default-large.svg';
      }
    );
  }
}
