import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../modules/authentication-module/authentication-service/authentication.service';
import { RoutingService } from '@services';
import { UserService } from '@shared/services/user.service';
import { NewUserComponent } from 'src/app/components/prompts/new-user/new-user.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  private forgotPasswordForm: FormGroup;
  private requestingNewPassword = false;
  private resetPasswordSuccess = false;
  private resetPasswordError = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private routingService: RoutingService,
    private userService: UserService,
    private modalService: NgbModal
  ) {
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  ngOnInit() {
    this.createForgotPasswordForm();
    if (this.authService.currentUserValue) {
      this.routingService.navigateToHome();
    }
  }

  createForgotPasswordForm() {
    this.forgotPasswordForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]
      )
    });
  }

  submitForm() {
    this.requestingNewPassword = true;
    if (this.f.email.valid) {
      this.userService.resetPassword(this.f.email.value).subscribe(
        success => {
          this.resetPasswordError = '';
          this.resetPasswordSuccess = true;
          this.requestingNewPassword = false;
          // let modalRef = this.modalService.open(NewUserComponent, { centered: true, size: 'sm' });
          // modalRef.componentInstance.email = this.f.email.value;

          // modalRef.result.then(() => {
          //   this.routingService.navigateToLogin();
          // });

        },
        error => {
          if (error === 'Couldn\'t find User') {
            this.resetPasswordError = 'User with this E-mail does not exist.';
          }
          this.requestingNewPassword = false;
        });
    } else {
      this.resetPasswordError = '';
    }
  }

}
