import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Flight } from "src/app/domain/flight";
import * as moment from "moment";

@Component({
  selector: "app-multi-leg-display",
  templateUrl: "./multi-leg-display.component.html",
  styleUrls: ["./multi-leg-display.component.scss"],
})
export class MultiLegDisplayComponent implements OnInit {
  public legNumber: number;
  @Input() public flight: Flight;
  @Output() legSelected = new EventEmitter<number>();

  constructor() {
    this.legNumber = 1;
  }

  ngOnInit() {
  }

  public selectLeg(number: number): void {
    this.legNumber = number;
    this.legSelected.emit(this.legNumber);
  }

  public get departTime(): string {
    let departTime: string = moment(
      this.flight.flightLegs[this.legNumber - 1].departureTime,
      "HH:mm"
    ).format("hh:mm a");

    return departTime;
  }
}
