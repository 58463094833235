import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Aircraft } from "../../domain/aircraft.model";
import { FlightService } from "@shared/services/flight.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExploreAircraftModalComponent } from "src/app/screens/membership-flight-request/modals/explore-aircraft-modal/explore-aircraft-modal.component";
import { HelpDialogComponent } from "src/app/components/prompts/HelpDialog/dialog.component";

@Component({
  selector: "app-aircraft-card",
  templateUrl: "./aircraft-card.component.html",
  styleUrls: ["./aircraft-card.component.scss"],
})
export class AircraftCardComponent implements OnInit {
  @Input() public aircraft: Aircraft;
  @Input() public flightId: 0;
  @Output() public aircraftSelected: EventEmitter<Aircraft> = new EventEmitter<
    Aircraft
  >();

  @Input() public selectedPrice: number = 0;
  public loadingFlightTime: boolean = false;

  flightTime: string = "";
  @Input() index: number = 0;

  constructor(
    private flightService: FlightService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    if (this.flightId > 0) {
      this.calculateFlightTimes();
    }
  }

  aircraftSelectedEvent() {
    this.aircraftSelected.emit(this.aircraft);
  }

  calculateFlightTimes() {
    this.loadingFlightTime = true;
    this.flightService
      .calculateTotalLegFlightTimes(this.flightId, this.aircraft.sfid)
      .subscribe((data: any) => {
        this.flightTime = data && (data.total_block_time || data.total_flight_time);
        this.loadingFlightTime = false;
      });
  }
  openExploreAircraftModal() {
    const modalRef = this.modalService.open(ExploreAircraftModalComponent, {
      windowClass: "membership-modal",
    });

    modalRef.componentInstance.aircraft = this.aircraft;
    modalRef.result.then((data) => {
      if (data) {
        this.aircraftSelectedEvent();
      }
    });
  }
}
