import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../shared/custom-validators.service';
import {UserService} from '../../../../shared/services/user.service';
import {User} from '@domain';
import {AuthenticationService} from '../../../../modules/authentication-module/authentication-service/authentication.service';

@Component({
  selector: 'app-profile-reset-password',
  templateUrl: './profile-reset-password.component.html',
  styleUrls: ['./profile-reset-password.component.scss']
})
export class ProfileResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public password1Visible = false;
  public password2Visible = false;
  public emptyPassword = true;
  public success = false;
  public loading = false;
  public error = false;

  constructor(
    private fb: FormBuilder,
    private customValidators: CustomValidators,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  ngOnInit() {
    this.createResetPasswordForm();
  }

  createResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      newPassword: new FormControl('', [
        Validators.required
      ]),
      passwordRepeat: new FormControl('', [
        Validators.required,
        this.customValidators.validatePasswordRepeat
      ]),
    });
  }

  onPasswordChanged(e) {
    if (e.target.value !== '') {
      this.emptyPassword = false;
      this.f.passwordRepeat.setValue('');
    } else {
      this.emptyPassword = true;
      this.password2Visible = false;
    }
  }

  submitForm() {
    this.loading = true;
    const u = new User();
    u.password = this.f.newPassword.value;
    this.userService.update(this.authenticationService.currentUserValue.id, u).subscribe(
      (user: User) => {
        this.loading = false;
        this.success = true;
        this.f.newPassword.setValue('');
        this.f.passwordRepeat.setValue('');
      },
      error => {
        this.error = true;
        this.loading = false;
        console.log(error);
      });
  }

}
