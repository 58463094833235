import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormControl, Validators, Form } from "@angular/forms";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import * as moment from "moment";
import { NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";

import { Airport } from "src/app/domain/airport.model";
import { Aircraft } from "src/app/domain/aircraft.model";
import { FlightService } from "@shared/services/flight.service";
import { CustomValidators } from "@shared/custom-validators.service";

const AIRCRAFT_TYPE_CONTROL = { name: "aircraftType" };
const PASSENGERS_NUMBER_CONTROL = {
  name: "noOfPassengers",
  validation: ["largerThanZero"],
};
const ROUND_TRIP_CONTROLS = [
  {
    name: "returnDate",
    defaultValue: moment().add(1, "day").format("YYYY-MM-DD"),
  },
  { name: "returnTime", defaultValue: "07:00" },
];
const FLIGHT_LEG_CONTROLS = [
  { name: "fromAirport", validation: ["hasFieldId"] },
  { name: "toAirport", validation: ["hasFieldId"] },
  {
    name: "departureDate",
    defaultValue: moment().add(1, "day").format("YYYY-MM-DD"),
  },
  { name: "departureTime", defaultValue: "07:00" },
];

@Component({
  selector: "app-member-leg-form",
  templateUrl: "./member-leg-form.component.html",
  styleUrls: ["./member-leg-form.component.scss"],
})
export class MemberLegFormComponent implements OnInit, OnChanges {
  @Input() legForm: FormGroup;
  @Input() roundTrip: boolean;
  @Input() tripType: string = "";
  @Input() aircrafts: Aircraft[] = [];
  @Input() aircraftType: boolean;
  @Input() passengersNumber: boolean;
  @Input() minDate: string;

  @Output() public dateSelected: EventEmitter<any>;

  public TooManyPax = false;
  public maxDate: string;
  public minTime: string;
  public maxTime: string;
  public maxPax = 6;

  get aircraftTypeControl(): FormControl {
    return <FormControl>this.legForm.get("aircraftType");
  }

  get passengersControl(): FormControl {
    return <FormControl>this.legForm.get("noOfPassengers");
  }

  get today() {
    const dt = new Date();
    return {
      day: dt.getDate(),
      month: dt.getMonth() + 1,
      year: dt.getFullYear(),
    };
  }

  constructor(
    private customValidators: CustomValidators,
    private flightService: FlightService
  ) {
    this.dateSelected = new EventEmitter<any>();
  }

  ngOnInit(): void {
    const today = this.today;
    if (!this.minDate) {
      this.minDate = `${today.year}-${today.month
        .toString()
        .padStart(2, "0")}-${today.day.toString().padStart(2, "0")}`;
    }
    if (!this.maxDate) {
      const nextYear = today;
      nextYear.year += 2;
      this.maxDate = `${nextYear.year}-${nextYear.month
        .toString()
        .padStart(2, "0")}-${nextYear.day.toString().padStart(2, "0")}`;
    }
    if (!this.minTime) {
      this.minTime = "00:00";
    }
    if (!this.maxTime) {
      this.maxTime = "23:59";
    }
    this.initializeForm();
  }

  ngOnChanges(): void {
    this.updateForm();
  }

  public searchAirport = (text: Observable<string>) => {
    return text.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => this.flightService.getAirports(term, 10))
    );
  };

  handleAircraftSelection(event) {
    if (this.aircraftTypeControl.value != event)
      this.aircraftTypeControl.setValue(event);
    this.maxPax = event.passengerCapacity;
  }

  changePassengers(delta: number): void {
    this.TooManyPax = false;
    if (this.passengersControl.value === "") {
      if (delta === -1) return;
      this.passengersControl.setValue(1);
    } else {
      let result = this.passengersControl.value + delta;
      if (result <= this.maxPax) {
        this.passengersControl.setValue(result);
      } else {
        this.TooManyPax = true;
      }
    }

    if (this.passengersControl.value === 0) {
      this.passengersControl.setValue("");
    }
  }

  airportFormatter(airport: Airport): string {
    if (!airport) {
      return "";
    }

    return (
      (airport.icao || "") +
      ", " +
      (airport.city || "") +
      " " +
      (airport.country === "United States" ? airport.state : airport.country)
    );
  }

  private initializeForm(): void {
    FLIGHT_LEG_CONTROLS.forEach((controlData) => this.addControl(controlData));
    this.updateForm();
    this.legForm.get("departureDate").valueChanges.subscribe((departure) => {
      if (
        this.roundTrip &&
        moment(this.legForm.get("returnDate").value).isBefore(departure)
      ) {
        this.legForm.get("returnDate").setValue("");
      }
    });
  }

  private updateForm(): void {
    if (!this.roundTrip) {
      if (this.hasControl(ROUND_TRIP_CONTROLS[0])) {
        ROUND_TRIP_CONTROLS.forEach((controlData) =>
          this.removeControl(controlData)
        );
      }
    } else {
      if (!this.hasControl(ROUND_TRIP_CONTROLS[0])) {
        ROUND_TRIP_CONTROLS.forEach((controlData) =>
          this.addControl(controlData)
        );
      }
    }

    if (this.aircraftType && !this.hasControl(AIRCRAFT_TYPE_CONTROL)) {
      this.addControl(AIRCRAFT_TYPE_CONTROL);
    }

    if (this.passengersNumber && !this.hasControl(PASSENGERS_NUMBER_CONTROL)) {
      this.addControl(PASSENGERS_NUMBER_CONTROL);
    }
  }

  private hasControl(controlData): boolean {
    return !!this.legForm.get(controlData.name);
  }

  private addControl(controlData): void {
    let value: any =
      controlData.defaultValue != null ? controlData.defaultValue : "";
    let validators: any[] = controlData.validation
      ? [
          Validators.required,
          ...controlData.validation.map(
            (validator) => this.customValidators[validator]
          ),
        ]
      : [Validators.required];
    this.legForm.addControl(
      controlData.name,
      new FormControl(value, validators)
    );
  }

  private removeControl(controlData): void {
    this.legForm.removeControl(controlData.name);
  }
}
