import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@environment/environment";
import { Card } from "src/app/domain/card";
import { Serialize, Deserialize } from "cerialize";
import { map } from "rxjs/operators";
import { StoredWireAch } from 'src/app/domain/stored-wire-ach.model';

@Injectable({
  providedIn: "root",
})
export class BlueSnapService {
  constructor(private http: HttpClient) { }

  public getToken() {
    return this.http.get(
      environment.baseUrl + "bluesnap",
      { headers: {"accept": "application/json"} }
    );
  }

  public addCard(card: Card) {
    return this.http.post(
      environment.baseUrl + "payment_cards",
      Serialize(card, Card)
    );
  }

  public getCards() {
    return this.http.get(environment.baseUrl + "payment_cards").pipe(
      map((cards: Card[]) => {
        return Deserialize(cards, Card);
      })
    );
  }

  public deleteCard(id: number) {
    return this.http.delete(environment.baseUrl + "payment_cards/" + id);
  }

  public addWire(newWire: StoredWireAch) {
    const body = Serialize(newWire, StoredWireAch);
    return this.http.post(environment.baseUrl + "payment_cards/create_ach", body)
      .pipe(
        map((card: Card) => {
          return Deserialize(card, Card);
        })
      );
  }
}
