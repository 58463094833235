import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../../../shared/services/user.service';
import {AuthenticationService} from '../../../../modules/authentication-module/authentication-service/authentication.service';
import {User} from '@domain';
import {Account} from '../../../../domain/account.model';
import {Serialize} from 'cerialize';

@Component({
  selector: 'app-profile-in-flight-preferences',
  templateUrl: './profile-in-flight-preferences.component.html',
  styleUrls: ['./profile-in-flight-preferences.component.scss']
})
export class ProfileInFlightPreferencesComponent implements OnInit {
  public profileInFlightPreferencesForm: FormGroup;
  private loading: boolean;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.loading = false;
    this.createForm();
    this.userService.get(this.authenticationService.currentUserValue.id).subscribe(user => {
      this.createForm(user);
    }, error => {
      console.log(error);
    });
  }

  createForm = (user?: User) => {
    if (user) {
      this.profileInFlightPreferencesForm = this.fb.group({
        inFlightComfortPreferences: new FormControl(user.account.inFlightComfortPreferences),
        catering: new FormControl(user.account.catering),
        allergies: new FormControl(user.account.allergies),
      });
    } else {
      this.profileInFlightPreferencesForm = this.fb.group({
        inFlightComfortPreferences: new FormControl(''),
        catering: new FormControl(''),
        allergies: new FormControl(''),
      });
    }
  }

  updateInFlightPreferencesInfo() {
    this.loading = true;
    const user: User = new User();
    user.account = new Account();
    user.account.inFlightComfortPreferences = this.profileInFlightPreferencesForm.controls.inFlightComfortPreferences.value;
    user.account.allergies = this.profileInFlightPreferencesForm.controls.allergies.value;
    user.account.catering = this.profileInFlightPreferencesForm.controls.catering.value;
    this.userService.update(this.authenticationService.currentUserValue.id, Serialize(user)).subscribe(userResponse => {
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }
}
