import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Aircraft } from "src/app/domain/aircraft.model";
import { Flight } from "src/app/domain/flight";

import { FlightService } from "@shared/services/flight.service";
import { Deserialize } from "cerialize";

@Component({
  selector: "app-aircraft-select",
  templateUrl: "./aircraft-select.component.html",
  styleUrls: ["./aircraft-select.component.scss"],
})
export class AircraftSelectComponent implements OnInit {
  @Input() public membershipAircrafts: Aircraft[] = [];
  public aircrafts: Aircraft[] = [];
  public selectedAircraft: Aircraft;
  public upsellAircraft: Aircraft;
  public flight: Flight;
  @Input() public flightId: number;
  public loadingFlight: boolean;
  @Output() aircraftSelectedEvent = new EventEmitter<boolean>();

  @Output() itineraryReady = new EventEmitter<Flight>();

  constructor(private flightService: FlightService) { }

  ngOnInit() {
    this.getFlight();
  }

  getFlight() {
    this.loadingFlight = true;
    const that = this;

    const updateVars = (flight) => {
      that.flight = flight;

      const selectedAircraftIndex = that.membershipAircrafts.findIndex(
        (ma) => ma.sfid == this.flight.flightLegs[0].aircraft.sfid
      );
      const selectedAircraft = that.membershipAircrafts[selectedAircraftIndex];
      selectedAircraft.isSelected = true;

      that.selectedAircraft = selectedAircraft;

      that.aircrafts.push(selectedAircraft);

      if (selectedAircraftIndex < that.membershipAircrafts.length - 1) {
        that.upsellAircraft = that.membershipAircrafts[
          selectedAircraftIndex + 1
        ];
        that.aircrafts.push(that.upsellAircraft);
      }
      that.loadingFlight = false;
    }

    let tries = 1;

    const setupRetry = (fid, flight) => {
      console.log("Waiting 1.5 seconds before retrying to get flight & legs");
      console.log("Flight", flight);
      setTimeout(() => {
        tries++;
        if (tries > 5) {
          console.error("Giving up after 5th try");
          return;
        }

        retrieve(fid);
      }, 1500);
    };

    const retrieve = (fid: number) => {
      that.flightService.getFlight(fid)
        .subscribe(
          (flight: Flight) => {
            if (!flight.flightLegs || (Array.isArray(flight.flightLegs) && flight.flightLegs.length == 0)) {
              setupRetry(fid, flight)
              return;
            }

            updateVars(flight);
          },
          (err) => {
            console.log("Unable to retrieve flight, error:", err);
            setupRetry(fid, undefined);
          }
        );
    }

    retrieve(that.flightId);
  }

  aircraftSelected(event, i: number) {
    this.aircrafts.forEach((aircraft: Aircraft, index: number) => {
      aircraft.isSelected = i == index;
    });
    this.selectedAircraft = this.aircrafts[i];
  }

  moreAircraftSelected(event, i: number) {
    this.membershipAircrafts.forEach((aircraft: Aircraft, index: number) => {
      aircraft.isSelected = i == index;
    });
    this.selectedAircraft = this.membershipAircrafts[i];
  }

  updateFlight() {
    const that = this;
    const doUpdate = () => {
      const aid = (that.selectedAircraft && that.selectedAircraft.sfid) || ""
      that.flightService
        .updateFlightTimesWithPadding(that.flight.id, aid)
        .subscribe(
          (flight: Flight) => {
            flight = Deserialize(flight, Flight);
            if (flight.flightLegs && flight.flightLegs.length > 0) {
              that.itineraryReady.emit(flight);
            } else {
              //this is in case heroku connect sync issues causes an empty flight leg array to be returned with the flight we just updated
              //this is due to the time it takes trip legs to sync
              setTimeout(() => {
                that.refreshFlight();
              }, 1500);
            }
          },
          () => this.itineraryReady.emit(undefined)
        );
    }

    let tries = 1;

    const check = () => {
      if (!that.flight) {
        tries++;

        if (tries > 5) {
          console.error("Give up! Flight is not known", that.flight);
          alert("Eek! We are experiencing some technical issues right now. Please try again later, or contact your Aviation Consultant.")
          return;
        }

        console.error("Retrying update flight again in 1.5 seconds")
        console.log("flight", that.flight)
        console.log("aircraft", that.selectedAircraft);
        setTimeout(() => {
          check();
        }, 1500);
        return;
      }

      doUpdate();
    }

    check();
  }
  aircraftSelectedInModal() {
    this.aircraftSelectedEvent.emit(true);
  }

  refreshFlight() {
    this.flightService.getFlight(this.flightId).subscribe((flight: Flight) => {
      flight = Deserialize(flight, Flight);
      if (flight.flightLegs && flight.flightLegs.length > 0) {
        this.itineraryReady.emit(flight);
      } else {
        console.error("Error retreiving updated legs.");
      }
    });
  }
}
