import { Component, OnInit, Input } from "@angular/core";
import { Flight } from "src/app/domain/flight";

@Component({
  selector: "app-itinerary",
  templateUrl: "./itinerary.component.html",
  styleUrls: ["./itinerary.component.scss"],
})
export class ItineraryComponent implements OnInit {
  @Input() public flight: Flight;

  constructor() {}

  ngOnInit() {}

  openAppLink() {
    window.open(
      "https://apps.apple.com/us/app/magellanjets/id1500410467",
      "_blank"
    );
  }
}
