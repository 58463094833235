import { Component, forwardRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AircraftService } from '@shared/services/aircraft.service';
import { Observable, zip } from 'rxjs';
import { User } from '@domain';
import { MemberCard } from 'src/app/domain/member-card.model';

const CATEGORIES = ["TurboProp", "Light Jet", "Mid Jet", "Super Mid", "Heavy Jet"];

@Component({
  selector: 'app-aircraft-input',
  templateUrl: './aircraft-input.component.html',
  styleUrls: ['./aircraft-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AircraftInputComponent),
      multi: true
    }
  ]
})
export class AircraftInputComponent implements OnInit, ControlValueAccessor {
  @Output() onAircraftSelected = new EventEmitter();
  @Input() public typeable: boolean;
  @Input() public disabled: boolean;
  @Input() public placeholder: string;
  public filter: string;
  public opened: boolean;
  private currentUser: User;
  private productName: string = 'unknown';
  private accountType: string = 'unknown';
  public options: any[];
  public cards: MemberCard[];
  public defaultMemberAircraft: string;
  public categories: string[];
  public selectedCategory: string;
  public selectedOption: any;
  public get display(): string {
    return (this.selectedCategory) ? this.selectedCategory : ((this.selectedOption) ? this.selectedOption.text : ((this.filter) ? this.filter : ''))
  };
  public get filteredOptions(): any[] { return (this.filter) ? this.options.filter(option => option.text.toLowerCase().includes(this.filter.toLowerCase())) : []; }
  constructor(private aircraftService: AircraftService) {
    this.opened = false;
    this.options = [];
  }

  public ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (this.currentUser && this.currentUser
      && this.currentUser.account
      && this.currentUser.account.accountType
    ) {
      this.accountType = this.currentUser.account.accountType;

      if (this.accountType == 'Member') {
        if (this.currentUser.account.memberCards && (this.currentUser.account.memberCards.length > 0)) {
          this.cards = this.currentUser.account.memberCards;

          // const card = this.cards[0];
          // this.defaultMemberAircraft = card.productName;
          // this.onSelectCategory(this.defaultMemberAircraft)

          // this.onAircraftSelected.emit(this.defaultMemberAircraft)
        }
      }
    }

    this.aircraftService.getTypes()
      .subscribe(
        (aircrafts) => {
          this.options = aircrafts.map(aircraft => { return { value: aircraft.sfid, text: aircraft.name } });
        },
        (error) => {
          console.error(error)
        }
      );

    this.aircraftService.getCategories()
      .subscribe(
        (categories: string[]) => {
          // Change when proper categories are returned by API
          //this.categories = categories;
          this.categories = CATEGORIES;
        },
        (error) => {
          console.error(error)
        }
      );
  }


  public writeValue(): void {
    if (this.selectedCategory) {
      this.onAircraftSelected.emit(this.selectedCategory);
    }
    else if (this.selectedOption) {
      this.onAircraftSelected.emit(this.selectedOption.text);
    }
  }
  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public setDisabledState(isDisabled: boolean): void {

    this.disabled = isDisabled;
  }
  public onInputChange(event): void {
    this.filter = event.target.value;
    this.onTouched();
  }
  public onSelectOption(option): void {
    this.selectedOption = option;
    this.selectedCategory = null;
    this.opened = false;
    this.writeValue();
  }
  public onSelectCategory(category): void {

    this.selectedCategory = category;
    this.selectedOption = null;
    this.filter = '';
    this.writeValue();
  }
  public onChange(value: string) {

  };
  public onTouched() { };
}
