import { Component, Input, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements AfterViewInit {

  @Input() public title: string;
  @Input() public message: string;
  @Input() public link;
  @Input() public linkText: string;
  @Input() public confirmButton: string;


  ngAfterViewInit() { }
  constructor(public activeModal: NgbActiveModal) {


    if (!this.confirmButton) this.confirmButton = "OK";
  }
}
