import { autoserialize, autoserializeAs } from 'cerialize';

export enum NotificationType {
  Booked = 'booked_trip',
  Cancelled = 'cancelled_trip',
  OffersReady = 'offers_ready',
  DepartureFBOChange = 'departure_fbo_changed',
  ArrivalFBOChange = 'arrival_fbo_changed'
}

export class Notification {
  @autoserialize id: number;
  @autoserialize body: string;
  @autoserialize title: string;
  @autoserializeAs('created_at') createdAt: string;
  public type: NotificationType;
  public flightId: number;

  public constructor(
    id?: number,
    body?: string,
    title?: string,
    createdAt?: string
  ) {
    this.id = id;
    this.body = body;
    this.title = title;
    this.createdAt = createdAt;
  }

  public static OnDeserialized(instance: Notification, json: any): void {
    instance.type = json.data.notification_type;
    instance.flightId = json.data.flight_id;
  }
}
