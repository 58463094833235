import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../shared/services/user.service';
import {AuthenticationService} from '../../../../modules/authentication-module/authentication-service/authentication.service';
import {User} from '@domain';
import {Serialize} from 'cerialize';
import {Identification} from '../../../../domain/identification.model';
import {Card} from '../../../../domain/card';
import {FlightService} from '@shared/services/flight.service';
import {CreditCardValidator} from 'angular-cc-library';

@Component({
  selector: 'app-profile-personal',
  templateUrl: './profile-personal.component.html',
  styleUrls: ['./profile-personal.component.scss']
})
export class ProfilePersonalComponent implements OnInit {
  private profileGeneralInfoForm: FormGroup;
  private identificationForm: FormGroup;
  private loading: boolean;
  private gettingCards = false;
  private cardIdTab = true;
  public cards: Card[];
  public user: User;
  creditCardForm: FormGroup;
  showCardForm = false;
  isAddingCard = false;
  public get earliestDate() { return {year: 1900, month: 1, day: 1} };

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private flightService: FlightService
  ) {
  }

  ngOnInit() {
    this.loading = false;
    this.getCards();
    this.createCreditCardForm();
    this.userService.get(this.authenticationService.currentUserValue.id).subscribe(user => {
      this.user = user;
    }, error => {
      console.log(error);
    });
  }

  getCards() {
    this.gettingCards = true;
    this.flightService.getCards()
        .subscribe((cards: Card[]) => {
            this.cards = cards;
            this.gettingCards = false;
        });
  }

  updateProfileGeneralInfo(user: User) {
    this.loading = true;
    this.userService.update(this.authenticationService.currentUserValue.id, Serialize(user)).subscribe(userResponse => {
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

  deleteCard = (id: number) => {
    this.gettingCards = true;
    this.flightService.deleteCard(id)
        .subscribe(() => {
          this.getCards();
        });
  }

  createCreditCardForm() {
      this.creditCardForm = this.fb.group({
          firstName: new FormControl('', [
              Validators.required
          ]),
          lastName: new FormControl('', [
              Validators.required
          ]),
          cardNumber: new FormControl('', [
              Validators.required,
              CreditCardValidator.validateCCNumber
          ]),
          expirationDate: new FormControl('', [
              Validators.required,
              CreditCardValidator.validateExpDate
          ]),
          securityCode: new FormControl('', [
              Validators.maxLength(4),
              Validators.minLength(3)
          ])
      });
  }

  addCreditCard = () => {
      this.isAddingCard = true;
      const card: Card = this.creditCardForm.getRawValue();
      this.flightService.addCard(card)
          .subscribe(
              () => {
                  this.getCards();
                  this.isAddingCard = false;
                  this.showCardForm = false;
                  this.creditCardForm.reset();
              });
  }


}
