import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RoutingService {
  constructor(private router: Router) { }

  public navigateToHome() {
    this.router.navigate(["/request-flight"]);
  }

  public navigateToLogin(queryParams?) {
    console.log(queryParams);
    if (queryParams) {
      this.router.navigate(["/login"], queryParams);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  public navigateToRequestRecievedConfirmation() {
    this.router.navigate(["/request-received"]);
  }

  public navigateToMyTrips() {
    this.router.navigate(["/my-trips"]);
  }

  public navigateToRequestFlightFeaturedPage() {
    this.router.navigate(["/request-flight"]);
  }

  public navigateToMembershipFlightRequest() {
    this.router.navigate(["/membership-flight-request"]);
  }

  public navigateToCompleteMembershipFlightRequest(flightId: number) {
    this.router.navigate(["/membership-flight-request/" + flightId]);
  }

  public navigateToTripDetails(id: number) {
    this.router.navigate(["/my-trips/trip/" + id]);
  }

  public navigateToTripRequestOffers(id: number) {
    this.router.navigate(["/my-trips/offers/" + id]);
  }

  public navigateToOfferDetails(flightId: number, offerId: number) {
    this.router.navigate([
      "/my-trips/offers/" + flightId + "/offer-details/" + offerId,
    ]);
  }

  public navigateToMyProfile() {
    this.router.navigate(['/profile']);
  }

  public navigateToMyFlightDetails(flightId: number, offerId: number) {
    this.router.navigate([
      "/my-trips/offers/" +
      flightId +
      "/offer-details/" +
      offerId +
      "/flight-details",
    ]);
  }

  public navigateToFlightBooked() {
    this.router.navigate(["/my-trips/flight-booked"]);
  }

  public redirectToForgotPassword() {
    this.router.navigate(["/forgot-password"]);
  }

  public getCurrentRouteUrl() {
    return this.router.url;
  }
}
