import { Component, OnInit } from '@angular/core';
import { Notification } from '@domain';
import { NotificationType } from 'src/app/domain/notification.model';
import { NotificationService, RoutingService } from '@services';
// import { ModalService } from '../../services/modal.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from 'src/app/components/prompts/dialog/dialog.component';
import { not } from '@angular/compiler/src/output/output_ast';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public notifications: Notification[] = [];
  public isLoadingNotifications = false;
  public disableDelete = true;
  public butDialog;
  public message;

  public constructor(
    private routing: RoutingService,
    private notificationService: NotificationService,
    private modalService: NgbModal
  ) {
  }

  public ngOnInit(): void {
    this.isLoadingNotifications = true;
    this.notificationService.get().subscribe(notifications => {
      this.notifications = notifications;
      console.log(notifications)
      if (notifications && notifications.length && notifications.length > 0) this.disableDelete = false;
      this.isLoadingNotifications = false;
    }, error => {
      console.log(error);
      this.isLoadingNotifications = false;
    });
  }
  public clearAll() {
    let modalRef = this.modalService.open(DialogComponent, { centered: true, size: 'sm', backdrop: 'static' });
    modalRef.componentInstance.title = 'Clear All Notifications';
    // modalRef.componentInstance.link = 'https://magellanjets.com/privacy-policy-and-legal-information';
    // modalRef.componentInstance.linkText = 'Read More';
    modalRef.componentInstance.message = "Are you sure you want to DELETE ALL notifications";
    modalRef.result.then(confirmed => {
      if (confirmed) {
        console.log("yes");
        this.notificationService.clear().subscribe(deleted => {
          this.notifications = null;
        })
      }
      else {
        console.log("No");
      }

    });
  }

  // public clearAll(id) {
  //   this.message = "Are you sure you want to clear all notiifcations?";
  //   if (this.notifications) {
  //     if (this.notifications.length>0) {
  //       this.message = "There are " + this.notifications.length+" notiifcations.  Clear all?";
  //     }
  //   }
  //   this.notificationService.get().subscribe(notifications => {
  //     let numLocalNots = this.notifications.length;
  //     let remoteNots = notifications.length;
  //     if (numLocalNots != remoteNots) {
  //       this.message = "Unseen notifications must be displayed before clearing all "
  //     }
  //     this.openModal(id)

  //   }, error => {
  //     console.log(error);
  //     //xxx errorLogging()
  //   });
  // }

  public refresh() { }

  public navigateToNotificationTarget(notification: Notification): void {
    if (notification.type == NotificationType.Booked) {
      this.routing.navigateToTripDetails(notification.flightId);
    }
    // else if(notification.type == NotificationType.DepartureFBOChange || notification.type == NotificationType.ArrivalFBOChange) {
    //   this.routing.navigateToTripDetails(notification.flightId);
    // }
    else if (notification.type == NotificationType.OffersReady) {
      this.routing.navigateToTripRequestOffers(notification.flightId);
    }
  }

  // openModal(id: string) {
  //   this.modalService.open(id);
  // }

  // closeModal(id: string , state: boolean) {
  //     this.modalService.close(id);
  // }
}
