import { autoserialize, autoserializeAs } from 'cerialize';

export class Airport {
  @autoserialize id: number;
  @autoserializeAs('first_name') fullName: string;
  @autoserialize city: string;
  @autoserialize country: string;
  @autoserialize icao: string;
  @autoserialize name: string;
  @autoserializeAs('sfid') sfId: string;
  @autoserialize state: string;

  constructor(
    id?: number,
    city?: string,
    country?: string,
    fullName?: string,
    icao?: string,
    name?: string,
    sfId?: string,
    state?: string
  ) {
    this.id = id;
    this.city = city;
    this.country = country;
    this.fullName = fullName;
    this.icao = icao;
    this.name = name;
    this.sfId = sfId;
    this.state = state;
  }
}
