import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FlightService } from "@shared/services/flight.service";
import { Flight } from "../../../../../domain/flight";
import { ActivatedRoute } from "@angular/router";
import { Passenger } from "../../../../../domain/passenger.model";
import { Card } from "../../../../../domain/card";
import { RoutingService } from "@services";
import * as _ from "lodash";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MessageComponent } from "../../../../../components/prompts/message/message.component";
import { CreditCardValidator } from "angular-cc-library";
import { Observable } from "rxjs";
import { BlueSnapService } from "@shared/services/bluesnap.service";

declare let $: any;
declare let bluesnap: any;
@Component({
  selector: "app-steps-itinerary",
  templateUrl: "./steps-itinerary.component.html",
  styleUrls: ["./steps-itinerary.component.scss"],
})
export class StepsItineraryComponent implements OnInit {
  viewMode = "summaryScreen";
  flight: Flight;
  flightId: number;
  offerId: number;
  cateringItems = [];
  passengers: Passenger[];

  isLoading = false;

  @Output() cancelTrip = new EventEmitter();
  public get selectedOfferPrice(): number {
    return this.flight.flightOffers.filter(
      (offer) => offer.id == this.offerId
    )[0].memberHours;
  }
  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private flightService: FlightService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private blueSnapService: BlueSnapService
  ) {
    this.route.params.subscribe((params) => {
      this.flightId = +params.id;
      this.offerId = +params.offerId;
    });
  }

  ngOnInit() {
    this.getFlight();
    this.getFlightPassengers();
  }

  getFlight() {
    this.isLoading = true;
    this.flightService.getFlight(this.flightId).subscribe((flight: Flight) => {
      this.flight = flight;

      if (this.flight.cateringSummary) {
        this.cateringItems = this.flight.cateringSummary.split(",");
      }
      this.isLoading = false;
    });
  }

  getFlightPassengers() {
    this.flightService
      .getFlightPassengers(this.flightId)
      .subscribe((passengers: Passenger[]) => {
        this.passengers = passengers;
      });
  }

  confirmTrip() {
    this.routingService.navigateToFlightBooked();
  }
}
