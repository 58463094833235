import { autoserializeAs } from "cerialize";
import * as moment from "moment";
import { When } from "../when.model";

class Person {
  @autoserializeAs("name") name: string;
  @autoserializeAs("phone") phone: string;
  @autoserializeAs("mobile") mobile: string;
  @autoserializeAs("email") email: string;
}
class Airport {
  @autoserializeAs("city") city: string;
  @autoserializeAs("icao") icao: string;
  @autoserializeAs("fbo") fbo: string;
}
class TZ {
  @autoserializeAs("offset") offset: number;
}
class TravelSegment {
  @autoserializeAs("dt") dt: string;
  @autoserializeAs("when") when: When;
  @autoserializeAs("airport") airport: Airport;
}
class Leg {
  @autoserializeAs("stage") stage: string;
  @autoserializeAs("departure") departure: TravelSegment;
  @autoserializeAs("arrival") arrival: TravelSegment;
  @autoserializeAs("passengers") passengers: any[];

  // this is meant as an unpublished, for debug purposes only attribute
  // It may change at any moment, with or without notice, so please don't
  // rely upon it's presence.
  @autoserializeAs("raw") raw: any;
}
class Trip {
  @autoserializeAs("stage") stage: string;
  @autoserializeAs("tripId") tripId: string;
  @autoserializeAs("tripType") tripType: string;
  @autoserializeAs("amount") amount: number;
  @autoserializeAs("sfid") sfid: string;
  @autoserializeAs("pac") pac: Person;
  @autoserializeAs("legs") legs: Leg[];
  @autoserializeAs("client_notes") clientNotes: string;
  @autoserializeAs("passengers") passengers: string[];

  get dates() {
    const start = this.legs[0];
    const end = this.legs[this.legs.length - 1];

    if (!start || !end) { return ''; }
    const sd = moment(start.departure.dt.split('T')[0]);
    const ed = moment(end.arrival.dt.split('T')[0]);

    if (!sd || !ed) { return ''; }
    return `${sd.format('MM/DD')}-${ed.format('MM/DD')}`;
  }

  // this is meant as an unpublished, for debug purposes only attribute
  // It may change at any moment, with or without notice, so please don't
  // rely upon it's presence.
  // @autoserializeAs("raw") raw: any;

  get legSummary() {
    let segments = [ [] ];

    this.legs.forEach((leg, i) => {
      let last = segments[segments.length - 1];
      let from = leg.departure.airport.icao;
      let to = leg.arrival.airport.icao;
      if (i == 0) {
        last.push(from, to);
        return
      }
      if (last[last.length - 1] != from) {
        segments.push([ from, to ])
        return
      }

      last.push(to);
    });

    return segments.map(s => s.join('-')).join(';');
  }
}

export {
  Leg,
  Trip
}