import { autoserialize, autoserializeAs } from 'cerialize';

export class FBO {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs('sfid') sfId: string;
  @autoserialize phone: string;

  constructor(
    id?: number,
    name?: string,
    sfId?: string,
    phone?: string
  ) {
    this.id = id;
    this.name = name;
    this.sfId = sfId;
    this.phone = phone;
  }
}
