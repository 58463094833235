import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../../shared/services/user.service";
import { AuthenticationService } from "../../../../modules/authentication-module/authentication-service/authentication.service";
import { User } from "@domain";
import { Serialize } from "cerialize";

@Component({
  selector: "app-profile-personal-a",
  templateUrl: "./profile-personal-a.component.html",
  styleUrls: ["./profile-personal-a.component.scss"],
})
export class ProfilePersonalAComponent implements OnInit {
  public user: User;
  private loading: boolean;
  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    console.log(this.authenticationService.currentUserValue.id)
    this.userService
      .get(this.authenticationService.currentUserValue.id)
      .subscribe((user) => {
          console.log(user)
          this.user = user;
        },
        (error) => {
          console.log(error);
          //XXX
        }
      );
  }

  updateProfileGeneralInfo(user: User) {
    this.loading = true;
    this.userService
      .update(this.authenticationService.currentUserValue.id, Serialize(user))
      .subscribe(
        (userResponse) => {
          this.loading = false;
        },
        (error) => {
          console.log(error);
          //XXX
        }
      );
  }
}
