import { autoserialize, autoserializeAs } from "cerialize";

export class Card {
  @autoserializeAs("card_type") cardType: string;
  @autoserializeAs("last_four_digits") lastFourDigits: string;
  @autoserializeAs("id") id: number;
  @autoserializeAs("selected") selected: boolean;
  @autoserializeAs("first_name") firstName: string;
  @autoserializeAs("last_name") lastName: string;
  @autoserializeAs("expiration_date") expirationDate: string;
  @autoserializeAs("card_number") cardNumber: string;
  @autoserializeAs("security_code") securityCode: string;
  @autoserializeAs("token") token: string;

  @autoserializeAs('address1') address1: string;
  @autoserializeAs('address2') address2: string;
  @autoserializeAs('zip') zip: string;
  @autoserializeAs('country') country: string;
  @autoserializeAs('state') state: string;
  @autoserializeAs('city') city: string;
  @autoserializeAs('phone') phone: string;

  @autoserializeAs("payment_type") paymentType: string;

  constructor(
    cardType?: string,
    lastFourDigits?: string,
    id?: number,
    firstName?: string,
    lastName?: string,
    expirationDate?: string,
    securityCode?: string,
    cardNumber?: string,
    token?: string,

    address1?: string,
    address2?: string,
    zip?: string,
    country?: string,
    state?: string,
    city?: string,
    phone?: string,
    paymentType?: string

  ) {
    this.cardType = cardType;
    this.lastFourDigits = lastFourDigits;
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.expirationDate = expirationDate;
    this.securityCode = securityCode;
    this.cardNumber = cardNumber;
    this.token = token;

    this.address1 = address1;
    this.address2 = address2;
    this.zip = zip;
    this.country = country;
    this.state = state;
    this.city = city;
    this.phone = phone;
    this.paymentType = paymentType
  }

  public static OnDeserialized(instance: Card, json: any): void {
    instance.selected = false;
  }
}
