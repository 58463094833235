import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication-service/authentication.service';
import { InfoService, RoutingService } from '@services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private routingService: RoutingService,
    private infoService: InfoService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status >= 500) {
        this.infoService.errorListener.next(true);
      }
      if ([401, 403].indexOf(err.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        if (this.authenticationService.currentUserValue) {
          // logout if there is not refresh token
          if (!this.authenticationService.currentUserValue.authorization.refreshToken) {
            this.authenticationService.logout();
          } else {
            // refresh the token
            this.authenticationService.refreshToken().subscribe(
              suc => {
                location.reload();
              },
              fail => {
                this.authenticationService.logout();
                this.routingService.navigateToLogin();
              }
            );
          }
        } else {
          this.authenticationService.logout();
          this.routingService.navigateToLogin();
        }
      }
      const error = err.error ? err.error.message : err.statusText;
      return throwError(error);
    }));
  }
}
