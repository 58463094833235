import { autoserialize } from 'cerialize';

export class Photo {

  @autoserialize id: number;
  @autoserialize content: Content;

  constructor(
    id?: number,
    content?: Content,
  ) {
    this.id = id;
    this.content = content;
  }

}

export class Content {
  @autoserialize url: string;

  constructor(
    url?: string
  ) {
    this.url = url;
  }
}
