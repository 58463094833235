import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { User } from '@domain';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BlueSnapService } from '@shared/services/bluesnap.service';
import { UserService } from '@shared/services/user.service';
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication-service/authentication.service';
import { Card } from 'src/app/domain/card';
import { StoredWireAch } from 'src/app/domain/stored-wire-ach.model';
import { Serialize } from 'cerialize';

@Component({
  selector: 'app-add-wire-form',
  templateUrl: './add-wire-form.component.html',
  styleUrls: ['./add-wire-form.component.scss']
})
export class AddWireFormComponent implements OnInit {

  private loading: boolean = false;

  public user: User;
  public wireForm: FormGroup;
  public token: string;
  public firstName: string;
  public lastName: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;
  public country: string = "";
  public phone: string;
  public routingNumber: string;
  public accountNumber: string;
  public accountType: string = '';


  @Output() formSuccess = new EventEmitter<boolean>();

  public showWireForm: boolean = false;
  public isAddingWire: boolean = false;
  public userLoaded: boolean = false;
  // public get earliestDate() {
  //   return { year: 1900, month: 1, day: 1 };
  // }

  constructor(

    private userService: UserService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private bluesnapService: BlueSnapService
  ) { }

  ngOnInit() {







    this.userService
      .get(this.authenticationService.currentUserValue.id)
      .subscribe(
        (user) => {

          this.userLoaded = true;
          this.firstName = user.firstName
          this.lastName = user.lastName
          this.user = user;
          console.log(this.user)
          this.createwireForm();

        },
        (error) => {
          console.log(error);
          //XXXError
        }
      )


    // this.wireForm.valueChanges.subscribe((data) => {

    // });
  }



  cancel() {
    let emitter = this.formSuccess;
    emitter.emit(true);
  }

  createWirePayload(): StoredWireAch {
    let newWire = new StoredWireAch()

    //add name
    newWire.firstName = this.firstName;
    newWire.lastName = this.lastName;

    //add wire info
    newWire.accountType = this.accountType;
    newWire.accountNumber = this.wireForm.value.accountNumber;
    newWire.routingNumber = this.wireForm.value.routingNumber;

    //address info
    newWire.address1 = this.wireForm.value.address1;
    newWire.address2 = this.wireForm.value.address2;
    newWire.city = this.wireForm.value.city;
    newWire.state = this.wireForm.value.state;
    newWire.zip = this.wireForm.value.zip;
    newWire.country = this.country
    newWire.phone = this.wireForm.value.phone;



    console.log(newWire)

    return newWire;
  }

  createUpdatedUserPayload() {

    let updatedUser = this.user

    //add user info
    updatedUser.address = this.wireForm.value.address1;

    if (this.wireForm.value.address2.length > 0) {
      updatedUser.address += (", " + this.wireForm.value.address2);
    }
    // updatedUser.address2 = this.wireForm.value.address2;
    updatedUser.city = this.wireForm.value.city;
    updatedUser.state = this.wireForm.value.state;
    updatedUser.zip = this.wireForm.value.zip;
    updatedUser.country = this.country;

    return updatedUser;
  }

  submit() {

    this.isAddingWire = true;

    let wireObj = this.createWirePayload();

    let updatedUser = this.createUpdatedUserPayload();

    this.updateUserInfo(updatedUser);
    this.saveWire(wireObj)

  }

  saveWire(wireObj: StoredWireAch) {

    this.bluesnapService.addWire(wireObj).subscribe(data => {
      console.log(data)
      this.isAddingWire = false;
      let emitter = this.formSuccess;
      emitter.emit()

    },
      (error) => {
        this.isAddingWire = false;
        alert("We are unable to process your payment at this time, please call 844-316-6972 We apologize for the inconvenience.")
        console.log(error);

      })

  }
  selectAccountType(accountType: string, event) {
    event.preventDefault();
    this.accountType = accountType
    this.wireForm.value.accountType = accountType;


  }

  createwireForm() {
    this.wireForm = this.fb.group({
      firstName: new FormControl({ value: this.firstName, disabled: true }, [Validators.required]),
      lastName: new FormControl({ value: this.lastName, disabled: true }, [Validators.required]),
      address1: new FormControl({ value: this.user.address, disabled: false }, [Validators.required]),
      address2: new FormControl("", []),
      city: new FormControl({ value: this.user.city, disabled: false }, [Validators.required]),
      state: new FormControl({ value: this.user.state, disabled: false }, [Validators.required]),
      zip: new FormControl({ value: this.user.zip, disabled: false }, [Validators.required]),

      phone: new FormControl({ value: this.user.phone, disabled: false }, [Validators.required]),

      routingNumber: new FormControl("", [Validators.required]),
      accountNumber: new FormControl("", [Validators.required]),


    });
    this.wireForm.valueChanges.subscribe(data => console.log(data))

  }
  renderAccountType(): string {
    let type = 'Account Type';
    switch (this.accountType) {
      case "CONSUMER_CHECKING":
        type = "Personal Checking";
        break;

      case "CONSUMER_SAVINGS":
        type = "Personal Savings";
        break;

      case "CORPORATE_CHECKING":
        type = "Corporate Checking";
        break;
      case "CORPORATE_SAVINGS":
        type = "Corporate Savings";
        break;
      default:
        break;

    }    return type;

  }

  updateUserInfo(user: User) {
    // this.loading = true;
    this.userService
      .update(this.authenticationService.currentUserValue.id, Serialize(user))
      .subscribe(
        (userResponse) => {
          // this.loading = false;
        },
        (error) => {
          console.log(error);
          //XXX
        }
      );
  }

  countrySelected(event) {
    console.log(event)
    this.country = event;
  }


}
