import { autoserialize, autoserializeAs } from "cerialize";
import { AirCarrier } from "./air-carrier.model";

export class Aircraft {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs("sfid") sfid: string;
  @autoserializeAs("exterior_photo_id") exteriorPhotoId: string;
  @autoserializeAs("interior_photo_id") interiorPhotoId: string;
  @autoserializeAs("passenger_capacity") passengerCapacity: number;
  @autoserializeAs("wifi_equipped") wifiEquipped: boolean;
  @autoserializeAs("enclosed_lav") enclosedLAV: boolean;
  @autoserializeAs(AirCarrier, "operator") carrier: AirCarrier;
  @autoserializeAs("tail_id") tailId: string;
  public selected: boolean;

  @autoserializeAs("cabin_height") cabinHeight: number;
  @autoserializeAs("cabin_length") cabinLength: number;
  @autoserializeAs("cabin_width") cabinWidth: number;
  @autoserializeAs("baggage_external") baggageExternal: number;
  @autoserializeAs("baggage_internal") baggageInternal: number;
  @autoserializeAs("hourly_rate") hourlyRate: number;
  @autoserializeAs("generic_tail_id") genericTailId: string;

  @autoserializeAs("cruising_speed") cruisingSpeed: number;
  @autoserializeAs("range") range: number;
  public isSelected: boolean = false;
  @autoserializeAs("aircraft_details_summary") aircraftDetailsSummary: string;
  @autoserializeAs("aircraft_tagline") aircraftTagline: string;
  constructor(
    id?: number,
    name?: string,
    sfid?: string,
    exteriorPhotoId?: string,
    interiorPhotoId?: string,
    passengerCapacity?: number,
    wifiEquipped?: boolean,
    enclosedLAV?: boolean,
    carrier?: AirCarrier,
    tailId?: string,
    selected?: boolean,
    cabinHeight?: number,
    cabinLength?: number,
    cabinWidth?: number,
    baggageExternal?: number,
    baggageInternal?: number,
    genericTailId?: string,
    crusingSpeed?: number,
    range?: number,
    isSelected?: boolean,
    aircraftDetailsSummary?: string,
    aircraftTagline?: string
  ) {
    this.id = id;
    this.name = name;
    this.sfid = sfid;
    this.exteriorPhotoId = exteriorPhotoId;
    this.interiorPhotoId = interiorPhotoId;
    this.passengerCapacity = passengerCapacity;
    this.wifiEquipped = wifiEquipped;
    this.enclosedLAV = enclosedLAV;
    this.carrier = carrier;
    this.tailId = tailId;
    this.selected = selected;
    this.cabinHeight = cabinHeight;
    this.cabinWidth = cabinWidth;
    this.cabinLength = cabinLength;
    this.baggageExternal = baggageExternal;
    this.baggageInternal = baggageInternal;
    this.genericTailId = genericTailId;
    this.cruisingSpeed = crusingSpeed;
    this.range = range;
    this.isSelected = false;
    this.aircraftDetailsSummary = aircraftDetailsSummary;
    this.aircraftTagline = aircraftTagline;
  }
}
