import { Component, OnInit, Input } from '@angular/core';
import { Flight } from 'src/app/domain/flight';

@Component({
  selector: 'app-flight-overview',
  templateUrl: './flight-overview.component.html',
  styleUrls: ['./flight-overview.component.scss']
})
export class FlightOverviewComponent implements OnInit {

  @Input() public aircraftId: string;
  @Input() public flight: Flight;
  public constructor() { }
  public ngOnInit() {
  }
}
