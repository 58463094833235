import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@services';
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication-service/authentication.service';

@Component({
  selector: 'app-request-received',
  templateUrl: './request-received.component.html',
  styleUrls: ['./request-received.component.scss']
})
export class RequestReceivedComponent implements OnInit {

  public userType: string;
  public constructor(
    private auth: AuthenticationService,
    private routingService: RoutingService
  ) { }
  public ngOnInit(): void {
    this.userType = this.auth.currentUserValue.account.accountType;
  }
}
