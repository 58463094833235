const TRIP_TYPES = {
  ONE_WAY: 'oneWay',
  ROUND_TRIP: 'roundTrip',
  MULTI_LEG: 'multiLeg'
};

const NO_FBO_FOR_AIRPORT_MESSAGE = 'No FBO for selected airport';

export {
  TRIP_TYPES,
  NO_FBO_FOR_AIRPORT_MESSAGE
};
