import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./screens/login/login.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { LaddaModule } from "angular2-ladda";
import { AuthenticationModule } from "./modules/authentication-module/authentication.module";
import { HttpClientModule } from "@angular/common/http";
import { RequestFlightComponent } from "./components/request-flight/request-flight.component";
import {
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbModule,
  NgbTimepickerModule,
  NgbTypeaheadModule,
} from "@ng-bootstrap/ng-bootstrap";
import { MenuComponent } from "./components/menu/menu.component";
import { HomeComponent } from "./screens/home/home.component";
import { MainComponent } from "./screens/main/main.component";
import { ProfileComponent } from "./screens/profile/profile.component";
import { RequestReceivedComponent } from "./screens/request-received/request-received.component";
import { FeaturedRequestFlightComponent } from "./screens/featured-request-flight/featured-request-flight.component";
import { CustomNgbDateParserFormatter } from "@shared/custom-ngb-date-parser-formatter";
import { PlanetAnimationComponent } from "./components/globe-animation/animation.component";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { MyTripsComponent } from "./screens/my-trips/my-trips.component";
import { NotificationsComponent } from "./screens/notifications/notifications.component";
import { ProfilePersonalComponent } from "./screens/profile/profile-components/profile-personal/profile-personal.component";
import { QRCodeModule } from "angularx-qrcode";
import { TripDetailsComponent } from "./screens/my-trips/trip-details/trip-details.component";
import { RequestOffersComponent } from "./screens/my-trips/request-offers/request-offers.component";
import { OfferDetailsComponent } from "./screens/my-trips/offer-details/offer-details.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { FlightDirectionComponent } from "./components/flight-direction/flight-direction.component";
import { FlightDetailsComponent } from "./screens/my-trips/flight-details/flight-details.component";
import { CateringComponent } from "./screens/my-trips/flight-details/steps/catering/catering.component";
import { TransportationComponent } from "./screens/my-trips/flight-details/steps/transportation/transportation.component";
import { PassangerInfoComponent } from "./screens/my-trips/flight-details/steps/passenger-info/passanger-info.component";
import { PaymentComponent } from "./screens/my-trips/flight-details/steps/payment/payment.component";
import { ProfileInFlightPreferencesComponent } from "./screens/profile/profile-components/profile-in-flight-preferences/profile-in-flight-preferences.component";
import { ProfileTravelPreferencesComponent } from "./screens/profile/profile-components/profile-travel-preferences/profile-travel-preferences.component";
import { ProfileOtherComponent } from "./screens/profile/profile-components/profile-other/profile-other.component";
import { FlightBookedComponent } from "./screens/my-trips/flight-booked/flight-booked.component";
import { ProfileResetPasswordComponent } from "./screens/profile/profile-components/profile-reset-password/profile-reset-password.component";
import { ForgotPasswordComponent } from "./screens/forgot-password/forgot-password.component";
import { ProfileImageComponent } from "./screens/profile/profile-components/profile-image/profile-image.component";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { FlightOverviewComponent } from "./components/flight-overview/flight-overview.component";
import { FlightOverview2Component } from "./components/flight-overview2/flight-overview2.component";

import { DialogComponent } from "./components/prompts/dialog/dialog.component";
import { HelpDialogComponent } from "./components/prompts/HelpDialog/dialog.component";

import { MessageComponent } from "./components/prompts/message/message.component";
import { PassengerFormComponent } from "./components/passenger-form/passenger-form.component";
import { Passenger2FormComponent } from "./components/Passenger2Form/Passenger2.component";

import { ContactsComponent } from "./screens/my-trips/flight-details/steps/passenger-info/contacts/contacts.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { AircraftInputComponent } from "./components/request-flight/aircraft-input/aircraft-input.component";
import { FlightLegFormComponent } from "./components/request-flight/flight-leg-form/flight-leg-form.component";
import { FlightItineraryComponent } from "./components/flight-itinerary/flight-itinerary.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ProfilePersonalAComponent } from "./screens/profile/profile-components/profile-personal-a/profile-personal-a.component";
import { AddCardFormComponent } from "./components/credit-card-dashboard/add-card-form/add-card-form.component";
import { CreditCardDashboardComponent } from "./components/credit-card-dashboard/credit-card-dashboard.component";
import { LegDisplayComponent } from "./screens/my-trips/flight-details/leg-display/leg-display.component";
import { MultiLegDisplayComponent } from "./screens/my-trips/flight-details/leg-display/multi-leg-display/multi-leg-display.component";
import { StepsItineraryComponent } from "./screens/my-trips/flight-details/steps/steps-itinerary/steps-itinerary.component";
import { ResetPswdComponent } from "./components/reset-pswd/reset-pswd.component";
import { PassPortComponent } from "./components/pass-port/pass-port.component";
import { DriversLicenseComponent } from "./components/drivers-license/drivers-license.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { CustomerComponent } from "./components/customer/customer.component";
import { TotalFlightOverviewComponent } from "./components/total-flight-overview/total-flight-overview.component";
import { AddWireFormComponent } from "./components/credit-card-dashboard/add-wire-form/add-wire-form.component";
import { CountryDropdownComponent } from "./components/country-dropdown/country-dropdown.component";
import { NewUserComponent } from "./components/prompts/new-user/new-user.component";
import { MembershipFlightRequestComponent } from "./screens/membership-flight-request/membership-flight-request.component";
import { AircraftCardComponent } from "./components/aircraft-card/aircraft-card.component";
import { TripDetailsModalComponent } from "./screens/membership-flight-request/modals/trip-details-modal/trip-details-modal.component";
// import { FlightLegEditFormComponent } from "./screens/membership-flight-request/steps/leg-routing-select/flight-leg-edit-form/flight-leg-edit-form.component";
import { MemberReportComponent } from "./screens/member-report/member-report.component";

//material import
import { MaterialModule } from "./material.module";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { AircraftSelectComponent } from "./screens/membership-flight-request/steps/aircraft-select/aircraft-select.component";
// import { LegRoutingSelectComponent } from "./screens/membership-flight-request/steps/leg-routing-select/leg-routing-select.component";
import { LegRoutingComponent } from "./screens/membership-flight-request/steps/request-flight/leg-routing.component";
import { MemberLegFormComponent } from "./screens/membership-flight-request/steps/request-flight/member-leg-form/member-leg-form.component";
import { PaymentSelectComponent } from "./screens/membership-flight-request/steps/payment-select/payment-select.component";
import { LoadingDialogComponent } from "./screens/membership-flight-request/modals/loading-dialog/loading-dialog.component";
import { CardFormComponent } from "./screens/membership-flight-request/steps/payment-select/card-form/card-form.component";
import { MembershipAmmenityDialogComponent } from "./screens/membership-flight-request/modals/membership-ammenity-dialog/membership-ammenity-dialog.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExploreAircraftModalComponent } from "./screens/membership-flight-request/modals/explore-aircraft-modal/explore-aircraft-modal.component";
import { ItineraryComponent } from "./screens/membership-flight-request/steps/itinerary/itinerary.component";
import { SuccessBannerComponent } from "./components/success-banner/success-banner.component";
import { ErrorBannerComponent } from "./components/error-banner/error-banner.component";
import { EditCateringComponent } from "./components/edit-flight-itinerary/edit-catering/edit-catering.component";
import { EditFlightItineraryComponent } from "./components/edit-flight-itinerary/edit-flight-itinerary.component";

import { EditPassengerInfoComponent } from "./components/edit-flight-itinerary/edit-passenger-info/edit-passenger-info.component";
import { EditTransportationComponent } from "./components/edit-flight-itinerary/edit-transportation/edit-transportation.component";

import { from } from "rxjs";
import { PassengerHealthFormComponent } from "./components/edit-flight-itinerary/passenger-health-form/passenger-health-form.component";
import { MinorHealthFormComponent } from "./components/edit-flight-itinerary/passenger-health-form/minor-health-form/minor-health-form.component";

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RequestFlightComponent,
    MenuComponent,
    HomeComponent,
    MainComponent,
    ProfileComponent,
    RequestReceivedComponent,
    FeaturedRequestFlightComponent,
    PlanetAnimationComponent,
    MyTripsComponent,
    NotificationsComponent,
    ProfilePersonalComponent,
    ProfileInFlightPreferencesComponent,
    ProfileTravelPreferencesComponent,
    ProfileOtherComponent,
    TripDetailsComponent,
    RequestOffersComponent,
    OfferDetailsComponent,
    FlightDirectionComponent,
    FlightDetailsComponent,
    CateringComponent,
    TransportationComponent,
    PassangerInfoComponent,
    PaymentComponent,
    FlightBookedComponent,
    ProfileResetPasswordComponent,
    ForgotPasswordComponent,
    ProfileImageComponent,
    FlightOverviewComponent,
    FlightOverview2Component,
    DialogComponent,
    HelpDialogComponent,
    MessageComponent,
    PassengerFormComponent,
    Passenger2FormComponent,
    ContactsComponent,
    DropdownComponent,
    AircraftInputComponent,
    FlightLegFormComponent,
    FlightItineraryComponent,
    FooterComponent,
    ProfilePersonalAComponent,
    AddCardFormComponent,
    CreditCardDashboardComponent,
    LegDisplayComponent,
    MultiLegDisplayComponent,
    StepsItineraryComponent,
    ResetPswdComponent,
    PassPortComponent,
    DriversLicenseComponent,
    WelcomeComponent,
    CustomerComponent,
    TotalFlightOverviewComponent,
    AddWireFormComponent,
    CountryDropdownComponent,
    NewUserComponent,
    MembershipFlightRequestComponent,
    AircraftCardComponent,
    TripDetailsModalComponent,
    // FlightLegEditFormComponent,
    LegRoutingComponent,
    MemberLegFormComponent,
    AircraftSelectComponent,
    // LegRoutingSelectComponent,
    PaymentSelectComponent,
    LoadingDialogComponent,
    CardFormComponent,
    MembershipAmmenityDialogComponent,
    ExploreAircraftModalComponent,
    ItineraryComponent,
    SuccessBannerComponent,
    ErrorBannerComponent,
    EditCateringComponent,
    EditFlightItineraryComponent,
    EditPassengerInfoComponent,
    EditTransportationComponent,
    PassengerHealthFormComponent,
    MinorHealthFormComponent,
    MemberReportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    LaddaModule,
    AuthenticationModule,
    HttpClientModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbModule,
    LottieModule.forRoot({ player: playerFactory }),
    QRCodeModule,
    SlickCarouselModule,
    CreditCardDirectivesModule,
    MaterialModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new CustomNgbDateParserFormatter("mediumDate"),
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogComponent,
    HelpDialogComponent,
    MessageComponent,
    ContactsComponent,
    NewUserComponent,
    ExploreAircraftModalComponent,
    TripDetailsModalComponent,
    LoadingDialogComponent,
    MembershipAmmenityDialogComponent,
    PassengerHealthFormComponent,
  ],
})
export class AppModule {}
