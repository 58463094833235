import { Authorization } from './authorization.model';
import { autoserialize, autoserializeAs, deserializeAs } from 'cerialize';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { Account } from './account.model';
import { Photo } from './photo.model';
import { NgbDateWrapper } from './NgbDateWrapper.model';
import { Identification } from './identification.model';
import * as moment from "moment";
import { Trip } from './accounts/leg.model';

interface AdjHours {
  adjustment: Number,
  remaining: Number
}

class Balance {
  @autoserialize bought: number;
  @autoserialize remaining: number;
  @autoserialize used: number;
}

class AuthorizedSchedulers {
  @autoserializeAs("scheduler1") scheduler1: string;
  @autoserializeAs("scheduler2") scheduler2: string;
  @autoserializeAs("scheduler3") scheduler3: string;
  @autoserializeAs("scheduler4") scheduler4: string;
  @autoserializeAs("scheduler5") scheduler5: string;
}
class Concessions {
  @autoserializeAs("one_hour_mins_remaining") oneHourMinsRemaining: number;
  @autoserializeAs("peak_surcharge_override") peakSurchargeOverride: number;
  @autoserializeAs("daily_minimum") dailyMinimum: number;
  @autoserializeAs("trip_cancellation_hours") tripCancellationHours: number;
}
class Maturation {
  @autoserializeAs("matured_card") matured: boolean;
  @autoserializeAs("maturation_interval_months") intervalMonths: number;
  @autoserializeAs("next_maturation_date") nextMaturationDate: string;
  @autoserializeAs("interval") interval: number;
}
// class Pricebook {
//   @autoserializeAs("price") price: number;
//   @autoserializeAs("sfid") sfid: string;
//   @autoserializeAs("product_name") name: string;
//   @autoserializeAs("product__c") product: string;
// }

class Cost {
  @autoserializeAs("amount") amount: number;
  @autoserializeAs("unit") unit: string;
}
class LineItem {
  @autoserializeAs("name") name: string;
  @autoserializeAs("description") description: string;
  @autoserializeAs("product") product: any;
  @autoserializeAs("trip") tripNumber: string;
  @autoserializeAs("leg") leg: any;

  // Note: The API does the calculation as to what type of line item this is,
  // and what the cost is.
  @autoserializeAs("cost") _cost: Cost;

  get cost() {
    if (!this._cost) { return undefined; }

    return this._cost.amount;
  }

  get route() {
    if (!this.leg) { return 'N/A'; }
    return `${this.leg.departure_icao}-${this.leg.arrival_icao}`;
  }
  get date() {
    if (!this.leg) { return 'N/A'; }
    // Jan 1, 2021
    const dt = moment(this.leg.departure_date).format("ll");
    return dt;
  }
  get aircraftUsed() {
    if (!this.leg) { return 'N/A'; }
    return this.leg.aircraft;
  }
}

class AdjustmentItem {
  @autoserializeAs("name") name: string;
  @autoserializeAs("date") date: string;
  @autoserializeAs("hours") hours: AdjHours;
}

class Hours {
  @autoserialize bought: number;
  @autoserialize remaining: number;
  @autoserialize used: number;
}

export class MemberCard {
  @autoserialize id: string;
  @autoserialize sfid: string;
  @autoserializeAs('flight_total') flightTotal: number;
  @autoserialize product: string;
  @autoserializeAs('product_name') productName: string;
  @autoserialize isdeleted: boolean;
  @autoserializeAs('amount_remaining__c') amountRemaining: number;
  @autoserializeAs('daily_minimum__c') dailyMinimum: number;
  @autoserializeAs('rate__c') rate: number;
  @autoserializeAs("name") name: string;
  @autoserialize createddate: Date;

  @autoserializeAs('hours_used') hoursUsed: number;
  @autoserializeAs("hours_remaining") hoursRemaining: number;
  @autoserializeAs(Trip, "tripleg") trip: Trip;
  @autoserializeAs(Maturation, "maturation") maturation: Maturation;
  @autoserializeAs(LineItem, "line_items") lineItems: LineItem[];
  @autoserializeAs(AdjustmentItem, "adjustments") adjustments: AdjustmentItem[];

  @autoserializeAs("hours") hours: Balance;
  @autoserializeAs("amount") amount: Balance;
  // @autoserializeAs(Account, "account") account: Account;
  @autoserializeAs("creation_date") createdDate: string;
  @autoserializeAs("comp") comp: any;
  @autoserializeAs(Concessions, "concessions") concessions: Concessions;
  @autoserializeAs(AuthorizedSchedulers, "authorized_schedulers") authorized_schedulers: AuthorizedSchedulers;

  @autoserialize billing_contact: any;

  get remainingBalance() {
    return this.amount && this.amount.remaining;
  }

  get remainingHours() {
    return this.hours && this.hours.remaining;
  }

  get aircraftType() {
    return this.product;
  }

  get totalHoursUsed() {
    return this.hours
      && (
        this.hours.used
        || (
          this.hours.bought
          && this.hours.remaining
          && this.hours.bought - this.hours.remaining
        )
      );
  }

  constructor(
    id?: string,
    sfid?: string,
    amount?: number,
    hours?: number,
    amountRemaining?: number,
    hoursUsed?: number,
  ) {
    this.id = id;
    this.sfid = sfid;
    // this.amount = amount;
    // this.hours = hours;
    this.amountRemaining = amountRemaining;
    this.hoursUsed = hoursUsed;
  }

  get hrsUsed(): number {
    return this.hours && this.hours.used;
  }

  get hrsRemaining(): number | string {
    if (!this.hoursRemaining) {
      const hr = this.hours.remaining;
      if (!hr) { return ''; }
      this.hoursRemaining = hr;
    }
    return this.hoursRemaining;
  }

  public static fromData(data: any) {
    let mc = new MemberCard();
    mc.id = data.id;
    mc.sfid = data.sfid;
    mc.amount = data.amount;

    let h = new Hours();
    h.bought = data.hours;
    h.used = data.hoursUsed;
    h.remaining = data.hoursRemaining;
    mc.hours = h;

    if (data.productName) {
      mc.productName = data.productName;
    }

    return mc;
  }
}

