import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  SimpleChanges,
} from "@angular/core";
import { RoutingService } from "@services";
import { FlightService } from "@shared/services/flight.service";
import * as _ from "lodash";
import { ActivatedRoute } from "@angular/router";
import { FormControl } from "@angular/forms";
import { Flight } from "src/app/domain/flight";
import { FlightLeg } from "src/app/domain/flight-leg.model";

declare let $: any;
@Component({
  selector: "app-catering",
  templateUrl: "./catering.component.html",
  styleUrls: ["./catering.component.scss"],
})
export class CateringComponent implements OnInit {
  public catering;
  public dishesByCategory;
  private selectedCategory = 0;
  public selectedSectionName = "Breakfast";
  public flightId: number;
  private selectedDishes = [];
  private cateringSummary = "";
  public isLoading = false;
  public isSubmitting = false;
  public currentPrice = 0.0;
  public customInput: FormControl;
  @Output() cancelTrip = new EventEmitter();
  @Output() cateringUpdated = new EventEmitter();
  @Input() activeLeg: number;
  public flight: Flight;
  public currentLeg: FlightLeg;

  public currentLegCatering: string;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private flightService: FlightService
  ) {
    this.customInput = new FormControl("");
    this.route.params.subscribe((params) => {
      this.flightId = +params.id;
    });
  }

  ngOnInit() {
    this.getCatering();
    this.activeLeg = 1;
    this.getFlight();
  }

  getFlight() {
    this.isLoading = true;
    this.flightService.getFlight(this.flightId).subscribe((flight: Flight) => {
      this.flight = flight;
      this.currentLeg = this.flight.flightLegs[0];
      this.currentLegCatering = this.currentLeg.cateringSummary;

      this.isLoading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeLeg && this.flight) {
      this.saveCateringOptions(false);
      this.refreshFlight();
      this.getCatering();
      this.currentLeg = this.flight.flightLegs[this.activeLeg - 1];

      this.currentLegCatering = this.currentLeg.cateringSummary;

    } else {
      // console.log(changes);
    }
  }

  refreshFlight() {
    this.isLoading = true;
    this.flightService.getFlight(this.flightId).subscribe((flight: Flight) => {
      this.flight = flight;
      this.isLoading = false;
    });
  }

  saveCateringOptions(nextStep: boolean) {
    this.isSubmitting = true;

    let flightLegId = Number(this.currentLeg.id);

    this.flightService
      .updateLegCatering(flightLegId, this.getCateringSummary())
      .subscribe((data) => {
        if (nextStep) {
          if (this.flight.flightLegs.length > 1 && this.activeLeg != 1) {
            //reset leg display
            let button = $(".leg-button");
            button[0].click();
          }
          this.cateringUpdated.emit();
        }
        this.isSubmitting = false;
      });
  }

  //gets catering
  getCatering = () => {
    this.isLoading = true;
    this.flightService.getCatering().subscribe((response) => {
      this.catering = response;
      this.catering.menu.forEach((menu) => {
        menu.items.forEach((item) => {
          item.count = 0;
        });
      });
      this.catering.menu.push({ section: "Custom", items: [] });
      this.dishesByCategory = _.groupBy(
        this.catering.menu,
        (items) => items.section
      );
      this.isLoading = false;
    });
  };

  selectCategory(i: number, sectionName: string) {
    this.selectedCategory = i;
    this.selectedSectionName = sectionName;
  }

  // setCateringOptions() {
  //   this.isSubmitting = true;
  //   this.flightService
  //     .updateFlightCatering(this.flightId, this.getCateringSummary())
  //     .subscribe(() => {
  //       this.cateringUpdated.emit();
  //       this.isSubmitting = false;
  //     });
  // }

  getCateringSummary() {
    this.selectedDishes = [];
    _.forEach(this.dishesByCategory, (dishCategory) => {
      const selectedDishesInCategory = _.filter(
        dishCategory[0].items,
        (item) => {
          return item.count > 0;
        }
      );
      this.selectedDishes = this.selectedDishes.concat(
        selectedDishesInCategory
      );
    });
    if (this.selectedDishes.length > 0) {
      this.cateringSummary = "";
      this.selectedDishes.forEach((item) => {
        this.cateringSummary = this.cateringSummary.concat(
          item.name + " x" + item.count + ", "
        );
      });
      this.cateringSummary = this.cateringSummary.substring(
        0,
        this.cateringSummary.length - 2
      );
    } else {
      this.cateringSummary = "";
    }
    if (this.customInput.value !== "") {
      let custom: string = this.customInput.value;
      custom = custom.replace(/,/g, " ");
      custom = custom.replace(/\s+/g, " ");
      if (this.selectedDishes.length > 0) custom = ", " + custom;
      this.cateringSummary = this.cateringSummary.concat(custom);
    }
    return this.cateringSummary;
  }

  removeItem(dish) {
    if (dish.count > 0) {
      dish.count = dish.count - 1;
      this.currentPrice = this.currentPrice - parseFloat(dish.price);
    }
  }

  addItem(dish) {
    dish.count = dish.count + 1;
    this.currentPrice = this.currentPrice + parseFloat(dish.price);
  }
}
