import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray } from '@angular/forms';
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication-service/authentication.service';
import { User } from '@domain';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent {

  @Input() contacts: User[];
  public form: FormGroup;
  public selected: string[];
  public get currentUser() : User {
    let userArray: User[] = this.contacts.filter(contact => contact.id === this.authenticationService.currentUserValue.id);
    return (userArray.length > 0) ? userArray[0] : null;
  }
  public get otherContacts() : User[] { return this.contacts.filter(contact => contact.id !== this.authenticationService.currentUserValue.id); }
  public get allContacts() : User[] { return (this.currentUser) ? [this.currentUser, ...this.otherContacts] : this.otherContacts; }
  public get selectionChecks() : FormArray { return <FormArray> this.form.get('selected'); }
  constructor(
    public activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService
    ) {
    this.selected = [];
  }
  public onCheckChange(value) : void {
    if(this.selected.indexOf(value) === -1) {
      this.selected.push(value);
    }
    else {
      this.selected.splice(this.selected.indexOf(value), 1);
    }
  }
  public onSelect() {
    this.activeModal.close(this.selected);
  }
}
