import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Notification } from '@domain';
import { Deserialize } from 'cerialize';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  // TODO modify according to the backend
  public get(id?: number) {
    if (id) {
      return this.http.get<Notification>(environment.baseUrl + 'notifications/' + id).pipe(
        map((notification: Notification) => {
          return Deserialize(notification, Notification);
        }
        ));
    } else {
      return this.http.get<Notification[]>(environment.baseUrl + 'notifications/').pipe(
        map((notifications: Notification[]) => {
          return Deserialize(notifications, Notification);
        }
        ));
    }
  }

  public clear() {
    return this.http.delete(environment.baseUrl + 'notifications/clear');
  }
}

