import { Component, Input, OnChanges } from '@angular/core';
import { Flight } from 'src/app/domain/flight';
import { TRIP_TYPES } from '@shared/constants';
import { FlightService } from '@shared/services/flight.service';

@Component({
  selector: 'app-flight-direction',
  templateUrl: './flight-direction.component.html',
  styleUrls: ['./flight-direction.component.scss']
})
export class FlightDirectionComponent implements OnChanges {
  @Input() public aircraftId: string;
  @Input() public flightLeg: number;
  @Input() public flight: Flight;
  @Input() public isHorizontal = false;
  public flightDuration: string;
  public get firstLeg(): number { return (this.flightLeg != null) ? this.flightLeg : 0; }
  public get lastLeg(): number { return (this.flightLeg != null) ? this.flightLeg : (this.flight.type === TRIP_TYPES.ROUND_TRIP) ? 0 : this.flight.flightLegs.length - 1; }
  public get airportFrom(): string { return this.flight.flightLegs[this.firstLeg].fromAirport.icao; }
  public get cityFrom(): string { return this.flight.flightLegs[this.firstLeg].fromAirport.city; }
  public get airportTo(): string { return this.flight.flightLegs[this.lastLeg].toAirport.icao; }
  public get cityTo(): string { return this.flight.flightLegs[this.lastLeg].toAirport.city; }
  constructor(private flightService: FlightService) { }

  ngOnChanges() {
    if (!this.flightLeg) {
      this.flightLeg = 0;
    }
    const offers = this.flight && this.flight.flightOffers
    if (offers && offers.length > 0) {
      if (!this.aircraftId) {
        const firstOffer = offers && offers[0];
        const plane = firstOffer && firstOffer.aircraft;
        const pid = plane && plane.id;
        this.aircraftId = pid && pid.toString();
      } else {
        if (this.flight.flightLegs) {
          try {
            const legs = this.flight && this.flight.flightLegs;
            const firstLeg = legs && legs[0];
            this.flightDuration = firstLeg && firstLeg.flightTime;
          } catch (e) {
            this.flightDuration = "";
          }
        }
      }
      this.flightService.getFlightDuration(this.flight, this.flightLeg, this.aircraftId)
        .subscribe(
          (duration) => {
            this.flightDuration = duration;
          },
          (err) => {
            console.error("Unknown Flight Duration", err, this.flight, this.flightLeg, this.aircraftId);
          }
        );
    }
  }
}
