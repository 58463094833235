import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./screens/login/login.component";
import { HomeComponent } from "./screens/home/home.component";
import { AuthGuard } from "./modules/authentication-module/auth.guard";
import { MainComponent } from "./screens/main/main.component";
import { RequestFlightComponent } from "./components/request-flight/request-flight.component";
import { ProfileComponent } from "./screens/profile/profile.component";
import { RequestReceivedComponent } from "./screens/request-received/request-received.component";
import { FeaturedRequestFlightComponent } from "./screens/featured-request-flight/featured-request-flight.component";
import { MyTripsComponent } from "./screens/my-trips/my-trips.component";
import { NotificationsComponent } from "./screens/notifications/notifications.component";
import { TripDetailsComponent } from "./screens/my-trips/trip-details/trip-details.component";
import { RequestOffersComponent } from "./screens/my-trips/request-offers/request-offers.component";
import { OfferDetailsComponent } from "./screens/my-trips/offer-details/offer-details.component";
import { FlightDetailsComponent } from "./screens/my-trips/flight-details/flight-details.component";
import { FlightBookedComponent } from "./screens/my-trips/flight-booked/flight-booked.component";
import { ForgotPasswordComponent } from "./screens/forgot-password/forgot-password.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { MembershipFlightRequestComponent } from "./screens/membership-flight-request/membership-flight-request.component";
import { MemberReportComponent } from "./screens/member-report/member-report.component"

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "membership-flight-request",
    component: MembershipFlightRequestComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "membership-flight-request/:id",
    component: MembershipFlightRequestComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "member-cards/:id/statement",
    component: MemberReportComponent,
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  {
    path: "",
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "welcome",
        component: WelcomeComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "request-flight",
        component: RequestFlightComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },

      {
        path: "my-trips",
        component: MyTripsComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "my-trips/trip/:id",
        component: TripDetailsComponent,
        pathMatch: "full",
      },
      {
        path: "my-trips/flight/:trip_num",
        component: TripDetailsComponent,
        pathMatch: "full",
      },
      {
        path: "my-trips/offers/:id",
        component: RequestOffersComponent,
        pathMatch: "full",
      },
      {
        path: "my-trips/offers/:id/offer-details/:offerId",
        component: OfferDetailsComponent,
        pathMatch: "full",
      },
      {
        path: "my-trips/offers/:id/offer-details/:offerId/flight-details",
        component: FlightDetailsComponent,
        pathMatch: "full",
      },
      {
        path: "my-trips/flight-booked",
        component: FlightBookedComponent,
        pathMatch: "full",
      },
      {
        path: "notifications",
        component: NotificationsComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profile",
        component: ProfileComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "request-received",
        component: RequestReceivedComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "welcome" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

// const routes: Routes = [
//   {
//     path: 'login',
//     component: LoginComponent
//   },
//   {
//     path: 'forgot-password',
//     component: ForgotPasswordComponent,
//   },
//   {
//     path: 'request-received',
//     component: RequestReceivedComponent,
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'make-a-flight-request',
//     component: FeaturedRequestFlightComponent,
//     canActivate: [AuthGuard],
//   },
//   {
//     path: '',
//     component: MainComponent,
//     canActivate: [AuthGuard],
//     children: [
//       // {
//       //   path: 'home',
//       //   component: HomeComponent,
//       //   pathMatch: 'full'
//       // },

//       {
//         path: 'welcome',
//         component: WelcomeComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'request-flight',
//         component: RequestFlightComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips',
//         component: MyTripsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/trip/:id',
//         component: TripDetailsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/offers/:id',
//         component: RequestOffersComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/offers/:id/offer-details/:offerId',
//         component: OfferDetailsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/offers/:id/offer-details/:offerId/flight-details',
//         component: FlightDetailsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/flight-booked',
//         component: FlightBookedComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'notifications',
//         component: NotificationsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'profile',
//         component: ProfileComponent,
//         pathMatch: 'full'
//       },
//       // otherwise redirect to home
//       {path: '**', redirectTo: 'welcome'}
//     ]
//   }
// ];

//   {
//     path: 'request-received',
//     component: RequestReceivedComponent,
//     canActivate: [AuthGuard],
//   },
//   {
//     path: 'make-a-flight-request',
//     component: FeaturedRequestFlightComponent,
//     canActivate: [AuthGuard],
//   },
//   {
//     path: '',
//     component: MainComponent,
//     canActivate: [AuthGuard],
//     children: [
//       {
//         path: 'home',
//         component: HomeComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'request-flight',
//         component: RequestFlightComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips',
//         component: MyTripsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/trip/:id',
//         component: TripDetailsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/offers/:id',
//         component: RequestOffersComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/offers/:id/offer-details/:offerId',
//         component: OfferDetailsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/offers/:id/offer-details/:offerId/flight-details',
//         component: FlightDetailsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'my-trips/flight-booked',
//         component: FlightBookedComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'notifications',
//         component: NotificationsComponent,
//         pathMatch: 'full'
//       },
//       {
//         path: 'profile',
//         component: ProfileComponent,
//         pathMatch: 'full'
//       },

//       // otherwise redirect to home
//       {path: '**', redirectTo: 'request-flight'}
//     ]
//   }
// ];
