import { Component, OnInit, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BlueSnapService } from "@shared/services/bluesnap.service";
import { Card } from "src/app/domain/card";

declare let $: any;
declare let bluesnap: any;

@Component({
  selector: "app-card-form",
  templateUrl: "./card-form.component.html",
  styleUrls: ["./card-form.component.scss"],
})
export class CardFormComponent implements OnInit {
  public token: string;

  private _submitCardForm: boolean = false;

  public creditCardForm: NgForm;

  @Input() set submitCardForm(value: boolean) {
    console.log(value);
    this._submitCardForm = value;
    if (this._submitCardForm) {
      this.submit();
    }
  }

  get submitCardForm(): boolean {
    return this._submitCardForm;
  }

  constructor(public blueSnapService: BlueSnapService) {}

  ngOnInit() {
    this.getHostedFields();
  }

  public onFormChange(creditCardForm: NgForm) {
    this.creditCardForm = creditCardForm;
  }

  getHostedFields() {
    this.blueSnapService.getToken().subscribe((data: any) => {
      let tokenSplit = data.token.split("/");
      let length = tokenSplit.length;
      this.token = tokenSplit[length - 1];
      this.loadHostedPaymentToken(this.token);
      console.log(this.token);
    });
  }

  loadHostedPaymentToken(token: string) {
    let self = this;
    let cardUrl = {
      AmericanExpress: "https://files.readme.io/97e7acc-Amex.png",
      CarteBleau: "https://files.readme.io/5da1081-cb.png",
      DinersClub: "https://files.readme.io/8c73810-Diners_Club.png",
      Discover: "https://files.readme.io/caea86d-Discover.png",
      JCB: "https://files.readme.io/e076aed-JCB.png",
      MaestroUK: "https://files.readme.io/daeabbd-Maestro.png",
      MasterCard: "https://files.readme.io/5b7b3de-Mastercard.png",
      Solo:
        "https://sandbox.bluesnap.com/services/hosted-payment-fields/cc-types/solo.png",
      Visa: "https://files.readme.io/9018c4f-Visa.png",
    };
    let bsObj = {
      onFieldEventHandler: {
        changeImpactedElement(tagId, removeClass, addClass) {
          removeClass = removeClass || "";
          addClass = addClass || "";
          $("[data-bluesnap=" + tagId + "]")
            .removeClass(removeClass)
            .addClass(addClass);
        },
        onFocus: function (tagId) {
          // Handle focus
          this.changeImpactedElement(tagId, "", "hosted-field-focus");
        },
        onBlur: function (tagId) {
          // Handle blur
          this.changeImpactedElement(tagId, "hosted-field-focus");
        },
        onError: function (tagId, errorCode, errorDescription) {
          // Handle a change in validation by displaying help text
          $("#" + tagId + "-help")
            .removeClass("helper-text-green")
            .text(errorDescription);
        },
        onType: function (tagId, cardType, cardData) {
          // get card type from cardType and display card image
          $("#card-logo > img").attr(
            "src",
            `assets/images/credit-cards/${cardType}.png`
          );
        },
        onValid: function (tagId) {
          // Handle a change in validation by removing any help text
          $("#" + tagId + "-help").text("");
        },
      },
      style: {
        // styling bluesnap inputs
        input: {
          "font-size": "14px",

          color: "#555",
          "border-bottom": "solid #7f7f7f 1px",
          height: "30px",
        },
        // Styling input state
        ":focus": {
          color: "#555",
          "border-bottom": "solid black 1px",
        },
      },
      ccnPlaceHolder: "1234 5678 9012 3456",
      cvvPlaceHolder: "123",
      expPlaceHolder: "MM / YY",
      expDropDownSelector: false, //set to true for exp date dropdown
    };
    bluesnap.hostedPaymentFieldsCreation(token, bsObj);
  }

  submit() {
    let blueSnapService = this.blueSnapService;

    let firstName = this.creditCardForm.value.firstName;
    let lastName = this.creditCardForm.value.lastName;

    // address info
    let address = this.creditCardForm.value.address;
    let state = this.creditCardForm.value.state;
    let phone = this.creditCardForm.value.phone;
    let city = this.creditCardForm.value.city;
    let zip = this.creditCardForm.value.zip;

    let token = this.token;

    bluesnap.submitCredentials(function (callback) {
      if (null != callback.error) {
        var errorArray = callback.error;
        for (let i in errorArray) {
          $("#" + errorArray[i].tagId + "-help").text(
            errorArray[i].errorDescription
          );
        }
      } else {
        var cardData = callback.cardData;

        let newCard = new Card();
        newCard.cardNumber = cardData.last4Digits;
        newCard.expirationDate = cardData.exp;
        newCard.token = token;
        newCard.firstName = firstName;
        newCard.lastName = lastName;
        newCard.address1 = address;
        newCard.state = state;
        newCard.city = city;
        newCard.zip = zip;
        newCard.phone = phone;

        blueSnapService.addCard(newCard).subscribe(
          (data) => {
            console.log(data);
            bluesnap.closeCheckout();
          },
          (error) => {
            alert(
              "We are unable to process your payment at this time, please call 844-316-6972 We apologize for the inconvenience."
            );
            console.log(error);
          }
        );
      }
    });
  }
}
