import { autoserialize, autoserializeAs } from 'cerialize';

export class AirCarrier {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs('primary_contact_email') primaryContactEmail: string;
  @autoserialize phone: string;

  constructor(
    id?: number,
    name?: string,
    primaryContactEmail?: string,
    phone?: string
  ) {
    this.id = id;
    this.name = name;
    this.primaryContactEmail = primaryContactEmail;
    this.phone = phone;
  }
}
