import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import * as moment from "moment";
import { CustomValidators } from "@shared/custom-validators.service";
import { FlightService } from "@shared/services/flight.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Flight } from "src/app/domain/flight";
import { Passenger } from "src/app/domain/passenger.model";
import { AuthenticationService } from "src/app/modules/authentication-module/authentication-service/authentication.service";

const AGREEMENT_TERMS = [
  "a. tested positive with the Coronavirus or been identified as a potential carrier of the COVID-19 virus or similar communicable illness (“Coronavirus”); ",
  "b. experienced any symptoms commonly associated with the Coronavirus,including an elevated temperature; ",
  "c. been in any location positively designated as hazardous and/or potentially infected with the Coronavirus by a recognized health or regulatory authority;",
  "d. been in direct contact with or the immediate vicinity of any person I knew and/or now know to be carrying the Coronavirus or has been identified as a potential carrier of the Coronavirus;",
  "e. Travelled to/through/from any countries currently listed by the U.S. Department of State of Level 3 or 4.",
];

const PASSENGER_CONTROLS = [
  { name: "signature" },
  { name: "phoneNumber" },
  {
    name: "signatureDate",
  },
];

@Component({
  selector: "app-passenger-health-form",
  templateUrl: "./passenger-health-form.component.html",
  styleUrls: ["./passenger-health-form.component.scss"],
})
export class PassengerHealthFormComponent implements OnInit {
  public minorHealthForms: FormGroup[] = [];
  public submittingForm: boolean = false;
  public paxHealthForm: FormGroup = new FormGroup({});
  public loadingPassengers: boolean = false;
  public termsToBeAgreedUpon = AGREEMENT_TERMS;
  public termAgreements: boolean[] = new Array(AGREEMENT_TERMS.length).fill(
    false
  );
  public tripPassengers = [];

  @Input() public flight: Flight;

  constructor(
    private customValidators: CustomValidators,
    private flightService: FlightService,
    public activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.getFlightPassengers();
  }

  getFlightPassengers() {
    this.loadingPassengers = true;
    this.flightService
      .getFlightPassengers(this.flight.id)
      .subscribe((passengers: Passenger[]) => {
        this.tripPassengers = passengers;
        this.tripPassengers.forEach((item, i) => {
          if (item.user.id === this.authenticationService.currentUserValue.id) {
            this.tripPassengers.splice(i, 1);
            this.tripPassengers.unshift(item);
          }
        });

        this.loadingPassengers = false;
      });
  }

  addMinorHealthForm() {
    const minorPaxAmount = this.minorHealthForms.length;

    //check if an incompleted minor pax form exists before creating new one
    if (minorPaxAmount > 0) {
      const lastHealthForm = this.minorHealthForms[minorPaxAmount - 1];

      if (
        lastHealthForm.value.passengerFirstName !== "" &&
        lastHealthForm.value.passengerLastName !== ""
      ) {
        this.minorHealthForms.push(new FormGroup({}));
      } else {
        alert(
          "Please fill out the first and last name of the current passenger."
        );
      }
    } else {
      this.minorHealthForms.push(new FormGroup({}));
    }
  }

  submitHealthForms() {
    this.submittingForm = true;
    const passengerObject = {
      flightSfid: this.flight.sfid,
      primary_passenger: {
        signature_date: this.paxHealthForm.value.signatureDate,
        passenger_name: this.paxHealthForm.value.signature,
      },
      minor_passengers: [],
    };

    this.minorHealthForms.forEach((form) => {
      const minorPax = {
        passenger_first_name: form.value.passengerFirstName,
        passenger_last_name: form.value.passengerLastName,
        birthday: form.value.birthDate,
      };
      passengerObject.minor_passengers.push(minorPax);
    });

    this.flightService.submitPassengerHealthForm(passengerObject).subscribe(
      (data) => {
        this.submittingForm = false;
        this.activeModal.close(true);
      },
      (e) => (this.submittingForm = false)
    );
  }
  removeMinorPassenger(index: number) {
    this.minorHealthForms.splice(index, 1);
  }

  private initializeForm(): void {
    PASSENGER_CONTROLS.forEach((controlData) => this.addControl(controlData));

    // auto load primary pax phone number
    this.paxHealthForm.controls.phoneNumber.setValue(
      this.authenticationService.currentUserValue.phone
        ? this.authenticationService.currentUserValue.phone
        : ""
    );
  }

  private hasControl(controlData): boolean {
    return !!this.paxHealthForm.get(controlData.name);
  }

  private addControl(controlData): void {
    let value: any =
      controlData.defaultValue != null ? controlData.defaultValue : "";
    let validators: any[] = controlData.validation
      ? [
          Validators.required,
          ...controlData.validation.map(
            (validator) => this.customValidators[validator]
          ),
        ]
      : [Validators.required];
    this.paxHealthForm.addControl(
      controlData.name,
      new FormControl(value, validators)
    );
  }

  private removeControl(controlData): void {
    this.paxHealthForm.removeControl(controlData.name);
  }
}
