import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Serialize } from "cerialize";
import { NO_FBO_FOR_AIRPORT_MESSAGE, TRIP_TYPES } from "@shared/constants";
import { RoutingService } from "@services";
import { FlightRequest, User } from "@domain";
import { Aircraft } from "src/app/domain/aircraft.model";
import { FlightLeg } from "src/app/domain/flight-leg.model";
import { FlightService } from "@shared/services/flight.service";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DialogComponent } from "src/app/components/prompts/dialog/dialog.component";
import { Flight } from "src/app/domain/flight";

@Component({
  selector: "app-leg-routing",
  templateUrl: "./leg-routing.component.html",
  styleUrls: ["./leg-routing.component.scss"],
})
export class LegRoutingComponent implements OnInit {
  @Input() aircrafts: Aircraft[] = [];

  public legForms: FormGroup[] = [];
  public membershipAccount: boolean = false;
  public legDepartures: NgbDate[];
  public tripType: string = "";

  @Output() public flightCreatedEvent = new EventEmitter<number>();

  //first key is logic value, second key is text label spelling
  public tripTypes: object[] = [
    { type: "oneWay", label: "One Way" },
    { type: "multiLeg", label: "Multi - City" },
    { type: "roundTrip", label: "Round Trip" },
  ];

  private flightLegs: FlightLeg[];

  constructor(
    private routingService: RoutingService,
    private flightService: FlightService,
    private modalService: NgbModal
  ) {
    // initialize the flight legs form;
    this.addFlightLeg();
  }

  ngOnInit(): void {
    this.legDepartures = Array(this.legForms.length).fill(null);
  }

  requestFlight(): void {
    if (!this.formsValid()) {
      return;
    }

    this.flightLegs = [];
    this.legForms.forEach((legForm) => {
      this.flightLegs.push(FlightLeg.fromFormData(legForm.value));
    });
    let today = new Date();
    let depart = new Date(
      this.flightLegs[0].departureDate + " " + this.flightLegs[0].departureTime
    );

    let howSoon = depart.valueOf() - today.valueOf();
    const TooSoonMS = 24 * 1000 * 60 * 60; //24 hours

    if (howSoon < TooSoonMS) {
      this.warnTooSoon();
    } else {
      this.sendTripRequest();
    }
  }

  warnTooSoon() {
    let modalRef = this.modalService.open(DialogComponent, {
      centered: true,
      size: "md",
      backdrop: "static",
    });
    let result = false;
    modalRef.componentInstance.title = "Warning";
    modalRef.componentInstance.acceptButton = "Continue";
    modalRef.componentInstance.declineButton = "Cancel";
    modalRef.componentInstance.message =
      "You are attempting to book a trip within 24 hours of your requested departure time.  We may not be able to process this request in time to meet your needs. Please call us at 617-328-5387 to book within 24 hours.";
    modalRef.result.then((confirmed) => {
      if (confirmed) {
        this.sendTripRequest();
        this.routingService.navigateToHome();
      }
    });
  }

  sendTripRequest() {
    if (this.tripType === TRIP_TYPES.ROUND_TRIP) {
      this.flightLegs.push(FlightLeg.createReturnLeg(this.legForms[0].value));
    }
    let flightRequest = new FlightRequest(this.flightLegs);
    // const user: User = JSON.parse(localStorage.getItem("currentUser"));
    flightRequest.jetMembershipTrip = true;
    flightRequest.aircraftType = this.legForms[0].value.aircraftType;

    const aircraft = this.aircrafts && this.aircrafts.find(
      (ac) => ac.name === flightRequest.aircraftType
    );

    const aircraftId = (aircraft && aircraft.sfid) || "";

    if (aircraftId != "") {
      flightRequest.flightLegs.forEach((x: any) => {
        x.aircraft = aircraftId;
        x.departureDate = new Date(
          x.departureDate.getTime() -
          x.departureDate.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
      });

      this.flightService.flightRequest(flightRequest).subscribe(
        (data: Flight) => {
          this.flightCreatedEvent.emit((data && data.id) || 0);
        },
        (err) => {
          console.error("Unable to request trip", err);
          this.flightCreatedEvent.emit(0);
        }
      );
    } else {
      // console.error("Error saving aircraft to flight");
      this.flightCreatedEvent.emit(0);
    }
  }

  selectTripType(type: string): void {
    this.tripType = type;
    if (type != TRIP_TYPES.MULTI_LEG) {
      this.legForms.splice(1);
    }
  }

  addFlightLeg(): void {
    this.legForms.push(new FormGroup({}));
  }

  removeFlightLeg(index: number): void {
    this.legForms.splice(index, 1);
  }

  formsValid(): boolean {
    let valid: boolean = true;

    this.legForms.forEach(
      (legForm) =>
      (valid =
        valid && legForm.valid && legForm.value.departureTime.length == 5)
    );
    valid =
      valid &&
      (this.tripType !== TRIP_TYPES.MULTI_LEG || this.legForms.length > 1);
    return valid;
  }

  dateSelected(index: number, value: NgbDate): void {
    this.legDepartures[index] = value;
    for (let i = index + 1; i < this.legDepartures.length; i++) {
      this.legForms[i].controls.departureDate.reset();
      this.legDepartures[i] = null;
    }
  }
}
