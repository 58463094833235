import { Component } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-planet-animation',
  template: `<ng-lottie [options]="options"></ng-lottie>`,
  styleUrls: ['./animation.component.scss'],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})

export class PlanetAnimationComponent {
  options: AnimationOptions = {
    path: 'assets/planet-animation.json',
    autoplay: true,
    loop: true
  };

  constructor() { }
}
