import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@shared/custom-validators.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FlightService } from '@shared/services/flight.service';
import { Airport } from '../../domain/airport.model';

@Component({
  selector: 'app-home ',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public searchFlightForm: FormGroup;
  private today = { day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() };
  public flights = [
    {
      imageUrl: 'https://robbreportedit.files.wordpress.com/2018/05/pilatus-pc-24.jpg?w=1000',
      planeModel: 'The Embraer Legacy 600',
      duration: 22,
      noOfSeats: 9,
      wifi: true,
      enclosedLAV: true,
      date: new Date()
    },
    {
      imageUrl: 'https://s3-eu-west-1.amazonaws.com/lnjt-prod/aircrafts/gulfstream/gulfstream-g650/gulfstream-g650-aircraft-hero.jpg',
      planeModel: 'Gulfstream G650',
      duration: 12.5,
      noOfSeats: 16,
      wifi: true,
      enclosedLAV: false,
      date: new Date()
    },
    {
      imageUrl: 'https://www.gulfstream.com/efs/images/d_g600_a_mkt_00037.jpg',
      planeModel: 'Gulfstream G500',
      duration: 5,
      noOfSeats: 10,
      wifi: false,
      enclosedLAV: true,
      date: new Date()
    },
    {
      imageUrl: 'https://robbreportedit.files.wordpress.com/2018/05/pilatus-pc-24.jpg?w=1000',
      planeModel: 'The Embraer Legacy 600',
      duration: 24,
      noOfSeats: 9,
      wifi: true,
      enclosedLAV: true,
      date: new Date()
    },
    {
      imageUrl: 'https://s3-eu-west-1.amazonaws.com/lnjt-prod/aircrafts/gulfstream/gulfstream-g650/gulfstream-g650-aircraft-hero.jpg',
      planeModel: 'Gulfstream G650',
      duration: 15.5,
      noOfSeats: 16,
      wifi: true,
      enclosedLAV: false,
      date: new Date()
    },
    {
      imageUrl: 'https://www.gulfstream.com/efs/images/d_g600_a_mkt_00037.jpg',
      planeModel: 'Gulfstream G500',
      duration: 2,
      noOfSeats: 10,
      wifi: false,
      enclosedLAV: true,
      date: new Date()
    },
    {
      imageUrl: 'https://robbreportedit.files.wordpress.com/2018/05/pilatus-pc-24.jpg?w=1000',
      planeModel: 'The Embraer Legacy 600',
      duration: 2.5,
      noOfSeats: 9,
      wifi: true,
      enclosedLAV: true
    },
    {
      imageUrl: 'https://s3-eu-west-1.amazonaws.com/lnjt-prod/aircrafts/gulfstream/gulfstream-g650/gulfstream-g650-aircraft-hero.jpg',
      planeModel: 'Gulfstream G650',
      duration: 5.5,
      noOfSeats: 16,
      wifi: true,
      enclosedLAV: false,
      date: new Date()
    },
    {
      imageUrl: 'https://www.gulfstream.com/efs/images/d_g600_a_mkt_00037.jpg',
      planeModel: 'Gulfstream G500',
      duration: 4,
      noOfSeats: 10,
      wifi: false,
      enclosedLAV: true,
      date: new Date()
    }
  ];

  constructor(
    private fb: FormBuilder,
    private customValidators: CustomValidators,
    private flightService: FlightService,
  ) {
  }

  ngOnInit() {
    this.createSearchFlightForm();
  }

  createSearchFlightForm() {
    this.searchFlightForm = this.fb.group({
      from: new FormControl('', [
        this.customValidators.hasFieldId
      ]),
      to: new FormControl('', [
        this.customValidators.hasFieldId
      ]),
      departure: new FormControl('', []),
      passengers: new FormControl('', [])
    });
  }

  // Typeahead filters

  searchAirport = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.flightService.getAirports(term, 10)
      )
    )
  // End of typeahead filters

  // Typeahead formatters

  airportFormatter = (airport: Airport) => {
    if (airport) {
      return (airport.icao || '') + ', '
        + (airport.city || '') + ' '
        + (airport.country === 'United States' ? airport.state : airport.country);
    } else {
      return '';
    }
  }

  // End of typeahead formatters

  onSubmit() {

  }

}
