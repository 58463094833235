import { Authorization } from "./authorization.model";
import { autoserialize, autoserializeAs, deserializeAs } from "cerialize";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Account } from "./account.model";
import { Photo } from "./photo.model";
import { NgbDateWrapper } from "./NgbDateWrapper.model";
import { Identification } from "./identification.model";
import { count } from 'rxjs/operators';

export class User {
  @autoserialize id: number;
  @autoserialize email: string;
  @autoserialize password: string;
  @autoserializeAs("first_name") firstName: string;
  @autoserializeAs("last_name") lastName: string;
  @autoserializeAs("birth_date") birthday: string;
  @autoserializeAs("weight") weight: number;
  @autoserializeAs("document_number") documentNumber: number;
  @autoserializeAs("expiration_date") expirationDate: number;
  @autoserializeAs(Account, "account") account: Account;
  @autoserializeAs("photo") photo: Photo;
  @autoserialize authorization: Authorization;
  @deserializeAs(Identification, "identification")
  @autoserializeAs(Identification, "identification")
  identification: Identification;
  public passengerId: number;
  @autoserializeAs("phone") phone: string;
  @autoserializeAs("address") address: string;
  @autoserializeAs("city") city: string;
  @autoserializeAs("zip") zip: string;
  @autoserializeAs("country") country: string;
  @autoserializeAs("state") state: string;



  constructor(
    id?: number,
    email?: string,
    password?: string,
    firstName?: string,
    lastName?: string,
    weight?: number,
    documentNumber?: number,
    expirationDate?: number,
    account?: Account,
    authorization?: Authorization,
    identification?: Identification,
    city?: string,
    state?: string,
    country?: string,
    phone?: string,
    zip?: string,
    address?: string,

  ) {
    this.id = id;
    this.email = email;
    this.password = password;
    this.firstName = firstName;
    this.lastName = lastName;
    this.weight = weight;
    this.documentNumber = documentNumber;
    this.expirationDate = expirationDate;
    this.account = account;
    this.authorization = authorization;
    this.identification = identification;

    this.state = state;
    this.address = address;
    this.country = country;
    this.city = city;
    this.zip = zip;
    this.phone = phone;
  }

  public static fromData(data: any) {
    const user = new User();
    user.id = data.id;
    user.email = data.email;
    user.password = data.password;
    user.firstName = data.firstName;
    user.lastName = data.lastName;
    user.birthday = data.birthday;
    user.weight = data.weight;
    user.documentNumber = data.documentNumber;
    user.expirationDate = data.expirationDate;
    user.authorization = data.authorization;

    user.identification = new Identification();
    if (data.account) {
      user.account = Account.fromData(data.account);
    }

    if (data.identification) {
      user.identification.birthDate = data.identification.birthDate;
      user.identification.id = data.identification.id;
      user.identification.contact = data.identification.contact;
      user.identification.licenseExpirationDate = data.identification.licenseExpirationDate;
      user.identification.licenseNumber = data.identification.licenseNumber;
      user.identification.licenseIssuingState = data.identification.licenseIssuingState;
      user.identification.passportExpiration = data.identification.passportExpiration;
      user.identification.passportIssueDate = data.identification.passportIssueDate;
      user.identification.passportIssuingCountry = data.identification.passportIssuingCountry;
      user.identification.passportNumber = data.identification.passportNumber;
    }

    user.passengerId = data.passengerId;
    return user;
  }

  public static fromFormData(formData: any): User {
    console.log(formData)
    const user = User.fromData(formData);
    user.birthday = NgbDateWrapper.fromNgbDate(formData.birthday);
    user.identification = Identification.fromFormData(formData.identification);
    console.log(user)
    return user;
  }

  public toFormGroup(): FormGroup {
    let identificationTest;
    if (!this.identification) {
      identificationTest = new Identification();
    } else {
      identificationTest = this.identification;
    }
    return new FormGroup({
      id: new FormControl(this.id || ""),
      passengerId: new FormControl(this.passengerId || ""),
      email: new FormControl(this.email || ""),
      password: new FormControl(this.password || ""),
      firstName: new FormControl(this.firstName || "", Validators.required),
      lastName: new FormControl(this.lastName || "", Validators.required),
      birthday: new FormControl(NgbDateWrapper.toNgbDate(this.birthday) || null),
      weight: new FormControl(this.weight || "", Validators.required),
      documentNumber: new FormControl(this.documentNumber || ""),
      expirationDate: new FormControl(this.expirationDate || ""),
      authorization: new FormControl(this.authorization || ""),
      identification: identificationTest.toFormGroup(),
    });
  }

  public toFormGroup_User(oldUser): FormGroup {
    let identificationTest;
    if (!this.identification) {
      identificationTest = new Identification();
    } else {
      identificationTest = this.identification;
    }
    return new FormGroup({
      id: new FormControl(this.id || ""),
      passengerId: new FormControl(this.passengerId || ""),
      email: new FormControl(this.email || ""),
      password: new FormControl(this.password || ""),
      firstName: new FormControl(this.firstName || "", Validators.required),
      lastName: new FormControl(this.lastName || "", Validators.required),
      birthday: new FormControl(NgbDateWrapper.toNgbDate(this.birthday) || null),
      weight: new FormControl(this.weight || "", Validators.required),
      documentNumber: new FormControl(this.documentNumber || ""),
      expirationDate: new FormControl(this.expirationDate || ""),
      authorization: new FormControl(this.authorization || ""),
      identification: identificationTest.toFormGroup(),
    });
  }
}
