import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../modules/authentication-module/authentication-service/authentication.service";
import { UserService } from "./../../shared/services/user.service";
import { ImageService } from "@shared/services/image.service";
import { Router } from "@angular/router";
// import { ModalService } from '../../services/modal.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HelpDialogComponent } from "src/app/components/prompts/HelpDialog/dialog.component";
declare let $: any;

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public collapseMenuOpen = false;
  public userFullName = "";
  public photoUrl: string;
  private user: any;
  public get shortMenu(): boolean {
    return this.router.url === "/my-trips";
  }
  public showMenu = false;

  public membershipTrip: boolean = false;
  constructor(
    private router: Router,
    public imageService: ImageService,
    public userService: UserService,
    public authenticationService: AuthenticationService,
    private modalService: NgbModal // private modalService: ModalService
  ) { }
  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe((user) => {
      if (user) {
        this.showMenu = true;
        this.user = user;
        this.updateForm();
        this.membershipTrip = this.isMembership(user);
      }
    });
  }
  closeMenu() {
    $(".navbar-collapse").collapse("hide");
    if (this.collapseMenuOpen) {
      this.collapseMenuOpen = false;
    }
  }

  private isMembership(user) {
    if (!user) { return false; }
    if (!user.account) { return false; }
    if (!user.account.accountType) { return false; }
    return user.account.accountType.toLowerCase() == 'jet membership';
  }

  private updateForm() {
    if (this.user) {
      this.userFullName = `${this.user.firstName} ${this.user.lastName}`;
      if (this.user.photo) {
        this.imageService.get(this.user.photo.id).subscribe((result: any) => {
          this.photoUrl = result.content.url;
        });
      } else {
        //Use generic image
        this.photoUrl = "assets/images/genericPerson.png";
      }
    } else {
      this.userFullName = "";
    }
  }

  toggleMenu() {
    this.collapseMenuOpen = !this.collapseMenuOpen;
  }

  public onShowHelp() {
    let modalRef = this.modalService.open(HelpDialogComponent, {
      backdropClass: "modal-dialog-centered",
    });
    modalRef.componentInstance.title = "Having Trouble?";
    modalRef.componentInstance.link =
      "https://magellanjets.com/privacy-policy-and-legal-information";
    modalRef.componentInstance.linkText = "More...";
    modalRef.componentInstance.message = `Your satisfaction is our top priority.
    If you need help with planning a flight or an active flight, contact your Flight Support team at <a href="tel:844-316-6972">844-316-6972</a>.`;
  }
}
