import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@environment/environment";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(private http: HttpClient) {}

  public getPDFDocument(url: string) {
    return this.http.get(environment.baseUrl + url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        responseType: "blob",
      }),
      responseType: "blob" as "json",
    });
  }
}
