import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FlightRequest } from "@domain";
import { RoutingService } from "@services";
import { environment } from "@environment/environment";
import { FlightService } from "@shared/services/flight.service";
import { ImageService } from "@shared/services/image.service";
import { Flight } from "src/app/domain/flight";

@Component({
  selector: "app-leg-display",
  templateUrl: "./leg-display.component.html",
  styleUrls: ["./leg-display.component.scss"],
})
export class LegDisplayComponent implements OnInit {
  public tripId: number;
  public photo: any;
  public environment = environment;
  @Input() public aircraftId: string;
  @Input() public flightLeg: number;
  @Input() public flight: Flight;
  @Output() activeLeg = new EventEmitter<number>();
  public tripLoaded: boolean;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private flightService: FlightService,
    private imageService: ImageService
  ) {
    this.tripLoaded = false;
    this.route.params.subscribe((params) => {
      this.tripId = +params.id;
      this.flightService.get(this.tripId).subscribe(
        (flight) => {
          this.flight = flight;
          this.tripLoaded = true;
        },
        (err) => {
          this.routingService.navigateToMyTrips();
        }
      );
    });
  }

  ngOnInit() { }

  onLegSelection(legNum: number): void {
    this.activeLeg.emit(legNum);
  }
}
