import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private http: HttpClient) { }
  public get(photoId: string) {
    return this.http.get(environment.baseUrl + 'photos/' + photoId);
  }
  public getSalesforceImage(photoId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'image/png'
      })
    };
    return this.http.get(environment.baseUrl + 'photos/salesforce/' + photoId, httpOptions);
  }
}
