import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../../../shared/services/user.service';
import {AuthenticationService} from '../../../../modules/authentication-module/authentication-service/authentication.service';
import {User} from '@domain';
import {Account} from '../../../../domain/account.model';
import {Serialize} from 'cerialize';

@Component({
  selector: 'app-profile-other',
  templateUrl: './profile-other.component.html',
  styleUrls: ['./profile-other.component.scss']
})
export class ProfileOtherComponent implements OnInit {
  public profileOtherInfoForm: FormGroup;
  private loading: boolean;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.loading = false;
    this.createForm();
    this.userService.get(this.authenticationService.currentUserValue.id).subscribe(user => {
      this.createForm(user);
    }, error => {
      console.log(error);
    });
  }

  createForm = (user?: User) => {
    if (user) {
      this.profileOtherInfoForm = this.fb.group({
        preferredMethodOfCommunication: new FormControl(user.account.preferredMethodOfCommunication),
        preferredCarRentalCompany: new FormControl(user.account.preferredCarRentalCompany)
      });
    } else {
      this.profileOtherInfoForm = this.fb.group({
        preferredMethodOfCommunication: new FormControl(''),
        preferredCarRentalCompany: new FormControl('')
      });
    }
  }

  updateOtherInfo() {
    this.loading = true;
    const user: User = new User();
    user.account = new Account();
    user.account.preferredCarRentalCompany = this.profileOtherInfoForm.controls.preferredCarRentalCompany.value;
    user.account.preferredMethodOfCommunication = this.profileOtherInfoForm.controls.preferredMethodOfCommunication.value;
    this.userService.update(this.authenticationService.currentUserValue.id, Serialize(user)).subscribe(userResponse => {

      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

}
