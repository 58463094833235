import { autoserializeAs } from 'cerialize';
import { MemberCard } from './member-card.model';

export class Account {
  @autoserializeAs('home_airport') homeAirport: string;
  @autoserializeAs('preferred_diversions') preferredDiversions: string;
  @autoserializeAs('preferred_fbo_s') preferredFBOs: string;
  @autoserializeAs('preferred_car_rental_company') preferredCarRentalCompany: string;
  @autoserializeAs('in_flight_comfort_preferences') inFlightComfortPreferences: string;
  @autoserializeAs('catering') catering: string;
  @autoserializeAs('allergies') allergies: string;
  @autoserializeAs('preferred_method_of_communication') preferredMethodOfCommunication: string;
  @autoserializeAs('account_type') accountType: string;
  @autoserializeAs('remaining_hours') remainingHours: number;
  @autoserializeAs(MemberCard, 'member_cards') memberCards: MemberCard[];

  constructor(
    homeAirport?: string,
    preferredDiversions?: string,
    preferredFBOs?: string,
    preferredCarRentalCompany?: string,
    inFlightComfortPreferences?: string,
    catering?: string,
    allergies?: string,
    preferredMethodOfCommunication?: string,
    accountType?: string,
    remainingHours?: number,
    memberCards?: MemberCard[]
  ) {
    this.homeAirport = homeAirport;
    this.preferredDiversions = preferredDiversions;
    this.preferredFBOs = preferredFBOs;
    this.preferredCarRentalCompany = preferredCarRentalCompany;
    this.inFlightComfortPreferences = inFlightComfortPreferences;
    this.catering = catering;
    this.allergies = allergies;
    this.preferredMethodOfCommunication = preferredMethodOfCommunication;
    this.accountType = accountType;
    this.remainingHours = remainingHours;
    this.memberCards = memberCards;
  }

  public static fromData(data) {
    return new Account(
      data.home_airport,
      data.preferredDiversions,
      data.preferredFBOs,
      data.preferredCarRentalCompany,
      data.inFlightComfortPreferences,
      data.catering,
      data.allergies,
      data.preferredMethodOfCommunication,
      data.accountType,
      data.remainingHours,
      data.memberCards
    );
  }

  public sortMemberCards(prop) {
    if (this.memberCards.length <= 1) {
      return this.memberCards;
    }
    return this.memberCards.sort(
      (a, b) => {
        const av = a[prop];
        const bv = b[prop]
        if (!av) { return 1; }
        if (!bv) { return -1; }
        return av.localeCompare(bv, 'en-us', { ignorePunctuation: true })
      });
  }
}
