import { autoserialize, autoserializeAs, Serialize } from "cerialize";
import { FlightOffer } from "@domain";
import { FlightLeg } from "./flight-leg.model";

export class FlightRequest {
  @autoserializeAs("flight_legs") flightLegs: FlightLeg[];
  @autoserializeAs("flight_offers") flightOffers: FlightOffer[];
  @autoserializeAs("stage_name") stageName: string;
  @autoserializeAs("status") status: string;
  @autoserializeAs("sfid") sfid: string;
  @autoserialize id: number;
  @autoserializeAs("has_offers") hasOffers: boolean;
  @autoserializeAs("aircraft_type") aircraftType: string;
  @autoserializeAs("member_trip") memberTrip: boolean;
  @autoserializeAs("jet_membership_trip") jetMembershipTrip: boolean;

  constructor(
    flightLegs?: FlightLeg[],
    flightOffers?: FlightOffer[],
    stageName?: string,
    status?: string,
    sfid?: string,
    id?: number,
    hasOffers?: boolean,
    aircraftType?: string,
    memberTrip?: boolean,
    jetMembershipTrip?: boolean
  ) {
    this.flightLegs = flightLegs;
    this.flightOffers = flightOffers;
    this.stageName = stageName;
    this.status = status;
    this.sfid = sfid;
    this.id = id;
    this.hasOffers = hasOffers;
    this.aircraftType = aircraftType;
    this.memberTrip = memberTrip;
    this.jetMembershipTrip = jetMembershipTrip;
  }
  public toData(): any {
    let flightLegs = this.flightLegs.map((flightLeg) => {
      let legData = Serialize(flightLeg);
      legData.from_airport = flightLeg.fromAirport.sfId;
      legData.to_airport = flightLeg.toAirport.sfId;
      legData.number_of_passenger = this.flightLegs[0].noOfPassengers;
      return legData;
    });

    return {
      flight_legs: flightLegs,
      member_trip: this.memberTrip,
      jet_membership_trip: this.jetMembershipTrip,
      aircraft_type: this.aircraftType,
    };
  }
}
