import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {Airport} from '../../../../domain/airport.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FlightService} from '../../../../shared/services/flight.service';
import {CustomValidators} from '../../../../shared/custom-validators.service';
import {FBO, User} from '@domain';
import {AuthenticationService} from '../../../../modules/authentication-module/authentication-service/authentication.service';
import {Account} from '../../../../domain/account.model';
import {UserService} from '../../../../shared/services/user.service';
import {Serialize} from 'cerialize';

@Component({
  selector: 'app-profile-travel-preferences',
  templateUrl: './profile-travel-preferences.component.html',
  styleUrls: ['./profile-travel-preferences.component.scss']
})
export class ProfileTravelPreferencesComponent implements OnInit {
  private travelPreferencesForm: FormGroup;
  private user: User;
  private alternativeAirports: string[] = [];
  private alternativeFBOs: string[] = [];
  private allFBOs = [];

  constructor(
    private fb: FormBuilder,
    private customValidators: CustomValidators,
    private flightService: FlightService,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.flightService.getAllFBOs().subscribe(
      next => {
        this.allFBOs = next;
      },
      error => {
        console.log(error);
      }
    );
    if (this.authenticationService.currentUserValue.account.homeAirport) {
      this.flightService.getAirportBySFID(this.authenticationService.currentUserValue.account.homeAirport).subscribe(
        next => {
          this.user = this.authenticationService.currentUserValue;
          if (next instanceof Airport) {
            this.travelPreferencesForm.controls.homeAirport.setValue(next);
          }
        },
        error => {
          console.log(error);
        }
      );
    }
    this.createForm();
    this.alternativeAirports = [];
    if (this.authenticationService.currentUserValue.account.preferredDiversions) {
      this.authenticationService.currentUserValue.account.preferredDiversions.split('; ').map((name: string) => {
        this.alternativeAirports.push(name);
      });
    }

    this.alternativeFBOs = [];
    if (this.authenticationService.currentUserValue.account.preferredFBOs) {
      this.authenticationService.currentUserValue.account.preferredFBOs.split('; ').map((name: string) => {
        this.alternativeFBOs.push(name);
      });
    }
  }

  searchAirport = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>  this.flightService.getAirports(term, 10))
    );

  searchFBO = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.flightService.getFBOByTerm(term, 10)
      )
    );

  airportFormatter = (airport: Airport) => {
    if (airport) {
      return (airport.icao || '') + ', '
        + (airport.city || '') + ' '
        + (airport.country === 'United States' ? airport.state : airport.country);
    } else {
      return '';
    }
  };

  fboFormatter = (fbo: FBO) => {
    if (fbo) {
      return (fbo.name);
    } else {
      return '';
    }
  };

  createForm() {
    this.travelPreferencesForm = this.fb.group({
      homeAirport: new FormControl('', [
        Validators.required,
        this.customValidators.hasFieldId
      ]),
      alternativeAirport: new FormControl('', [
        Validators.required,
        this.customValidators.hasFieldId
      ]),
      preferredFBO: new FormControl('', [
        Validators.required,
        this.customValidators.hasFieldId
      ]),
    });
  }

  addFBO = () => {
    if (this.travelPreferencesForm.get('preferredFBO').value !== '') {
      this.alternativeFBOs.push(this.travelPreferencesForm.get('preferredFBO').value.name);
      this.travelPreferencesForm.markAsDirty();
      this.travelPreferencesForm.controls.preferredFBO.setValue('');
    }
  };

  removeFBO = (index) => {
    this.alternativeFBOs.splice(index, 1);
    this.travelPreferencesForm.markAsDirty();
  };

  addAirport = () => {
    if (this.travelPreferencesForm.get('alternativeAirport').value !== '') {
      this.alternativeAirports.push(this.travelPreferencesForm.get('alternativeAirport').value.name);
      this.travelPreferencesForm.markAsDirty();
      this.travelPreferencesForm.controls.alternativeAirport.setValue('');
    }
  };

  removeAirport = (index) => {
    this.alternativeAirports.splice(index, 1);
    this.travelPreferencesForm.markAsDirty();
  };

  updateProfileTravelPreferences = () => {
    /*let prefFBOs = '';
    this.alternativeFBOs.map((fbo) => {
      prefFBOs += fbo;
      prefFBOs += '; ';
    });*/
    let altAirports = '';
    this.alternativeAirports.map((airport) => {
      altAirports += airport;
      altAirports += '; ';
    });
    //prefFBOs = prefFBOs.slice(0, -2);
    altAirports = altAirports.slice(0, -2);
    const acc = new Account();
    //acc.preferredFBOs = prefFBOs;
    acc.preferredDiversions = altAirports;
    acc.homeAirport = this.travelPreferencesForm.controls.homeAirport.value.sfId;
    if (!this.travelPreferencesForm.controls.homeAirport.value.sfId) {
      acc.homeAirport = '';
    }
    const u = new User();
    u.account = acc;
    this.userService.update(this.authenticationService.currentUserValue.id, Serialize(u, User)).subscribe(
      next => {
        const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.account = next.account;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.authenticationService.currentUserSubject.next(currentUser);
        this.ngOnInit();
      },
      error => {
        console.log(error);
      }
    );
  };

}
