import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "./../../modules/authentication-module/authentication-service/authentication.service";
import { NotificationService } from "./../../shared/services/notification.service";
import { User } from "@domain";
import { MemberCard } from '../../domain/member-card.model';
import { RoutingService } from "@services";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  public user: User;
  public firstName = "";
  public noteCount: number = 0;
  private loading: boolean;
  public membershipTrip: boolean = false;

  get activeMemberCard() {
    const cu = this.authenticationService.currentUserValue;
    if (!cu) { return undefined; }
    const acct = cu.account;
    if (!acct) { return undefined; }

    const mcs = acct.memberCards;
    if (!mcs) { return undefined; }

    if (!Array.isArray(mcs) || !mcs.length) { return undefined; }

    const mc = MemberCard.fromData(mcs[0]);
    return mc;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private notifications: NotificationService,
    private routingService: RoutingService
  ) { }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(
      (user) => {
        if (user) {
          this.noteCount = 0;
          this.firstName = user.firstName;
          this.membershipTrip = user.account.accountType.toLowerCase() == "jet membership";
          this.notifications.get().subscribe((notes) => {
            if (notes) {
              this.noteCount = notes.length;
            }
          });
        }
      },
      (error) => {
        console.error("Unable to locate user", error);
        //XXX
      }
    );
  }
}
