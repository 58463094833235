import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Flight } from "src/app/domain/flight";

export interface FlightDialogData {
  flight: Flight;
}
@Component({
  selector: "app-trip-details-modal",
  templateUrl: "./trip-details-modal.component.html",
  styleUrls: ["./trip-details-modal.component.scss"],
})
export class TripDetailsModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public flight: FlightDialogData) {}

  ngOnInit() {
    console.log(this.flight);
  }
}
