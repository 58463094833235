import { autoserialize, autoserializeAs } from "cerialize";
import { Airport } from "./airport.model";
import { Aircraft } from "./aircraft.model";
import { NgbDateWrapper } from "./NgbDateWrapper.model";
import * as moment from "moment";

export class FlightLeg {
  @autoserializeAs("number_of_passenger") noOfPassengers: number;
  @autoserializeAs(Airport, "from_airport") fromAirport: Airport;
  @autoserializeAs(Airport, "to_airport") toAirport: Airport;
  @autoserializeAs("departure_fbo") departureFbo: string;
  @autoserializeAs("departure_date") departureDate: string;
  @autoserializeAs("departure_time") departureTime: string;
  @autoserializeAs("arrival_fbo") arrivalFbo: string;
  @autoserializeAs("arrival_date") arrivalDate: string;
  @autoserializeAs("arrival_time") arrivalTime: string;
  @autoserializeAs("flight_time") flightTime: string;
  @autoserializeAs(Aircraft, "aircraft") aircraft: Aircraft;
  @autoserializeAs("stage") stage: string;
  @autoserialize id: string;
  @autoserializeAs("arrival_ground_summary") arrivalGroundSummary: string;
  @autoserializeAs("departure_ground_summary") departureGroundSummary: string;
  @autoserializeAs("catering_summary") cateringSummary: string;
  @autoserializeAs("international_health_form") internationalHealthForm: string;
  @autoserializeAs("attestation_form_filename") attestationFormFilename: string;
  public legHasRequiredInputs: boolean;

  constructor(
    noOfPassengers?: number,
    fromAirport?: Airport,
    toAirport?: Airport,
    departureFbo?: string,
    departureDate?: string,
    departureTime?: string,
    arrivalTime?: string,
    arrivalFbo?: string,
    arrivalDate?: string,
    flightTime?: string,
    id?: string,
    stage?: string,
    arrivalGroundSummary?: string,
    departureGroundSummary?: string,
    cateringSummary?: string,
    legHasRequiredInputs?: boolean
  ) {
    this.noOfPassengers = noOfPassengers;
    this.fromAirport = fromAirport;
    this.toAirport = toAirport;
    this.departureFbo = departureFbo;
    this.departureDate = departureDate;
    this.departureTime = departureTime;
    this.arrivalFbo = arrivalFbo;
    this.arrivalDate = arrivalDate;
    this.arrivalTime = arrivalTime;
    this.flightTime = flightTime;
    this.id = id;
    this.stage = stage;
    this.arrivalGroundSummary = arrivalGroundSummary;
    this.departureGroundSummary = departureGroundSummary;
    this.cateringSummary = cateringSummary;
    this.legHasRequiredInputs = true;
  }
  public static OnDeserialized(instance: FlightLeg, json: any): void {
    instance.departureTime = moment(json.departure_time, "H:mm").format(
      "hh:mm a"
    );
    instance.arrivalTime = moment(json.arrival_time, "H:mm").format("hh:mm a");
  }
  public static fromData(data: any): FlightLeg {
    const flightLeg = new FlightLeg();
    flightLeg.noOfPassengers = data.noOfPassengers;
    flightLeg.fromAirport = data.fromAirport;
    flightLeg.toAirport = data.toAirport;
    flightLeg.departureFbo = data.departureFbo;
    flightLeg.departureDate = data.departureDate;
    flightLeg.departureTime = data.departureTime;
    flightLeg.departureDate = data.departureDate;
    flightLeg.departureTime = data.departureTime;
    flightLeg.arrivalFbo = data.departureFbo;
    flightLeg.flightTime = data.flightTime;
    flightLeg.id = data.id;
    flightLeg.stage = data.stage;
    return flightLeg;
  }
  public static fromFormData(formData: any): FlightLeg {
    const flightLeg = FlightLeg.fromData(formData);
    flightLeg.departureDate = formData.departureDate;

    return flightLeg;
  }
  public static createReturnLeg(data: any): FlightLeg {
    let flightLeg: FlightLeg = new FlightLeg();
    flightLeg.noOfPassengers = data.noOfPassengers;
    flightLeg.fromAirport = data.toAirport;
    flightLeg.toAirport = data.fromAirport;
    flightLeg.departureDate = data.returnDate;
    flightLeg.departureTime = data.returnTime;
    return flightLeg;
  }
}
