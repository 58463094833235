import { autoserializeAs } from 'cerialize';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateWrapper } from './NgbDateWrapper.model';


export class Identification {
  @autoserializeAs('id') id: number;
  @autoserializeAs('birth_date') birthDate: string;
  @autoserializeAs('contact') contact: string;
  @autoserializeAs('license_expiration_date') licenseExpirationDate: string;
  @autoserializeAs('license_issuing_state') licenseIssuingState: string;
  @autoserializeAs('license_number') licenseNumber: string;
  @autoserializeAs('passport_expiration') passportExpiration: string;
  @autoserializeAs('passport_fields') passportFields: string;
  @autoserializeAs('passport_issue_date') passportIssueDate: string;
  @autoserializeAs('passport_issuing_country') passportIssuingCountry: string;
  @autoserializeAs('passport_number') passportNumber: string;
  @autoserializeAs('sfid') sfid: string;

  constructor(
    id?: number,
    birthDate?: string,
    contact?: string,
    licenseExpirationDate?: string,
    licenseIssuingState?: string,
    licenseNumber?: string,
    passportExpiration?: string,
    passportFields?: string,
    passportIssueDate?: string,
    passportIssuingCountry?: string,
    passportNumber?: string,
    sfid?: string,
  ) {
    this.id = id;
    this.birthDate = birthDate;
    this.contact = contact;
    this.licenseExpirationDate = licenseExpirationDate;
    this.licenseIssuingState = licenseIssuingState;
    this.licenseNumber = licenseNumber;
    this.passportExpiration = passportExpiration;
    this.passportFields = passportFields;
    this.passportIssueDate = passportIssueDate;
    this.passportIssuingCountry = passportIssuingCountry;
    this.passportNumber = passportNumber;
    this.sfid = sfid;
  }

  public static fromData(data: any) {
    console.log(data)
    const identification = new Identification();
    identification.id = data.id;
    identification.birthDate = data.birthDate;
    identification.contact = data.contact;
    identification.licenseExpirationDate = data.licenseExpirationDate;
    identification.licenseIssuingState = data.licenseIssuingState;
    identification.licenseNumber = data.licenseNumber;
    identification.passportExpiration = data.passportExpiration;
    identification.passportFields = data.passportFields;
    identification.passportIssueDate = data.passportIssueDate;
    identification.passportIssuingCountry = data.passportIssuingCountry;
    identification.passportNumber = data.passportNumber;
    identification.sfid = data.sfid;
    console.log(identification)
    return identification;
  }

  public toFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(this.id || ''),
      birthDate: new FormControl(this.birthDate || ''),
      contact: new FormControl(this.contact || ''),
      licenseExpirationDate: new FormControl(NgbDateWrapper.toNgbDate(this.licenseExpirationDate) || null),
      licenseIssuingState: new FormControl(this.licenseIssuingState || ''),
      licenseNumber: new FormControl(this.licenseNumber || ''),
      passportExpiration: new FormControl(NgbDateWrapper.toNgbDate(this.passportExpiration) || null),
      passportFields: new FormControl(this.passportFields || ''),
      passportIssueDate: new FormControl(NgbDateWrapper.toNgbDate(this.passportIssueDate) || null),
      passportIssuingCountry: new FormControl(this.passportIssuingCountry || ''),
      passportNumber: new FormControl(this.passportNumber || ''),
      sfid: new FormControl(this.sfid || '')
    });
  }

  public static fromFormData(formData: any): Identification {
    const identification = Identification.fromData(formData);
    identification.licenseExpirationDate = NgbDateWrapper.fromNgbDate(formData.licenseExpirationDate);
    identification.passportExpiration = NgbDateWrapper.fromNgbDate(formData.passportExpiration);
    identification.passportIssueDate = NgbDateWrapper.fromNgbDate(formData.passportIssueDate);
    return identification;
  }
}
