import { autoserialize, autoserializeAs } from 'cerialize';
import { Aircraft } from './aircraft.model';


export class FlightOffer {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs('sfid') sfid: string;
  @autoserializeAs('amount_to_present') amountToPresent: number;
  @autoserializeAs('client_notes') clientNotes: string;
  @autoserializeAs('payment_options') paymentOptions: string;
  @autoserializeAs('membercard_hours') memberHours: number;
  @autoserializeAs(Aircraft, 'aircraft') aircraft: Aircraft;
  @autoserializeAs("client_agreed_to_option") selected: boolean;

  constructor(
    id?: number,
    name?: string,
    sfid?: string,
    amountToPresent?: number,
    clientNotes?: string,
    paymentOptions?: string,
    aircraft?: Aircraft
  ) {
    this.id = id;
    this.name = name;
    this.sfid = sfid;
    this.amountToPresent = amountToPresent;
    this.clientNotes = clientNotes;
    this.paymentOptions = paymentOptions;
    this.aircraft = aircraft;
  }
}
