import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbTime } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';

export class NgbDateWrapper {

  public static fromNgbDate(date: any): string {
    let month: string;
    let day: string;
    if (!date) { return null; }
    if (date.month < 10) { month = '0' + date.month; } else { month = date.month + ''; }
    if (date.day < 10) { day = '0' + date.day; } else { day = date.day + ''; }
    return date.year + '-' + month + '-' + day;
  }

  public static toNgbDate(date: string): NgbDate {
    if (!date) { return null; }
    const components = date.split('-');
    return new NgbDate(NgbDateWrapper.intFrom(components[0]), NgbDateWrapper.intFrom(components[1]), NgbDateWrapper.intFrom(components[2]));
  }

  public static fromNgbTime(time: NgbTime): string {
    return time.hour + ':' + time.minute;
  }

  public static intFrom(s: string): number {
    return parseInt(s, 10);
  }
}
