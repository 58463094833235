import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MemberCard } from "../../domain/member-card.model"
import { environment } from "@environment/environment";


@Injectable({
  providedIn: "root",
})
export class MemberCardService {
  constructor(private http: HttpClient) { }

  public getMemberCard(memberCardId: string) {
    const url = `${environment.baseUrl}member_cards/${memberCardId}`;
    return this.http.get<MemberCard>(url);
  }

  public getMemberCardStatement(memberCardId: string) {
    const url = `${environment.baseUrl}member_cards/${memberCardId}/statement`;
    return this.http.get<MemberCard>(url);
  }

}