import { autoserialize, autoserializeAs } from 'cerialize';
import { Identification } from './identification.model';
import { User } from '@domain';
import { FormGroup, FormControl } from '@angular/forms';

export class Passenger {
    @autoserializeAs('id') id: number;
    @autoserializeAs(Identification, 'identification') identification: Identification;
    @autoserializeAs('name') name: string;
    @autoserializeAs('sfid') sfid: string;
    @autoserializeAs(User, 'user') user: User;

    constructor(
        id?: number,
        identification?: Identification,
        name?: string,
        sfid?: string,
        user?: User
    ) {
        this.id = id;
        this.identification = identification;
        this.name = name;
        this.sfid = sfid;
        this.user = user;
    }
    public toUser(): User {
        let user: User = User.fromData(this.user);
        user.identification = this.identification;
        user.passengerId = this.id;
        return user;
    }
}
