import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environment/environment";
import { Aircraft } from "./../../domain/aircraft.model";
import { map } from "rxjs/operators";
import { Deserialize } from "cerialize";

@Injectable({
  providedIn: "root",
})
export class AircraftService {
  constructor(private http: HttpClient) {}

  private genericAircraft = [
    { name: "Light Jet", maxPax: 6 },
    { name: "Mid Jet", maxPax: 7 },
    { name: "Super Mid", maxPax: 8 },
    { name: "Heavy Jet", maxPax: 12 },
  ];
  private aircraftMaxPax = [
    { name: "Hawker 400XP", maxPax: 6 },
    { name: "Phenom 300", maxPax: 6 },
    { name: "Hawker 800XP", maxPax: 7 },
    { name: "Citation X", maxPax: 8 },
    { name: "Challenger 300", maxPax: 8 },
    { name: "Falcon 2000", maxPax: 10 },
    { name: "Gulfstream IV", maxPax: 12 },
    { name: "Gulfstream 450", maxPax: 12 },
    { name: "Sikorsky S-76", maxPax: 5 },
    { name: "Sikorsky S76", maxPax: 5 },
    { name: "Sikorsky S-76 C++", maxPax: 5 },
    { name: "Sikorsky S-76 C+", maxPax: 5 },
    { name: "Sikorsky S-76B", maxPax: 5 },
  ];

  private updateAircraftMaxPax(ac: Aircraft) {
    let changed = false;
    this.aircraftMaxPax.forEach((el) => {
      if (ac.name.indexOf(el.name) > -1) {
        if (!ac.passengerCapacity) {
          ac.passengerCapacity = el.maxPax;
          changed = true;
        }
      }
    });
  }
  private addGenericAircraft(aircrafts: any[]) {
    this.genericAircraft.forEach((ac) => {
      let temp = new Aircraft();
      temp.passengerCapacity = ac.maxPax;
      temp.name = ac.name;
      aircrafts.push(temp);
    });
  }
  public getTypes() {
    return this.http
      .get<Aircraft>(environment.baseUrl + "aircraft_types")
      .pipe(
        map((aircraft: Aircraft) => {
          let temp = Deserialize(aircraft, Aircraft);
          temp.forEach((element) => {
            this.updateAircraftMaxPax(element);
          });
          this.addGenericAircraft(temp);
          localStorage.setItem("aircraft", JSON.stringify(temp));
          return temp;
        })
      );
  }

  public getCategories() {
    return this.http.get(environment.baseUrl + "aircraft_types/categories");
  }

  public getMembershipAircraft() {
    return this.http
      .get(environment.baseUrl + "aircraft_types/membership_aircraft")
      .pipe(
        map((aircraft: Aircraft) => {
          return Deserialize(aircraft, Aircraft);
        })
      );
  }

  public getAircraftWithTailIds(query: string) {
    return this.http
      .get(environment.baseUrl + "aircraft_types/aircraft_with_tail_ids", {
        params: {
          q: query,
        },
      })
      .pipe(
        map((aircraft: Aircraft) => {
          return Deserialize(aircraft, Aircraft);
        })
      );
  }
}
