import { Component, OnInit, ViewChild } from "@angular/core";
import { AircraftService } from "@shared/services/aircraft.service";
import { Aircraft } from "src/app/domain/aircraft.model";
import { Flight } from "src/app/domain/flight";
import { LegRoutingComponent } from "./steps/request-flight/leg-routing.component";

import { FlightLeg } from "src/app/domain/flight-leg.model";

import { AircraftSelectComponent } from "./steps/aircraft-select/aircraft-select.component";
import { Deserialize } from "cerialize";
import { FlightService } from "@shared/services/flight.service";
import { RoutingService } from "@shared/services";
import { SuccessBannerComponent } from "src/app/components/success-banner/success-banner.component";

@Component({
  selector: "app-membership-flight-request",
  templateUrl: "./membership-flight-request.component.html",
  styleUrls: ["./membership-flight-request.component.scss"],
})
export class MembershipFlightRequestComponent implements OnInit {
  public flightId: number = 0;

  public flightLegs: FlightLeg[] = [];

  public flight: Flight;

  //contains all generic membership aircraft
  public membershipAircrafts: Aircraft[] = [];

  //membershift aircraft is loaded or not
  public aircraftLoaded: boolean = false;

  public stageNames: string[] = ["Build My Trip", "Optimize"];

  //what step the user is on shown by booleans in an array of length: steps
  //only one step is ever true at a time
  public currentStep: boolean[] = [true, false, false];

  //what steps are completed by the user,
  //step 2 and step 2requires the user to create a flight in step 1

  public stepsComplete: boolean[] = [false, false, false];

  public continueLoader: boolean = false;

  @ViewChild(LegRoutingComponent, { static: false })
  legRoutingSelectScreen: LegRoutingComponent;

  @ViewChild(AircraftSelectComponent, { static: false })
  aircraftSelectComponent: AircraftSelectComponent;

  @ViewChild(SuccessBannerComponent, { static: false })
  successBannerComponent: SuccessBannerComponent;

  constructor(
    private aircraftService: AircraftService,
    private flightService: FlightService,
    private routingService: RoutingService
  ) { }

  ngOnInit() {
    this.loadMembershipAircraft();
  }

  //loads generic membership aircraft
  loadMembershipAircraft() {
    this.aircraftService
      .getMembershipAircraft()
      .subscribe((aircrafts: Aircraft[]) => {
        this.membershipAircrafts = aircrafts.sort(
          (a: Aircraft, b: Aircraft) => a.hourlyRate - b.hourlyRate
        );
        this.aircraftLoaded = true;
      });
  }

  goToNextStage() {
    //one one stage in the currentStep array will ever have an index of true at a time
    const currentStage = this.currentStep.findIndex(Boolean);

    this.continueLoader = true;
    switch (currentStage) {
      case 0:
        this.legRoutingSelectScreen.requestFlight();
        break;
      case 1:
        this.aircraftSelectComponent.updateFlight();
        break;
      case 2:
        this.completeTripRequest();
        break;
      default:
        console.error("programming error; unable to determine step");
        this.goToSelectedStage(0);
        break;
    }
  }

  completeTripRequest() {
    this.flightService
      .changeStageName(this.flightId, "Needs Quote")
      .subscribe(() => {
        this.continueLoader = false;
        this.routingService.navigateToMyTrips();
      });
  }

  isStepChangeAllowed(selectedIndex: number): boolean {
    return (
      this.flightId > 0 && selectedIndex > this.currentStep.findIndex(Boolean)
    );
  }

  goToSelectedStage(index: number) {
    if (this.isStepChangeAllowed(index)) {
      this.currentStep = [false, false, false];
      this.currentStep[index] = true;
    }
  }

  flightCreatedEvent(event) {
    this.continueLoader = false;
    if (event > 0) {
      this.flightId = event;
      this.goToSelectedStage(1);
      this.stepsComplete[0] = true;
    } else {
      console.error("Error creating flight.");
    }
  }

  itineraryReadyEvent(flight) {
    this.continueLoader = false;
    if (flight !== undefined) {
      this.flight = flight;
      this.goToSelectedStage(2);
      this.stepsComplete[1] = true;
      this.successBannerComponent.showBanner();
    } else {
      console.error("Error loading itinerary.");
    }
  }

  // set loader to spin if event comes from more aircraft modal
  // this event only occurs when an aircraft is selected in the more aircraft modal
  // the user presses a different continue button then the other processes that cause loader to spin
  aircraftSelectedEvent(aircraftSelected: boolean) {
    this.continueLoader = true;
    if (aircraftSelected) {
      this.aircraftSelectComponent.updateFlight();
      setTimeout(() => (this.continueLoader = false), 1000);
    } else {
      console.error("Error saving aircraft to flight legs.");
    }
  }

  checkLegFormsValid() {
    return (
      this.flightId > 0 ||
      (this.legRoutingSelectScreen !== undefined &&
        this.legRoutingSelectScreen.legForms !== undefined &&
        this.legRoutingSelectScreen.formsValid())
    );
  }
}
