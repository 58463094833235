import { autoserialize, autoserializeAs } from "cerialize";
import { FlightOffer } from "@domain";
import { FlightLeg } from "./flight-leg.model";
import { TRIP_TYPES } from "@shared/constants";
import * as moment from "moment";

export class Flight {
  @autoserializeAs("amount") amount: number;
  @autoserializeAs(FlightLeg, "flight_legs") flightLegs: FlightLeg[];
  @autoserializeAs(FlightOffer, "flight_offers") flightOffers: FlightOffer[];
  @autoserializeAs("stage_name") stageName: string;
  @autoserializeAs("status") status: string;
  @autoserializeAs("sfid") sfid: string;
  @autoserialize id: number;
  @autoserializeAs("has_offers") hasOffers: boolean;
  @autoserializeAs("catering_summary") cateringSummary: string;
  @autoserializeAs("arrival_ground_summary") arrivalGroundSummary: string;
  @autoserializeAs("departure_ground_summary") departureGroundSummary: string;
  @autoserializeAs("trip_number") tripNumber: string;
  @autoserializeAs("member_trip") memberTrip: boolean;
  @autoserializeAs("jet_membership_trip") jetMembershipTrip: boolean;
  @autoserializeAs("membercard_hours") memberHours: number;
  @autoserializeAs("client_agreed_to_charter_terms") clientAgreedToCharterTerms: boolean;

  @autoserializeAs("credit_card_preauthorized") creditCardPreAuthorized: boolean;
  @autoserializeAs("client_paid_by_credit_card") clientPaidByCreditCard: boolean;
  @autoserializeAs("client_started_ach_payment") clientStartedACHPayment: boolean;
  @autoserializeAs("client_wants_to_pay_with_wire") clientWantsToWirePayment: boolean;
  @autoserializeAs("client_paid_for_trip") clientPaidForTrip: boolean;

  public get finalHoursPrice(): number {
    let totalDuration = moment.duration("00:00");
    this.flightLegs.forEach((leg) => {
      totalDuration.add(leg.flightTime);
    });
    let totalHours = totalDuration.days() * 24 + totalDuration.hours();
    if (totalDuration.minutes() > 0) totalHours++;
    return totalHours;
  }

  public get type(): string {
    if (
      this.flightLegs.length === 2 &&
      this.flightLegs[0].fromAirport.sfId === this.flightLegs[1].toAirport.sfId
    ) {
      return TRIP_TYPES.ROUND_TRIP;
    } else if (this.flightLegs.length > 1) {
      return TRIP_TYPES.MULTI_LEG;
    }
    return TRIP_TYPES.ONE_WAY;
  }

  public get complete(): boolean {
    let isComplete: boolean = this.stageName == "Booked trip";
    if (!isComplete) { return false; }

    return this.flightLegs.some((leg) => leg.stage == "Trip Leg Complete");
  }

  constructor(
    amount?: number,
    flightLegs?: FlightLeg[],
    flightOffers?: FlightOffer[],
    stageName?: string,
    status?: string,
    sfid?: string,
    id?: number,
    hasOffers?: boolean,
    cateringSummary?: string,
    arrivalGroundSummary?: string,
    departureGroundSummary?: string,
    tripNumber?: string,
    memberTrip?: boolean,
    creditCardPreauthorized?: boolean,
    clientAgreedToCharterTerms?: boolean,
    jetMembershipTrip?: boolean,
    clientStartedACHPayment?: boolean,
    clientWantsToWirePayment?: boolean,
    clientPaidByCreditCard?: boolean,
    clientPaidForTrip?: boolean
  ) {
    this.amount = amount;
    this.flightLegs = flightLegs;
    this.flightOffers = flightOffers;
    this.stageName = stageName;
    this.status = status;
    this.sfid = sfid;
    this.id = id;
    this.hasOffers = hasOffers;
    this.cateringSummary = cateringSummary;
    this.arrivalGroundSummary = arrivalGroundSummary;
    this.departureGroundSummary = departureGroundSummary;
    this.tripNumber = tripNumber;
    this.memberTrip = memberTrip;
    this.creditCardPreAuthorized = creditCardPreauthorized;
    this.clientAgreedToCharterTerms = clientAgreedToCharterTerms;
    this.jetMembershipTrip = jetMembershipTrip;
    this.clientStartedACHPayment = clientStartedACHPayment;
    this.clientWantsToWirePayment = clientWantsToWirePayment;
    this.clientPaidByCreditCard = clientPaidByCreditCard;
    this.clientPaidForTrip = clientPaidForTrip
  }

  funded() {
    const isFunded = [
      this.clientPaidForTrip,
      this.clientPaidByCreditCard,
      this.clientStartedACHPayment,
      this.clientWantsToWirePayment,
      this.creditCardPreAuthorized
    ].some(Boolean);

    const isScheduled = (this.flightOffers || []).some((o) => o.selected);

    return isFunded || isScheduled;
  }
}
