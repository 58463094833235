import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageComponent } from 'src/app/components/prompts/message/message.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public constructor(private modalService: NgbModal) { }

  public onShowPrivacy() {
    let modalRef = this.modalService.open(MessageComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = 'Privacy Policy';
    modalRef.componentInstance.link = 'https://magellanjets.com/privacy-policy-and-legal-information';
    modalRef.componentInstance.linkText = 'Read More'
    modalRef.componentInstance.message = `When you visit our website magellanjets.com, mobile application,
    and use our services, you trust us with your personal information. We take your privacy very seriously.
    In this privacy notice, we seek to explain to you in the clearest way possible what information
    we collect, how we use it and what rights you have in relation to it. We hope you take some time to
    read through it carefully, as it is important. If there are any terms in this privacy notice that
    you do not agree with, please discontinue use of our Sites or Apps and our services.`
  }

  public onShowLegal() {
    let modalRef = this.modalService.open(MessageComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = 'Part 135 Disclaimer';
    modalRef.componentInstance.link = 'https://magellanjets.com/privacy-policy-and-legal-information';
    modalRef.componentInstance.linkText = 'Read More';
    modalRef.componentInstance.message = `The Magellan Jets Memberships are programs of Magellan Jets, LLC (Magellan Jets®).
    Magellan Jets (the Air Charter Broker) acting as an “Authorized Agent” for the Member (Charterer) shall; maintain non-owned aircraft liability insurance,
    and personal injury coverage, contract for transportation services with only FAR Part 135 Direct Air Carriers or their foreign Civil Aviation Authority (CAA)
    equivalent that operate and exercise full operational control over those flights at all times. Flights will be sourced through a proprietary “Magellan Jets
    Preferred Network” of Direct Air Carriers that meet all FAA or CAA safety standards and additional safety standards established by Magellan Jets.`;
  }


  public onShowContact() {
    let modalRef = this.modalService.open(MessageComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = 'Contact Information';
    modalRef.componentInstance.link = 'https://magellanjets.com/privacy-policy-and-legal-information';
    modalRef.componentInstance.linkText = 'Read More';
    modalRef.componentInstance.message = `
    <div>
    We can be reached:</br>
    Magellan Jets Corporate Center</br>
    1250 Hancock Street</br>
    Quincy, MA 02169</br>
    <a href="tel:844-316-6972">844-316-6972</a></br>
    <a href="mailto:flightsupport@magellanjets.com?subject=Trip Question"> flightsupport@magellanjets.com</a></div>`;
  }
}
