import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FlightService } from "@shared/services/flight.service";
import { Flight } from "../../../../../domain/flight";
import { ActivatedRoute } from "@angular/router";
import { Passenger } from "../../../../../domain/passenger.model";
import { Card } from "../../../../../domain/card";
import { RoutingService } from "@services";
import * as _ from "lodash";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MessageComponent } from "../../../../../components/prompts/message/message.component";
import { Observable } from "rxjs";
import { BlueSnapService } from "@shared/services/bluesnap.service";
import { Deserialize } from 'cerialize';

declare let $: any;
declare let bluesnap: any;
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit {
  viewMode = "checkoutScreen";
  flight: Flight;
  flightId: number;
  offerId: number;
  cateringItems = [];
  passengers: Passenger[];
  public agreeToTerms = false;
  cards: Card[];
  storedACHs: Card[];
  isLoading = false;
  isProcessingPayment = false;
  isAddingCard = false;
  creditCardForm: FormGroup;
  public token: string;
  @Input() formCheck: boolean;
  @Input() showCardForm: boolean;
  @Output() paymentUpdated = new EventEmitter();

  public amount: number;
  public amountWithCreditCard: number;

  private gettingCards = false;
  private wasPaid: boolean;

  public cardsLoaded: boolean = false

  public clientSelectedACH: boolean;

  public clientSelectedWire: boolean

  public clientSelectedCard: boolean

  public cardWasPreAuthorized: boolean = false;

  public wireStageAfterAuth: boolean = false;

  @Output() cancelTrip = new EventEmitter();
  public get selectedOfferPrice(): number {
    let temp = 0;
    if (this.flight) {
      temp = this.flight.flightOffers.filter(
        (offer) => offer.id == this.offerId
      )[0].memberHours;
    }
    return temp;

  }
  public isChecked;


  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private flightService: FlightService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private blueSnapService: BlueSnapService
  ) {
    this.route.params.subscribe((params) => {
      this.flightId = +params.id;
      this.offerId = +params.offerId;
    });
    this.clientSelectedACH = localStorage.getItem('paymentMethod') == 'ACH' ? true : false;

    this.clientSelectedWire = localStorage.getItem('paymentMethod') == 'wire' ? true : false;



    if (!this.clientSelectedACH && !this.clientSelectedWire) {
      this.clientSelectedCard = true
    }
  }
  previous() {
    this.routingService.navigateToMyTrips();
  }

  ngOnInit() {
    this.getFlight();
    this.getFlightPassengers();
    this.getCards();

    this.formCheck = false;
    this.isAddingCard = false;
  }

  getFlight() {
    this.isLoading = true;
    this.flightService.getFlight(this.flightId).subscribe((flight: Flight) => {
      this.flight = flight;

      if (this.flight.cateringSummary) {
        this.cateringItems = this.flight.cateringSummary.split(",");
      }
      if (this.flight.creditCardPreAuthorized) {
        this.cardWasPreAuthorized = true;
        if (this.clientSelectedWire) {
          this.wireStageAfterAuth = true;
        }
      }
      if (flight.clientAgreedToCharterTerms) {
        this.agreeToTerms = true;
      }

      let amount = flight.amount;
      if (this.offerId) {
        const selectedOffer = flight.flightOffers.find(o => o.id == this.offerId);
        if (selectedOffer && selectedOffer.amountToPresent) {
          amount = selectedOffer.amountToPresent;
        }
      }
      this.amount = amount;

      this.amountWithCreditCard = this.amount * 1.035;
      this.isLoading = false;
    });
  }

  getFlightPassengers() {
    this.flightService
      .getFlightPassengers(this.flightId)
      .subscribe((passengers: Passenger[]) => {
        this.passengers = passengers;
      });
  }

  getCards() {
    this.flightService.getCards().subscribe((cards: Card[]) => {
      console.log(cards)
      if (this.clientSelectedACH) {
        this.storedACHs = cards.filter((card: Card) => card.paymentType == 'ACH');
        if (this.storedACHs.length) {
          this.storedACHs[0].selected = true;
        }
      }

      this.cards = cards.filter((card: Card) => card.paymentType == 'credit card')
      if (this.cards.length) {
        this.cards[0].selected = true;
      }
      this.cardsLoaded = true;

    });
  }

  confirmSummary() {
    if (this.flight.memberTrip) {
      this.bookFlight(false);
    } else {
      this.viewMode = "checkoutScreen";
    }
  }

  bookFlight(achPayment: boolean) {
    this.isProcessingPayment = true;
    let bookRequest: Observable<any> = this.flight.memberTrip
      ? this.flightService.bookMemberFlight(
        this.flightId,
        this.selectedOfferPrice
      )
      : achPayment
        ? this.flightService.bookFlightACH(this.flightId, this.getSelectedACH())
        : this.flightService.bookFlight(this.flightId, this.getSelectedCard());
    bookRequest.subscribe(
      () => {
        // this.routingService.navigateToFlightBooked();
        this.isProcessingPayment = false;
        this.paymentUpdated.emit();



      },
      (error) => {
        this.isProcessingPayment = false;
        let modalRef = this.modalService.open(MessageComponent, {
          centered: true,
          size: "sm",
          backdrop: "static",
        });
        modalRef.componentInstance.title = "Booking Failed.";
        modalRef.componentInstance.message = "Please try again later.";
        modalRef.componentInstance.confirmButton = "Continue";
      }
    );
  }


  bookFlightWire() {
    this.isProcessingPayment = true;
    let bookRequest: Observable<any> = this.flightService.bookFlightWire(this.flightId);
    bookRequest.subscribe(
      (data) => {
        // this.routingService.navigateToFlightBooked();
        console.log(data)
        this.isProcessingPayment = false;
        this.paymentUpdated.emit();



      },
      (error) => {
        this.isProcessingPayment = false;
        let modalRef = this.modalService.open(MessageComponent, {
          centered: true,
          size: "sm",
          backdrop: "static",
        });
        modalRef.componentInstance.title = "Booking Failed.";
        modalRef.componentInstance.message = "Please try again later.";
        modalRef.componentInstance.confirmButton = "Continue";
      }
    );
  }


  selectCardForPayment(card) {
    const selectedCard = this.getSelectedCard();
    selectedCard.selected = false;
    card.selected = true;
  }



  getSelectedCard() {
    return _.filter(this.cards, (cardItem) => {
      return cardItem.selected;
    })[0];
  }

  selectACHForPayment(ACH) {
    const selectedACH = this.getSelectedACH();
    selectedACH.selected = false;
    ACH.selected = true;
  }

  getSelectedACH() {

    return _.filter(this.storedACHs, (ACHItem) => {
      return ACHItem.selected;
    })[0];
  }

  deleteCard = (id: number) => {
    this.gettingCards = true;
    this.blueSnapService.deleteCard(id).subscribe(() => {
      this.getCards();
      this.gettingCards = false;
    });
  };


  clientAgreedToCharterTerms() {
    this.flightService.clientAgreedCharterTerms(this.flightId).subscribe((data) => {
      if (data == null) {
        this.agreeToTerms = true

      }
      else {
        alert("There was an error with your confirmation, if this continues please call us at 844-316-6972")
      }

    })

  }
  onSuccess(success: boolean): void {
    // this.showCardForm = false;
    // this.showWireForm = false;
    this.viewMode = "checkoutScreen"
    this.getCards();
  }
  preAuthCreditCard() {
    this.isProcessingPayment = true;
    this.flightService.preAuthFlight(this.flightId, this.getSelectedCard()).subscribe((flight: Flight) => {
      console.log(flight)

      let deserializedFlight = Deserialize(flight, Flight);
      if (deserializedFlight.creditCardPreAuthorized) {
        console.log('auth successful')
        this.isProcessingPayment = false;
        this.cardWasPreAuthorized = true;
        this.flight = deserializedFlight;
        if (this.clientSelectedWire) {
          this.wireStageAfterAuth = true;
        }

      }
      else {
        this.isProcessingPayment = false;
        alert("We are unable to process your payment at this time, please call 844-316-6972 We apologize for the inconvenience.")

      }
    })

  }
}
