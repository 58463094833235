import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  SimpleChanges,
} from "@angular/core";
import { FlightService } from "@shared/services/flight.service";
import { ActivatedRoute } from "@angular/router";
import { Flight } from "src/app/domain/flight";
import { FlightLeg } from "src/app/domain/flight-leg.model";
import { $ } from "protractor";

@Component({
  selector: "app-transportation",
  templateUrl: "./transportation.component.html",
  styleUrls: ["./transportation.component.scss"],
})
export class TransportationComponent implements OnInit {
  public selectedTransportation = "custom";
  @Output() cancelTrip = new EventEmitter();
  @Output() transportationUpdated = new EventEmitter();
  public departureGroundTransportation: string;
  public arrivalGroundTransportation: string;
  public isSubmitting: boolean;
  public flightId: number;
  public offerId: number;
  @Input() activeLeg: number;
  public flight: Flight;
  public isLoading: boolean;
  @Input() public currentArrTransportation: string;
  @Input() public currentDeptTransportation: string;
  public currentLeg: FlightLeg;

  constructor(
    private flightService: FlightService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.flightId = +params.id;
      this.offerId = +params.offerId;
    });
  }
  ngOnInit() {
    this.activeLeg = 1;
    this.getFlight();
  }

  getFlight() {
    this.isLoading = true;
    this.flightService.getFlight(this.flightId).subscribe((flight: Flight) => {
      this.flight = flight;
      this.currentLeg = this.flight.flightLegs[0];
      this.currentArrTransportation = this.currentLeg.arrivalGroundSummary;
      this.currentDeptTransportation = this.currentLeg.departureGroundSummary;

      this.isLoading = false;
    });
  }

  refreshFlight() {
    this.isLoading = true;
    this.flightService.getFlight(this.flightId).subscribe((flight: Flight) => {
      this.flight = flight;
      this.isLoading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeLeg && this.flight) {
      this.setTransportationOptions(false);
      this.refreshFlight();

      this.currentLeg = this.flight.flightLegs[this.activeLeg - 1];

      let currentLegArrTransporation = this.flight.flightLegs[
        this.activeLeg - 1
      ].arrivalGroundSummary;

      let currentLegDeptTransportation = this.flight.flightLegs[
        this.activeLeg - 1
      ].departureGroundSummary;

      this.currentArrTransportation = currentLegArrTransporation;

      this.currentDeptTransportation = currentLegDeptTransportation;
    } else {
    }
  }
  setTransportationOptions(nextStep: boolean) {
    this.isSubmitting = true;

    let flightLegId = Number(this.currentLeg.id);

    this.flightService
      .updateLegGroundTransportation(
        flightLegId,
        this.currentArrTransportation,
        this.currentDeptTransportation
      )
      .subscribe((data) => {
        if (nextStep) {
          this.transportationUpdated.emit();
        }

        this.isSubmitting = false;
      });
  }
  saveArrTransportation(event) {
    this.currentArrTransportation = event.target.value;
  }
  saveDeptTransportation(event) {
    this.currentDeptTransportation = event.target.value;
  }
}
