import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlightRequest } from '@domain';
import { RoutingService } from '@services';
import { FlightService } from '../../../shared/services/flight.service';
import { ImageService } from '../../../shared/services/image.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-offers.component.html',
  styleUrls: ['./request-offers.component.scss']
})
export class RequestOffersComponent implements OnInit {
  public tripId: number;
  public flight: FlightRequest;
  public photo: any;
  public environment = environment;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private flightService: FlightService,
    private imageService: ImageService
  ) {
    this.route.params.subscribe((params) => {
      this.tripId = +params.id;
      this.flightService.get(this.tripId)
        .subscribe(
          (flight) => {
            console.log(flight)
            this.flight = flight;
          },
          (err) => {
            this.routingService.navigateToMyTrips();
          }
        );
    });
  }

  ngOnInit() {
  }

}
