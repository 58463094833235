import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '@services';
import { FlightService } from '../../../shared/services/flight.service';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Flight } from '../../../domain/flight';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from '../../../components/prompts/dialog/dialog.component';


declare let $: any;

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {

  public tripId: number;
  public offerId: number;
  public flight: Flight;
  public offer: any;
  public environment = environment;
  public acceptingOffer = false;
  public isLoaded = false;
  public moment = moment;

  public clientSelectedACH: boolean = false;

  public clientSelectedWire: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private routingService: RoutingService,
    private flightService: FlightService
  ) {
    this.route.params.subscribe(params => {
      this.tripId = +params.id;
      this.offerId = +params.offerId;
      this.flightService.get(this.tripId).subscribe(
        flight => {
          this.flight = flight;
          this.offer = _.filter(this.flight.flightOffers, (o) => {
            return o.id === this.offerId;
          });
          if (this.offer.length) {
            this.offer = this.offer[0];
          }

          this.isLoaded = true;
        },
        err => {
          this.routingService.navigateToMyTrips();
        }
      );
    });
  }

  public closeModal() {
    let modal = document.getElementById('divModal');
    modal.style.display = "none";
  }

  public zoomModal(picType) {

    let modal = document.getElementById('divModal');
    let modalImg = <HTMLImageElement>document.getElementById("imgModal");
    let captionText = document.getElementById("caption");

    modal.style.display = "block";
    let originalImage = <HTMLImageElement>document.getElementById(picType);
    modalImg.src = originalImage.src;
    captionText.innerHTML = originalImage.alt;
  }
  ngOnInit() {
  }


  acceptOffer() {

    this.acceptingOffer = true;
    this.flightService.acceptOffer(this.tripId, this.offerId)
      .subscribe(
        () => {
          this.acceptingOffer = false;
          this.routingService.navigateToMyFlightDetails(this.tripId, this.offerId);
        },
        err => {
          this.acceptingOffer = false;
        }
      );
  }

  cancelRequest = () => {
    let modalRef = this.modalService.open(DialogComponent, { centered: true, size: 'sm', backdrop: 'static' });
    modalRef.componentInstance.title = "Cancel trip?";
    modalRef.componentInstance.message = "If you want to make changes call Magellan Jets Flight Support at 8443166972 or click the button below to cancel your trip";
    modalRef.result.then(confirmed => {
      if (confirmed) {
        this.flightService.delete(this.flight.id).subscribe(() =>
          this.routingService.navigateToMyTrips()
        );
      }
    });
  }

  choosePayment(paymentMethod: string) {
    localStorage.setItem('paymentMethod', paymentMethod);

    //otherwise everytime you come to this page, the stage can be changed to options presented
    //so if a customer pays for their trip and presses backwards on their browser and then clicks the button that calls this method, the stage will go from Send to FS to options presented
    if(this.flight.stageName !== "Send to FS"){
      this.acceptOffer()
    }
    else{
      this.routingService.navigateToMyFlightDetails(this.tripId, this.offerId);
    }

  }
}
