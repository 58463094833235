import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { environment } from "@environment/environment";
import { Aircraft } from "src/app/domain/aircraft.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: "app-explore-aircraft-modal",
  templateUrl: "./explore-aircraft-modal.component.html",
  styleUrls: ["./explore-aircraft-modal.component.scss"],
})
export class ExploreAircraftModalComponent implements OnInit {
  public slides = [];
  @Input() public aircraft: Aircraft;
  public showDetailsTab: boolean = true;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    arrows: false,
  };

  @ViewChild("slickModal", { static: true }) slickModal: SlickCarouselComponent;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    console.log(this.aircraft);
    this.slides =
      this.aircraft &&
      this.aircraft.interiorPhotoId &&
      this.aircraft.exteriorPhotoId
        ? [
            {
              img: `${environment.baseUrl}/photos/salesforce?id=${this.aircraft.interiorPhotoId}`,
            },
            {
              img: `${environment.baseUrl}/photos/salesforce?id=${this.aircraft.exteriorPhotoId}`,
            },
          ]
        : [];
  }
  changeTabs(tab: string) {
    if (
      (this.showDetailsTab && tab !== "details") ||
      (!this.showDetailsTab && tab !== "specs")
    ) {
      this.showDetailsTab = !this.showDetailsTab;
    }
  }

  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
}
