import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent implements ControlValueAccessor {

  @Input() public disabled: boolean;
  @Input() public placeholder: string;
  @Input() public options: any[];
  public opened: boolean;
  public selectedOption: any;
  public get display(): string { return (this.selectedOption) ? this.selectedOption.text : this.placeholder; }
  constructor() {
    this.opened = false;
  }
  public writeValue(): void {
    if (this.selectedOption) {
      this.onChange(this.selectedOption.value);
    }
  }
  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  public onSelectOption(option): void {
    this.selectedOption = option;
    this.opened = false;
    this.writeValue();
  }
  public onChange(value: string) { };
  public onTouched() { };
}
