import { Component, OnInit, Input } from '@angular/core';
import { Flight } from 'src/app/domain/flight';

@Component({
  selector: 'app-flight-overview2',
  templateUrl: './flight-overview2.component.html',
  styleUrls: ['./flight-overview2.component.scss']
})
export class FlightOverview2Component implements OnInit {

  @Input() public aircraftId: string;
  @Input() public flight: Flight;
  @Input() public leg: any;
  public flightDuration = ''
  public constructor() { }
  public ngOnInit() {
  }
}
