import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environment/environment";

import { Serialize, Deserialize } from "cerialize";
import { map } from "rxjs/operators";
import { Account,   } from "../../domain/account.model";
import { FBO } from "@domain"
import { Flight } from "../../domain/flight"
import { User } from "@domain";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(private http: HttpClient) { }

  public getAccountsOfEmployee(id: string) {
    return this.http
      .get<Account>(environment.baseUrl + "account", {
        params: {
          id: id,
        },
      })
      .pipe(
        map((account: Account) => {
          return Deserialize(account, Account);
        })
      );
  }

  public tripsForAccount(id: string) {
    const url = environment.baseUrl + `account/all_flights?id=${id}`;
    return this.http.get(url);
  }

  public getAccountFlights(id: string) {
    return this.http
      .get<Flight>(environment.baseUrl + `account/get_flights?id=${id}`)
      .pipe(
        map((flight: Flight) => {
          return Deserialize(flight, Flight);
        })
      );
  }

  public searchOperatorAccounts(query: string) {
    let search = {};
    if (query) {
      search = {
        params: { q: query },
      };
    }
    const url = `${environment.baseUrl}account/get_operator_accounts`;
    return this.http.get<FBO>(url, search).pipe(
      map((fbo: FBO) => {
        return Deserialize(fbo, FBO);
      })
    );
  }

  public getGenericOperator() {
    // Using FBO because we only need a small subset of the "account" information.
    const url = `${environment.baseUrl}account/generic_operator`;
    return this.http.get<FBO>(url).pipe(
      map((raw: any) => Deserialize(raw, FBO))
    );
  }

  public searchVendorAccounts(query: string) {
    const url = `${environment.baseUrl}vendors`
    const search = { params: { q: query } };
    return this.http.get(url, search);
  }

  public getMemberCardsOfAccount(accountSfid: string) {
    const url = `${environment.baseUrl}account/${accountSfid}/member_cards`;
    return this.http.get(url);
  }

  public getContactsOfAccount(accountSfid: string) {
    return this.http
      .get<User>(
        environment.baseUrl + "account/get_contacts_of_account?q=" + accountSfid
      )
      .pipe(
        map((user: User) => {
          return Deserialize(user, User);
        })
      );
  }

  public searchAccountsByName(query: string) {
    return this.http
      .get<Account>(environment.baseUrl + "account/search_accounts?q=" + query)
      .pipe(
        map((account: Account) => {
          return Deserialize(account, Account);
        })
      );
  }


  public searchContactsOfAccount(accountSfid: string, name: string) {
    return this.http.get<User>(
      environment.baseUrl +
      `account/${accountSfid}/search_related_contacts?name=${name}`
    );
  }

  public getAccount(accountSfid: string) {
    return this.http.get<Account>(
      environment.baseUrl + `account/${accountSfid}`
    );
  }

  public updateAccount(accountSfid: string, updates) {
    return this.http.patch<Account>(
      environment.baseUrl + `account/${accountSfid}`, updates
    );
  }

  public getMembershipPrograms() {
    const url = `${environment.baseUrl}memberships`
    return this.http.get(url);
  }
  public assignMembership(accountSfid, membershipId, effectiveDt) {
    const url = `${environment.baseUrl}account/${accountSfid}`;

    const dt = new Date(effectiveDt);
    const month = (dt.getMonth() + 1).toString().padStart(2, '0');
    const day = (dt.getDate() + 1).toString().padStart(2, '0');
    const effective = `${dt.getFullYear()}-${month}-${day}`;

    const contents = {
      id: membershipId,
      effective_at: effective,
      active: true // TODO, add ability to associate a membership but not make it the active one.
    };
    const body = { active_membership: contents };

    return this.http.patch(url, body);
  }
}