import * as moment from "moment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@environment/environment";
import { FBO, FlightRequest, User } from "@domain";
import { Deserialize, Serialize } from "cerialize";
import { map } from "rxjs/operators";
import { Airport } from "../../domain/airport.model";
import { Flight } from "../../domain/flight";
import { Passenger } from "../../domain/passenger.model";
import { Card } from "../../domain/card";
import { NgbDateWrapper } from "../../domain/NgbDateWrapper.model";
import { FlightLeg } from "src/app/domain/flight-leg.model";
import { Observable, zip } from "rxjs";
import { FlightCalc } from "src/app/domain/flight-calc.model";

@Injectable({
  providedIn: "root",
})
export class FlightService {
  constructor(private http: HttpClient) { }

  public getAirports(query?: string, perPage?: number) {
    if (!query) {
      query = "";
    }
    return this.http
      .get(environment.baseUrl + "airports?q=" + query + "&per_page=" + perPage)
      .pipe(
        map((airport: Airport) => {
          return Deserialize(airport, Airport);
        })
      );
  }

  public getAirportBySFID(sfId: string) {
    return this.http.get(environment.baseUrl + "airports/" + sfId).pipe(
      map((airport: Airport) => {
        return Deserialize(airport, Airport);
      })
    );
  }

  public getFBOs(airportSfId: string, query?: string) {
    if (!query) {
      query = "";
    }
    return this.http
      .get(environment.baseUrl + "airports/" + airportSfId + "/fbos?q=" + query)
      .pipe(
        map((fbos: FBO[]) => {
          return Deserialize(fbos, FBO);
        })
      );
  }

  public getFBOByTerm(term: string, perPage?: number) {
    return this.http
      .get(environment.baseUrl + "fbos?q=" + term + "&per_page=" + perPage)
      .pipe(
        map((fbos: Airport[]) => {
          return Deserialize(fbos, FBO);
        })
      );
  }

  public getFBOBySFID(sfId: string) {
    return this.http.get(environment.baseUrl + "fbos/" + sfId).pipe(
      map((fbo: FBO) => {
        return Deserialize(fbo, FBO);
      })
    );
  }

  public getAllFBOs() {
    return this.http.get(environment.baseUrl + "fbos").pipe(
      map((fbos: FBO[]) => {
        return Deserialize(fbos, FBO);
      })
    );
  }

  private formatDuration(duration: moment.Duration): string {
    let hours: number = duration.days() * 24 + duration.hours();
    return (
      (hours > 9 ? "" : "0") +
      hours +
      ":" +
      (duration.minutes() > 9 ? "" : "0") +
      duration.minutes()
    );
  }

  public getFlightDuration(
    flight: Flight,
    leg?: number,
    aircraftId?: string
  ): Observable<any> {
    // console.log(flight,leg, aircraftId)
    return new Observable<string>((observer) => {
      let legs: FlightLeg[] =
        leg != null ? [flight.flightLegs[leg]] : [...flight.flightLegs];
      let duration: moment.Duration = moment.duration("00:00");
      if (!aircraftId) {
        let incomplete: boolean = false;
        legs.forEach((leg) => {
          if (leg.flightTime) {
            duration.add(leg.flightTime);
          } else incomplete = true;
        });
        if (incomplete) {
          observer.next(null);
        } else {
          observer.next(this.formatDuration(duration));
        }
        observer.complete();
      } else {
        let actions: Observable<any>[] = legs.map((leg) =>
          this.flightLegDuration(flight.id, leg.id, aircraftId)
        );
        zip(...actions).subscribe(
          (results) => {
            results.forEach((result) => duration.add(result.flight_time));
            observer.next(this.formatDuration(duration));
            observer.complete();
          },
          (error) => {
            console.error(error);
            observer.next(null);
            observer.complete();
          }
        );
      }
    });
  }

  private flightLegDuration(
    flightId: number,
    flightLegId: string,
    aircraftId: string
  ) {
    return this.http.get(
      environment.baseUrl +
      "flights/" +
      flightId +
      "/flight_legs/" +
      flightLegId +
      "/flight_time?aircraft_id=" +
      aircraftId
    );
  }

  public flightRequest(flightRequest: FlightRequest) {
    return this.http.post(
      environment.baseUrl + "flights/",
      flightRequest.toData()
    );
  }

  public submitPassengerHealthForm(passengerInfo) {
    return this.http.post(
      environment.baseUrl + "flights/submit_passenger_health_form",
      {
        ...passengerInfo,
      }
    );
  }

  public updateFlightLeg(flightId: number, flightLeg: FlightLeg) {
    return this.http
      .put(
        environment.baseUrl + "flight_legs/update_leg?flight_id=" + flightId,
        {
          from_airport: flightLeg.fromAirport.sfId,
          to_airport: flightLeg.toAirport.sfId,
          departure_date: flightLeg.departureDate,
          departure_time: flightLeg.departureTime,
          arrival_date: flightLeg.arrivalDate,
          arrival_time: flightLeg.arrivalTime,
          flight_time: flightLeg.flightTime,

          aircraft: flightLeg.aircraft.sfid,
          number_of_passenger: flightLeg.noOfPassengers,
          id: flightLeg.id,
        }
      )
      .pipe(
        map((flightLeg: FlightLeg) => {
          return Deserialize(flightLeg, FlightLeg);
        })
      );
  }

  public getCharterAgreed(id) {
    if (id) {
      return this.http
        .get(
          environment.baseUrl + "flights/client_agreed_charter_terms?id=" + id
        )

        .pipe(
          map((flight: Flight) => {
            return Deserialize(flight, Flight);
          })
        );
    } else {
      return this.http.get(environment.baseUrl + "flights?per_page=1000").pipe(
        map((flights: Flight[]) => {
          return Deserialize(flights, Flight);
        })
      );
    }
  }

  public get(id?: number, tripNum?: string) {
    if (null !== id  && "number" === typeof id) {
      return this.http.get(environment.baseUrl + "flights/" + id).pipe(
        map((flight: Flight) => {
          return Deserialize(flight, Flight);
        })
      );
    } else if (null !== tripNum && "string" === typeof tripNum) {
      return this.http.get(environment.baseUrl + "flights/by_trip_num/" + tripNum).pipe(
        map((flight: Flight) => {
          return Deserialize(flight, Flight);
        })
      );
    } else {
      return this.http.get(environment.baseUrl + "flights?per_page=1000").pipe(
        map((flights: Flight[]) => {
          return Deserialize(flights, Flight);
        })
      );
    }
  }

  public deleteFlightLeg(flightId: number, legId: number) {
    return this.http.delete(environment.baseUrl + "flight_legs/delete", {
      params: {
        flight_id: flightId.toString(),
        id: legId.toString(),
      },
    });
  }

  public delete(id: number) {
    return this.http.delete(environment.baseUrl + "flights/" + id);
  }

  public acceptOffer(flightId?: number, offerId?: number) {
    return this.http.post(
      environment.baseUrl + "flights/" + flightId + "/accept",
      { flight_offer_id: offerId }
    );
  }

  public getCatering() {
    return this.http.get(environment.baseUrl + "catering");
  }

  public updateFlightCatering(flightId: number, catering: string) {
    return this.http.put(environment.baseUrl + "flights/" + flightId, {
      catering_summary: catering,
    });
  }

  //for flight
  public updateGroundTransportation(
    flightId: number,
    arrivalGroundSummary: string,
    departureGroundSummary: string
  ) {
    return this.http.put(environment.baseUrl + "flights/" + flightId, {
      departure_ground_summary: departureGroundSummary,
      arrival_ground_summary: arrivalGroundSummary,
    });
  }
  //for leg
  public updateLegGroundTransportation(
    flightLegId: number,
    arrivalGroundSummary: string,
    departureGroundSummary: string
  ) {
    return this.http.put(
      environment.baseUrl + "ground_summary/?flight_id=" + flightLegId,
      {
        arrival_ground_summary: arrivalGroundSummary,
        departure_ground_summary: departureGroundSummary,
      }
    );
  }

  //update catering for a leg
  public updateLegCatering(flightLegId: number, cateringSummary: string) {
    return this.http.put(
      environment.baseUrl + "leg_catering/?flight_id=" + flightLegId,
      {
        catering_summary: cateringSummary,
      }
    );
  }

  public getFlightPassengers(flightId: number) {
    return this.http
      .get(environment.baseUrl + "flights/" + flightId + "/passengers")
      .pipe(
        map((passengers: Passenger[]) => {
          return Deserialize(passengers, Passenger);
        })
      );
  }

  public removePassenger(passengerId: number) {
    return this.http.delete(environment.baseUrl + "passengers/" + passengerId);
  }

  private preparePassengerData(user: User) {
    return {
      user: {
        first_name: user.firstName,
        last_name: user.lastName,
        weight: user.weight,
        birth_date: user.birthday,
      },
      identification: {
        passport_expiration: NgbDateWrapper.fromNgbDate(
          user.identification.passportExpiration
        ),
        passport_issue_date: NgbDateWrapper.fromNgbDate(
          user.identification.passportIssueDate
        ),
        passport_issuing_country: user.identification.passportIssuingCountry,
        passport_number: user.identification.passportNumber,
        license_expiration_date: NgbDateWrapper.fromNgbDate(
          user.identification.licenseExpirationDate
        ),
        license_issuing_state: user.identification.licenseIssuingState,
        license_number: user.identification.licenseNumber,
      },
    };
  }

  public updatePassenger(passengerId: number, user: User) {
    return this.http.put(
      environment.baseUrl + "passengers/" + passengerId,
      this.preparePassengerData(user)
    );
  }

  public addPassengerToFlight(userId: string, flightId: number) {
    return this.http.post(
      environment.baseUrl + "flights/" + flightId + "/passengers",
      { user_id: userId }
    );
  }

  public getFlight(flightId: number) {
    return this.http.get(environment.baseUrl + "flights/" + flightId).pipe(
      map((flight: Flight) => {
        return Deserialize(flight, Flight);
      })
    );
  }

  public getCards() {
    return this.http.get(environment.baseUrl + "payment_cards").pipe(
      map((cards: Card[]) => {
        return Deserialize(cards, Card);
      })
    );
  }

  public addCard(card: Card) {
    return this.http.post(
      environment.baseUrl + "payment_cards",
      Serialize(card, Card)
    );
  }

  public deleteCard(id: number) {
    return this.http.delete(environment.baseUrl + "payment_cards/" + id);
  }

  public bookFlight(flightId: number, card: Card) {
    return this.http.post(
      environment.baseUrl + "flights/" + flightId + "/book",
      { payment_methods: [{ payment_card_id: card.id, percentage: 1 }] }
    );
  }
  public bookFlightACH(flightId: number, card: Card) {
    return this.http.post(
      environment.baseUrl + "flights/book_with_ach?id=" + flightId,
      { payment_methods: [{ payment_card_id: card.id, percentage: 1 }] }
    );
  }

  public bookFlightWire(flightId: number) {
    return this.http.post(
      environment.baseUrl + "flights/book_with_wire?id=" + flightId,
      {}
    );
  }

  public bookMemberFlight(flightId: number, hours: number) {
    return this.http.post(
      environment.baseUrl + "flights/" + flightId + "/book",
      { flight_hours: hours }
    );
  }

  public preAuthFlight(flightId: number, card: Card) {
    return this.http.post(
      environment.baseUrl +
      "flights/pre_auth_flight_with_card/?id=" +
      flightId,
      { payment_methods: [{ payment_card_id: card.id, percentage: 1 }] }
    );
  }

  public clientAgreedCharterTerms(flightId: number) {
    return this.http.put(
      environment.baseUrl +
      `flights/client_agreed_charter_terms?id=${flightId}`,
      {}
    );
  }

  public updateFlightTimesWithPadding(flightId: number, aircraft: string) {
    return this.http.put(
      environment.baseUrl + "flights/calculate_flight_time_with_padding",
      {
        id: flightId.toString(),
        aircraft: aircraft,
      }
    );
  }

  public calculateTotalLegFlightTimes(flightId: number, aircraft: string) {
    const url = `${environment.baseUrl}flights/total_leg_flight_times?id=${flightId}&aircraft=${aircraft}`
    return this.http.get(url);
  }

  public changeStageName(flightId: number, stageName: string) {
    return this.http.put(environment.baseUrl + "flights/change_stage_name", {
      id: flightId.toString(),
      stage: stageName,
    });
  }

  public checkPassengerFormsCompleted(flightId: number) {
    return this.http.get(
      environment.baseUrl +
      `flights/${flightId}/all_passengers_submitted_health_form`
    );
  }
}
