import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';

import { User } from '@domain';
import { RoutingService } from '@services';
import { Deserialize } from 'cerialize';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private routingService: RoutingService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(user: User) {
    return this.http.post<User>(environment.baseUrl + `users/authenticate`, { ...user, provider: 'email' })
      .pipe(map((res: User) => {
        // login successful if there's a jwt token in the response
        if (res && res.authorization) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(Deserialize(res, User)));
          let temp = <User>JSON.parse(localStorage.getItem('currentUser'));
          this.currentUserSubject.next(temp);
        }
        return res;
      }));
  }

  refreshToken() {
    if (this.currentUserValue.authorization.refreshToken) {
      return this.http.post<User>(
        environment.baseUrl + `/users/refresh_token`,
        { refresh_token: this.currentUserValue.authorization.refreshToken }
      )
        .pipe(map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.authorization && user.authorization.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        }));
    } else {
      this.routingService.navigateToLogin();
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.routingService.navigateToLogin();
  }
}
