import { Component, OnInit } from "@angular/core";
import { FlightService } from "@shared/services/flight.service";
import * as _ from "lodash";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RoutingService } from "@services";
import * as moment from "moment";
import { Flight } from "../../domain/flight";
import { DialogComponent } from "../../components/prompts/dialog/dialog.component";
import { AuthenticationService } from "src/app/modules/authentication-module/authentication-service/authentication.service";

@Component({
  selector: "app-my-trips",
  templateUrl: "./my-trips.component.html",
  styleUrls: ["./my-trips.component.scss"],
})
export class MyTripsComponent implements OnInit {
  public flights: Flight[];
  public requested: Flight[];
  public flightsByStatus;
  public isLoadingTrips = false;
  public moment = moment;
  public selectedCategory: string;
  public categoryNames: string[];
  public userType: string;

  constructor(
    private modalService: NgbModal,
    private flightService: FlightService,
    private routingService: RoutingService,
    private authenticationService: AuthenticationService
  ) {
    this.categoryNames = ["Requested", "Scheduled", "Past trips"];
    this.selectedCategory = this.categoryNames[0];
  }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(
      (user) => {
        if (user) {
          this.userType = user.account.accountType.toLowerCase();
        }
      },
      (error) => {
        console.error(error);
      }
    );
    this.getFlights();
  }

  selectCategory = (category: string) => {
    if (!category) { return; }
    this.selectedCategory = category;
  };

  private sortByFirstLegDepartureDate(a: Flight, b: Flight) {
    const decision = moment(b.flightLegs[0].departureDate)
      .isBefore(a.flightLegs[0].departureDate);

    if (decision) { return -1; }
    return 1;
  }

  getFlights = () => {
    this.isLoadingTrips = true;
    this.flightService.get()
      .subscribe(
        (response: Flight[]) => {
          let trips = response.filter(
            (flight) => flight.stageName !== "Cancellation" && flight.flightLegs.length > 0
          );

          trips = trips.sort(this.sortByFirstLegDepartureDate);

          for (let trip of trips) {
            if (trip.complete) { trip.status = 'past'; }
            if (trip.funded()) { trip.status = 'scheduled'; }

            trip.status = 'requested';
          }

          this.flightsByStatus = _.groupBy(trips, (f: Flight) => f.status);
          if (!this.flightsByStatus.requested) {
            if (!this.flightsByStatus.scheduled) {
              this.selectCategory(
                this.categoryNames.find(c => c.toLowerCase().startsWith('past'))
              );
            } else {
              this.selectCategory(
                this.categoryNames.find(c => c.toLowerCase().startsWith('scheduled'))
              );
            }
          }

          this.isLoadingTrips = false;
        },
        (err) => {
          debugger;
        }
      );
  };

  presentableOffers(flight) {
    const hasPresentableOffers = flight.flightOffers
      && flight.flightOffers.length > 0;
    return hasPresentableOffers;
  }

  presentationPage(flight) {
    const url = `https://tbp.magellanjets.com/present/${flight.sfid}-${flight.id}`;
    console.log("PRESENTATION PAGE", url);
    window.location.href = url;
  }

  cancelRequest = (id: number) => {
    let modalRef = this.modalService.open(DialogComponent, {
      centered: true,
      size: "sm",
      backdrop: "static",
    });
    modalRef.componentInstance.title = "Cancel trip?";
    modalRef.componentInstance.message =
      "Are you sure you want to cancel this trip?";
    modalRef.result.then((confirmed) => {
      if (!confirmed) { return }

      this.flightService
        .delete(id)
        .subscribe(
          () => {
            this.requested = this.requested.filter(
              (flight) => flight.id != id
            )
          }
        );
    });
  };
}
